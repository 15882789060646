<template>
    <!-- MOBILE--->
    <div class="offcanvas offcanvas-start hide"  ref="offcanvasRef" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasModulos" aria-labelledby="offcanvasModulos" v-if="backdrop">
        <div class="offcanvas-header col-12 d-flex justify-content-center align-items-center">
            <div class="row justify-content-between row__width">
                <div class="col-12 d-flex justify-content-start align-items-center px-3 border-bottom-div close">
                    <button class="navbar-toggler d-flex justify-content-center align-items-center" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasModulos" aria-controls="offcanvasModulos">
                        <img src="../../img/viendo-icons/close.svg" alt="">
                        <span class="">Cerrar</span>
                    </button>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center py-3">
                    <div class="row justify-content-between row__width">
                        <div class="col-9 d-flex flex-column justify-content-center align-items-start px-0">
                            <h5>Módulos {{modComplete }} / {{ content.length - 1 }}</h5>
                            <h4>{{modComplete }} de {{ content.length - 1 }} módulos terminados</h4>
                        </div>
                        <div class="col-3 d-flex justify-content-center align-items-center px-0 progreso">
                            <div class="row justify-content-center row__width">
                                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                    <div class="gris">
                                        <div class="blanco">
                                            <h6>{{calPercentages}}%</h6>
                                        </div>
                                        <div class="azul" :style="`width:${calPercentages}%`"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="offcanvas-body col-12 d-flex flex-column justify-content-start align-items-center position-relative px-0">
            <div class="row justify-content-center row__width">
                <ModulosNav/>
            </div>
        </div>
    </div>
    <!-- ESCRITORIO--->
    <div class="offcanvas offcanvas-start show justify-content-start align-items-start max-width px-0" ref="offcanvasRef" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasModulos" aria-labelledby="offcanvasModulos" v-else>
        <div class="offcanvas-header col-12 d-flex justify-content-center align-items-center">
            <div class="row justify-content-center row__width">
                <div class="col-9 d-flex flex-column justify-content-center align-items-start">
                    <!-- Escritorio -->
                    <h5>Módulos {{modComplete }} / {{ content.length - 1 }}</h5>
                    <h4>{{modComplete }} de {{ content.length - 1 }} módulos terminados</h4>
                </div>
                <div class="col-3 d-flex justify-content-center align-items-center px-0 progreso">
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                            <div class="gris">
                                <div class="blanco">
                                    <h6>{{calPercentages}}%</h6>
                                </div>
                                <div class="azul" :style="`width:${calPercentages}%`"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="offcanvas-body col-12 d-flex flex-column justify-content-start align-items-center position-relative px-0">
            <div class="row justify-content-center row__width">
                <ModulosNav/>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, onMounted, onUnmounted, computed, watch } from 'vue';
import ModulosNav from "../Viendo/ModulosNav.vue";

let content = inject('content')
let modZero = inject('modZero')
let response = inject('response')
let modComplete = inject('modComplete')
let backdrop = inject('backdrop')
let courseProgress = inject('courseProgress')
let offcanvasRef = inject('offcanvasRef')

const calPercentages = computed(() => {
    let totalPercentage = 0
    let totalLessons = 0
    //console.log(content.value)
    content.value.forEach(unit => {
        unit[0].lessons.forEach(lesson => {
            // console.log(lesson.percentage)
            if (lesson.lessonType == 1){
                totalPercentage += lesson.percentage
                totalLessons++
            }
            else if (lesson.lessonType == 2){
                console.log(lesson)
                if (lesson.result.viewed && lesson.result.score >= lesson.evaluations[0].quizzes[0].minimum_grade){
                    totalPercentage += 100
                }
                else {
                    totalPercentage += 0
                }
                totalLessons++
            }
        })
    })
    // console.log('totalPercentage: '+totalPercentage, 'totalLessons: '+totalLessons)
    return Math.round(totalPercentage / totalLessons)
})

watch(calPercentages, (newValue) => {
    courseProgress.value = newValue;
});


</script>

<style lang="scss" scoped>

@media (min-width: 1440px) {
    .offcanvas{
        position: relative;
        height: 100%;
        min-height: 100%;
        visibility: visible !important;
        border: 0;
        z-index: 1;
        padding: 0;
        border: 0 !important;
        width: 0 !important;
        box-shadow: 0px 3px 6px #00000029;
        left: 0;
        background-color: #FAFAFA;
        .offcanvas-header, .offcanvas-body{
            display: none !important;
            padding: 20px 10px 0 10px;
            h5{
                color: var(--colorSecondary);
                font-weight: 600;
                font-size: 25px;
                padding-bottom: 10px;
            }
            h4{
                font-weight: 400;
                font-size: 16px;
                color: #697891;
                text-align: left;
            }
        }
        .boton-toggler{
            position: absolute;
            top: 35px;
            right: auto;
            z-index: 1047;
            border: 0;
            width: 27px;
            height: 27px;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            outline: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 10px;
            }
            transition: all 0.2s ease;
        }
        .nav-link{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: var(--enlacesNav);
            font-size: 15px;
            font-weight: 500;
            text-align: left;
            margin: 10px 0px;
            position: relative;
        }
        .navbar-brand{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            @media (min-width: 992px) {
                font-size: 30px;
            }
        }
        .dropdown-toggle::after{
            border: 0;
        }
        ul{
            h4{
                color: var(--grisPrimario);
                font-size: 16px;
                font-weight: 700;
                text-align: left;
                padding: 0 0 10px 0;
            }
            li{
                padding: 0;
            }
        }
        .btns{
            margin: 10px 0px;
        }
        .ul_precios{
            padding-left: 0;
            list-style: none;
            padding-bottom: 40px;
            li{
                a{
                    color: var(--colorSecondary);
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                }
            }
        }
        .ul_certificados{
            li{
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .boder-top{
            h6{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 500;
                text-align: left;
                strong{
                    font-size: inherit;
                }
            }
            .form-check-input{
                border-color: var(--colorPrimary);
                position: relative;
                display: flex;
                align-items: center;
            }
            .form-switch .form-check-input {
                height: 25px;
                width: 48px;
                background-image: url('../../img/menu-icons/switch_normal.svg');
                background-size: 42% 100%;
                background-position-x: 2px;
                &::before{
                    content: '';
                    position: absolute;
                    background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                    
                    background-repeat: no-repeat;
                    background-size: 100%;
                    height: 15px;
                    width: 15px;
                    right: 5px;
                }
                &::after{
                    display: none;
                }
            }
            .form-switch .form-check-input:checked{
                background-image: url('../../img/menu-icons/switch_active.svg');
                background-position-x: 25px;
                &::before{
                    content: '';
                    position: absolute;
                    background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                    filter: brightness(10);
                    background-repeat: no-repeat;
                    background-size: 100%;
                    height: 15px;
                    width: 15px;
                    left: 5px;
                }
            }
            .form-switch .form-check-input:focus{
                box-shadow: none;
            }
        }
        .navbar-nav .dropdown-menu{
            position: absolute;
        }
        .boder-box{
            display: none ;
            box-shadow: 0px 3px 10px #00000033;
            padding: 10px 10px 15px 30px;
            position: absolute;
            z-index: 2;
            left: 0;
            bottom: 0vh;
            width: 100%;
            .nav-link{
                &::before{
                    display: none !important;
                }
            }
        }
    }

    .show{
        width: 330px !important;
        .offcanvas-header, .offcanvas-body{
            display: flex !important;
        }
        &:before{
            right: -6%;
        }
        .boton-toggler{
            right: -3%;
            transition: all 0.2s ease;
        }
    }
    .progreso{
        .gris{
            border-radius: 50%;
            width: 56px;
            height: 56px;
            position: relative;
            background-color: #E3E3E3;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            .blanco{
                background-color: #fff;
                height: 48px;
                width: 48px;
                border-radius: 50%;
                display: flex;       
                justify-content: center;
                align-items: center; 
                z-index: 1;
                h6{
                    font-weight: 600;
                    font-size: 18px;
                    color: var(--colorPrimary);
                }
            }
            .azul{
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: var(--colorPrimary);
                width: 0;
                height: 110%;
                max-height: 103%;
                border-radius: 35%;
                transform: rotate(0);
                transition: all 1s ease-out;;
            }
        }
    }
}
@media (max-width: 1440px) {
    .offcanvas-header, .offcanvas-body{
        padding: 0px;
        h5{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 25px;
            padding-bottom: 10px;
        }
        h4{
            font-weight: 400;
            font-size: 16px;
            color: #697891;
            text-align: left;
        }
        ul{
            list-style: none;
            left: auto;
            right: 0;
            
            padding: 15px;
            h4{
                color: var(--grisPrimario);
                font-size: 16px;
                font-weight: 700;
                text-align: left;
                padding: 0 0 10px 0;
            }
            li{
                padding: 0;
                width: 100% ;
            }
            .ajustesDropdown{
                box-shadow: 0px 3px 15px #00000029;
                border-radius: 10px;
                border: 0;
                min-width: 230px;
            }
        }
        .close{
            min-height: 45px;
            padding: 25px 0;
            span{
                font-weight: 600;
                font-size: 16px;
                color: var(--colorSecondary);
                padding-left: 10px;
            }
        }
    }
    .progreso{
        .gris{
            border-radius: 50%;
            width: 56px;
            height: 56px;
            position: relative;
            background-color: #E3E3E3;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            .blanco{
                background-color: #fff;
                height: 48px;
                width: 48px;
                border-radius: 50%;
                display: flex;       
                justify-content: center;
                align-items: center; 
                z-index: 1;
                h6{
                    font-weight: 600;
                    font-size: 18px;
                    color: var(--colorPrimary);
                }
            }
            .azul{
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: var(--colorPrimary);
                width: 0;
                height: 110%;
                max-height: 103%;
                border-radius: 35%;
                transform: rotate(0);
                transition: all 1s ease-out;;
            }
        }
    }
    .offcanvas.offcanvas-start{
        min-height: 100%;
    }
}

.border-bottom-div{
    border-bottom: 1px solid #E4E4E4;
}
.offcanvas{
    overflow-y: scroll;
    @media (max-width: 991px) {
        max-height: calc(100vh - 68px);
    }
    &::-webkit-scrollbar{
        border-radius: 200px;
        background-color: rgba(219, 219, 219, 0.25);
        max-width: 5px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #DBDBDB;
        border-radius: 200px;

    }
}
// .offcanvas-body{
//     // max-height: 86vh;
//     max-height: 100%;
//     overflow-y: scroll;
//     .modulos{
//         height: 100%;
//     }
//     &::-webkit-scrollbar{
//         border-radius: 200px;
//         background-color: rgba(219, 219, 219, 0.25);
//         max-width: 5px;
//     }
//     &::-webkit-scrollbar-thumb{
//         background-color: #DBDBDB;
//         border-radius: 200px;

//     }
    
// }
</style>