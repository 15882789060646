<template>
   <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
        <!--- Cursos --->
        <div class="row justify-content-center row__width mis_cursos" v-if="!courseSheet && courses">
            <div class=" col-12 col-lg d-flex justify-content-center justify-content-lg-start align-items-center px-0 max-width">
                <div class="row justify-content-start justify-content-lg-start row__width">
                    <TituloYSubtituloPanelV3 :titulo="$t('message.myCourses')" :subtitulo="courses ? $t('message.youHave')+' '+courses.length+' '+$t('message.totalCourses') : $t('message.youHave')+' 0 '+$t('message.totalCourses')" class="px-3"/>
                    <FiltrosYBuscadorCursosV3 class="px-3 d-none"/>
                    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center tablas mt-lg-5 px-0" >
                        <div class="scroll d-flex justify-content-start align-items-center">
                            <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                <li class="nav-item col-3 d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{{ $t('message.all') }}</button>
                                </li>
                                <!-- <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">{{ $t('message.actions') }}</button>
                                </li>
                                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">{{ $t('message.presets') }}</button>
                                </li>
                                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link" id="contact-tab2" data-bs-toggle="tab" data-bs-target="#contact2" type="button" role="tab" aria-controls="contact2" aria-selected="false">{{ $t('message.eBooks') }}</button>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <!---Con cursos--->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas" v-if="courses.length > 0">
                        <div class="tab-content row justify-content-center  row__width" id="myTabContent">
                            <div class="col-12 tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="row justify-content-center justify-content-lg-start my-3 px-2 px-lg-3 scroll-2 course-grid">
                                    <!-- Elemento curso -->
                                    <div class="d-flex justify-content-center justify-content-lg-start align-items-start slide course-item mb-4" v-for="(item, id) in courses">
                                        <router-link class="row d-flex justify-content-start " :to="'/viendo-curso/'+item.slug+'-'+item.courseId">
                                            <div class="col-12 flex-column d-flex justify-content-center align-items-center">
                                                <div class="row flex-column justify-content-center caption row__width">
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0" v-if="item.presentation">
                                                        <img v-if="item.image" :src="item.image + (item.image.includes('?') ? '&' : '?') + 't=' + new Date().getTime()" alt="">
                                                    </div>
                                                    <!-- <div class="col-12 d-flex justify-content-end align-items-start  arriba d-none" v-if="item.type">
                                                        <h3>{{item.type[0].translations[0].name}}</h3>
                                                    </div> -->
                                                    <!-- <div class="col-12 d-flex justify-content-start align-items-center abajo">
                                                        <h4>{{Math.round(item.courseTotalPercentage)}}% {{ $t('message.classesSeen') }}</h4>
                                                    </div> -->
                                                </div>
                                                <div class="row justify-content-center row__width">
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                        <h2 class="title-fix" v-if="item.translations">
                                                            {{ item.translations.name }}
                                                        </h2>
                                                    </div>
                                                </div>
                                                <!-- <div class="progress row justify-content-start row__width">
                                                    <div class="progress-bar px-0" role="progressbar" :style="`width: ${item.courseTotalPercentage}%`" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div> -->
                                            </div>
                                            <!-- <div class="col flex-column d-flex justify-content-center align-items-center px-0 ">
                                                <div class="row justify-content-center descripcion row__width mt-3 mb-4" v-if="item.translations">
                                                    <div class="col-12 d-flex justify-content-start align-items-center" v-if="item.translations">
                                                        <h2 class="title-fix">{{ item.translations.name }}</h2>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center justify-content-lg-end comprar row__width mb-3 mb-0">
                                                    <div class="col-6 col-lg-3 d-flex justify-content-center align-items-start px-1" >
                                                        <router-link class="btn__primario mirar__ahora" :to="'/viendo-curso/'+item.slug+'-'+item.courseId">Ver curso</router-link>
                                                    </div>
                                                    <div class="col-6 col-lg-3 d-flex justify-content-center align-items-start px-1 d-none" >
                                                        <button @click="courseSheet = true, viewCourse(false), fetchContent(item.courseId)" class="btn__primario">Gestionar</button>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <!-- <SliderMisCursos/> -->
                            </div>
                            <div class="col-12 tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                <!-- <SliderMisCursos/> -->
                            </div>
                            <div class="col-12 tab-pane fade" id="contact2" role="tabpanel" aria-labelledby="contact-tab2">
                                <!-- <SliderMisCursos/> -->
                            </div>
                        </div>
                    </div>
                    <!---Sin cursos--->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas" v-else>
                        <div class="tab-content row justify-content-center  row__width" id="myTabContent">
                            <div class="col-12 tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <h6>{{ $t('message.dontHaveCoursesYet') }}</h6>
                            </div>
                            <div class="col-12 tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <h6>{{ $t('message.dontHaveCoursesYet') }}</h6>
                            </div>
                            <div class="col-12 tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                <h6>{{ $t('message.dontHaveCoursesYet') }}</h6>
                            </div>
                            <div class="col-12 tab-pane fade" id="contact2" role="tabpanel" aria-labelledby="contact-tab2">
                                <h6>{{ $t('message.dontHaveCoursesYet') }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--- Ficha de un producto --->
        <div class="row justify-content-center sheet row__width mt-3" v-else>
            <template v-if="course">
                <div class="col-12 d-flex justify-content-start align-items-center px-0">
                    <button @click="courseSheet = false"  class="p-0">
                        <img src="../../../../img/home-icons/arrow-left-negra.svg" alt="">
                    </button>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center mt-4 px-0">
                    <div class="row justify-content-center slide slide2">
                        <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center px-0 caption" v-if="course.image">
                            <img :src="course.image" alt="">
                        </div>
                        <div class="col-9 col-lg-8 d-flex flex-column justify-content-center align-items-start">
                            <h3>{{ course.name }}</h3>
                        </div>
                        <div class="col-12 col-lg d-flex justify-content-center align-items-center px-0">
                            <router-link :to="'/viendo-curso/'+course.slug+'/'+course.courseId" class="btn__primario mirar__ahora w-100">    
                                Ver curso
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas mt-3 px-0" v-if="!courseContent">
                    <div class="scroll d-flex justify-content-center align-items-center">
                        <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                            <li class="nav-item col-10 col-lg-4 d-flex justify-content-center align-items-center px-0" role="presentation">
                                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Centro de evaluaciones</button>
                            </li>
                            <!-- <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">{{ $t('message.actions') }}</button>
                            </li>
                            <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">{{ $t('message.presets') }}</button>
                            </li>
                            <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                <button class="nav-link" id="contact-tab2" data-bs-toggle="tab" data-bs-target="#contact2" type="button" role="tab" aria-controls="contact2" aria-selected="false">{{ $t('message.eBooks') }}</button>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas px-0" v-if="!courseContent">
                    <div class="tab-content row justify-content-center  row__width mt-4" id="myTabContent">
                        <div class="col-12 tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row justify-content-center slide slide3">
                                <h5>Nuevas entregas</h5>
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas mt-2 px-0">
                                    <div class="scroll d-flex justify-content-start justify-content-lg-center align-items-center">
                                        <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                            <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                                <button class="nav-link active" id="new-task-tab" data-bs-toggle="tab" data-bs-target="#new-task" type="button" role="tab" aria-controls="new-task" aria-selected="true">Tareas</button>
                                            </li>
                                            <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                                <button class="nav-link" id="new-evaluations-tab" data-bs-toggle="tab" data-bs-target="#new-evaluations" type="button" role="tab" aria-controls="new-evaluations" aria-selected="false">Evaluaciones</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas">
                                    <div class="tab-content row justify-content-center  row__width" id="myTabContent">
                                        <div class="col-12 tab-pane fade show active" id="new-task" role="tabpanel" aria-labelledby="new-task-tab">
                                            <div class="px-0" v-if="tasks">
                                                <div class="row justify-content-center justify-content-lg-start slide slide4" v-for="(task, index) in tasks.filter(task => task.status == 0)" :key="task.id">
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                        <div class="row justify-content-center row__width">
                                                            <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Nombre del profesor</h6>
                                                                <h4>{{ task.professor }}</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-3 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Fecha</h6>
                                                                <h4>{{ formattedDate(task.upload_at) }}</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-3 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Estado</h6>
                                                                <h4 class="pending" v-if="task.status == 0">Pendiente</h4>
                                                                <h4 class="approved" v-else-if="task.status == 1">Aprobado</h4>
                                                                <h4 class="deprecated" v-else>Desaprobado</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center mt-3 mt-lg-0">
                                                                <button class="btn__primario" @click="viewCourse(true, task)">
                                                                    Ver
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 tab-pane fade" id="new-evaluations" role="tabpanel" aria-labelledby="new-evaluations-tab">
                                            <div class="px-0" v-if="fp && fp.approved == 0">
                                                <div class="row justify-content-center justify-content-lg-start slide slide4">
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                        <div class="row justify-content-center row__width">
                                                            <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Nombre del profesor</h6>
                                                                <h4>{{ fp.professor_name }}</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-3 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Fecha</h6>
                                                                <h4>{{ formattedDate(fp.upload_at) }}</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-3 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Estado</h6>
                                                                <h4 class="pending" v-if="fp.approved == 0">Pendiente</h4>
                                                                <h4 class="approved" v-else-if="fp.approved == 1">Aprobado</h4>
                                                                <h4 class="deprecated" v-else>Desaprobado</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center mt-3 mt-lg-0">
                                                                <button class="btn__primario" @click="viewCourse(true, fp)">
                                                                    Ver
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center slide slide3">
                                <h5>Entregas calificadas</h5>
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas mt-2 px-0">
                                    <div class="scroll d-flex justify-content-start justify-content-lg-center align-items-center">
                                        <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                            <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                                <button class="nav-link active" id="task-tab" data-bs-toggle="tab" data-bs-target="#task" type="button" role="tab" aria-controls="task" aria-selected="true">Tareas</button>
                                            </li>
                                            <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                                <button class="nav-link" id="evaluations-tab" data-bs-toggle="tab" data-bs-target="#evaluations" type="button" role="tab" aria-controls="evaluations" aria-selected="false">Evaluaciones</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas">
                                    <div class="tab-content row justify-content-center  row__width" id="myTabContent">
                                        <div class="col-12 tab-pane fade show active" id="task" role="tabpanel" aria-labelledby="task-tab">
                                            <div class="px-0" v-if="tasks">
                                                <div class="row justify-content-center justify-content-lg-start slide slide4" v-for="(task, index) in tasks.filter(task => task.status == 1 || task.status == 2)" :key="task.id">
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                        <div class="row justify-content-center row__width">
                                                            <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Nombre del profesor</h6>
                                                                <h4>{{ task.professor }}</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-3 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Fecha</h6>
                                                                <h4 v-if="task.upload_at">{{ formattedDate(task.upload_at) }}</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-3 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Estado</h6>
                                                                <h4 class="pending" v-if="task.status == 0">Pendiente</h4>
                                                                <h4 class="approved" v-else-if="task.status == 1">Aprobado</h4>
                                                                <h4 class="deprecated" v-else>Desaprobado</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center mt-3 mt-lg-0">
                                                                <button class="btn__primario" @click="viewCourse(true, task)">
                                                                    Ver
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 tab-pane fade" id="evaluations" role="tabpanel" aria-labelledby="evaluations-tab">
                                            <div class="px-0" v-if="fp && fp.approved != 0">
                                                <div class="row justify-content-center justify-content-lg-start slide slide4">
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                        <div class="row justify-content-center row__width">
                                                            <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Nombre del profesor</h6>
                                                                {{ fp }}
                                                                <h4>{{ fp.professor_name }}</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-3 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Fecha</h6>
                                                                <h4>{{ formattedDate(fp.upload_at) }}</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-3 d-flex flex-column justify-content-center align-items-start">
                                                                <h6>Estado</h6>
                                                                <h4 class="pending" v-if="fp.approved == 0">Pendiente</h4>
                                                                <h4 class="approved" v-else-if="fp.approved == 1">Aprobado</h4>
                                                                <h4 class="deprecated" v-else>Desaprobado</h4>
                                                            </div>
                                                            <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center mt-3 mt-lg-0">
                                                                <button class="btn__primario" @click="viewCourse(true, fp)">
                                                                    Ver
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            
                        </div>
                        <div class="col-12 tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            
                        </div>
                        <div class="col-12 tab-pane fade" id="contact2" role="tabpanel" aria-labelledby="contact-tab2">
                            
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-4 px-0" v-else>
                    <!-- TAREA -->
                    <div class="row justify-content-center justify-content-lg-start slide slide5 row__width" v-if="actualTask.taskId">
                        <div class="col-12 d-flex justify-content-between align-items-center px-0 flex-cambio">
                            <h5 class="px-0">{{ actualTask.taskName }}</h5>
                            <h4 v-if="actualTask.upload_at">Fecha: {{ formattedDate(actualTask.upload_at) }}</h4>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center px-0 profile">
                            <div class="row justify-content-center row__width">
                                <div class="col-2 col-lg-1 d-flex justify-content-start align-items-center px-0">
                                    <img :src="actualTask.avatar" alt="">
                                </div>
                                <div class="col d-flex justify-content-start align-items-center">
                                    <h3>{{ actualTask.professor }}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center px-0 evaluation">
                            <img :src="actualTask.file" alt="">
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-4 description">
                            <div class="row justify-content-center row__width width">
                                <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                    <h3>Comentario</h3>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center px-0 position-relative">
                                    <span>
                                        <h6>B</h6>
                                    </span>
                                    <textarea name="" id="" cols="30" rows="10" maxlength="400" v-model="actualTask.comment" disabled></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center mt-4 px-0">
                            <div class="row justify-content-center justify-content-lg-between row__width">
                                <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start mb-3 mb-lg-0">
                                    <h6>Estado</h6>
                                    <h4 class="pending" v-if="actualTask.status == 0">Pendiente</h4>
                                    <h4 class="approved" v-else-if="actualTask.status == 1">Aprobado</h4>
                                    <h4 class="deprecated" v-else>Desaprobado</h4>
                                </div>
                                <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center">
                                    <button class="btn__primario" @click="viewCourse(false)">
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- PROYECTO FINAL -->
                    <div class="row justify-content-center justify-content-lg-start slide slide5 row__width" v-else-if="actualTask.fp_id">
                        <div class="col-12 d-flex justify-content-between align-items-center px-0 flex-cambio">
                            <h5 class="px-0">{{ actualTask.title }}</h5>
                            <h4 v-if="actualTask.upload_at">Fecha: {{ formattedDate(actualTask.upload_at) }}</h4>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center px-0 profile">
                            <div class="row justify-content-center row__width">
                                <div class="col-2 col-lg-1 d-flex justify-content-start align-items-center px-0">
                                    <img :src="actualTask.avatar" alt="">
                                </div>
                                <div class="col d-flex justify-content-start align-items-center">
                                    <h3>{{ actualTask.professor_name }}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center px-0 evaluation">
                            <img :src="actualTask.uploaded_file" alt="">
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-4 description">
                            <div class="row justify-content-center row__width width">
                                <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                    <h3>Comentario</h3>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center px-0 position-relative">
                                    <span>
                                        <h6>B</h6>
                                    </span>
                                    <textarea name="" id="" cols="30" rows="10" maxlength="400" v-model="actualTask.comment" disabled></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center mt-4 px-0">
                            <div class="row justify-content-center justify-content-lg-between row__width">
                                <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start mb-3 mb-lg-0">
                                    <h6>Estado</h6>
                                    <h4 class="pending" v-if="actualTask.approved == 0">Pendiente</h4>
                                    <h4 class="approved" v-else-if="actualTask.approved == 1">{{ score }} - Aprobado</h4>
                                    <h4 class="deprecated" v-else>{{ score }} - Desaprobado</h4>
                                </div>
                                <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center">
                                    <button class="btn__primario" @click="viewCourse(false)">
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        
    </div>
</template>

<script setup>
import BotonesSlidersPanel from "../../../Botones/SlidersPanel.vue";
import AtrasBTNCertificados from "../../../Botones/Atras.vue";
import FiltrosYBuscadorCursosV3 from "../../../Filtros/FiltrosYBuscadorCursosV3.vue";
import TituloYSubtituloPanelV3 from "../../../Titulo/TituloYSubtituloPanelV3.vue";


import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"
import { useLanguageStore } from "../../../../stores/langs"
import { ref, onMounted, inject} from 'vue';
import axios from 'axios';

const {locale} = useI18n({ useScope: 'global' })

const users = useUsersStore();
const { access, idUser } = storeToRefs(users);
const { url, validate } = users;

const langs = useLanguageStore();
const {listLangs} = storeToRefs(langs);

let lang = 'ES'

const courseContent = ref(false)
const course = ref()

const courses = inject('courses')
const courseSheet = inject('courseSheet')
const loader = inject('loader')
const fetchTasksStudent = inject('fetchTasksStudent')
const fetchFPStudent = inject('fetchFPStudent')
let tasks = inject('tasks')
let fp = inject('fp')
let actualTask = inject('actualTask')
let isSidebarOpen = inject('isSidebarOpen')

const formattedDate = (taskDate)=>{
    if (!taskDate) return ''
    const date = new Date(taskDate)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

const fetchContent = async(x) => {
    loader.value = true
    //console.log(professionalData.value.id)
    let lang = 'ES'
    let idProf = JSON.parse(localStorage.getItem('idProf'))
    let id = idProf.id
    //console.log(id)
    try {
        await axios.get(url+'/api/course/'+lang+'/'+'asd/'+x).then(result => {
            console.log(result)
            course.value = result.data
            console.log(result.data)
            result.data.presentation.find((item) => {
                console.log(item);
                if(item.image){
                    course.value.image = item.image
                }
            })
            fetchTasksStudent(x)
            fetchFPStudent(x)
            console.log(course.value.image)
        })
        loader.value = false
    } catch (error) {
        console.log(error)
        loader.value = false
    }
}

const viewCourse = (x, task = null) =>{
    courseContent.value = x
    actualTask.value = null
    actualTask.value = task
}

</script>

<style lang="scss" scoped>
.mis_cursos{
    margin: 2vh 0;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 15px 0;
    @media (min-width: 992px) {
        margin: 3vh 0;
        padding: 40px 30px;
        border-radius: 28px;
        .max-width{
            max-width: 97%;
        }
    }
}

.tablas{
    h6{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
        padding: 2vh 0 6vh 0;
    }
    .nav-link{
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        position: relative;
        padding: 10px 0;
        border: 0;
    }
    .nav-link.active{
        font-weight: 700;
        border: 0;
        color: var(--colorSecondary);
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 0;
            left: 0;
            background-color: var(--colorPrimary);
            
        }
        @media (min-width: 992px) {
            &::before{
                height: 4px;
            }   
        }
    }
    .scroll{
        overflow-x: scroll;
        max-width: 100%;
        min-width: 100%;
        ul{
            min-width: 100%;
            padding: 0 20px;
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
    }
}

.course-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px 0;

    @media (max-width: 767px) {
        grid-template-columns: 1fr; // One item per row on small screens
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        grid-template-columns: repeat(3, 1fr); // Three items per row on medium screens
    }

    @media (min-width: 1440px) {
        grid-template-columns: repeat(4, 1fr); // Four items per row on large screens
    }
}

// Mixin for the shadow and border radius
@mixin box-style {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
}

.course-item, .slide {
    @include box-style;
    padding: 10px;
    opacity: 1;

    img, video, .plyr__video-embed {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        @include box-style;
    }

    h2 {
        font-weight: 600;
        font-size: 17px;
        color: var(--colorSecondary);
        text-align: center;
        margin: 10px 0 !important;
    }
}

Responsive image size adjustments
.course-item video, .course-item img, .course-item .plyr__video-embed, .slide video, .slide img,  .slide .plyr__video-embed {
    @media (max-width: 767px) {
        height: 284px !important;
    }
}

// Additional slide-specific styles
.slide {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    min-height: auto;
    max-height: auto;
    padding: 0 10px;

    .caption{
        position: relative;
        margin: 10px 0px;
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
            filter: opacity(1);
            border-radius: 15px;
            z-index: 1;
        }
        video, img, .plyr__video-embed{
            width: 100%;
            max-width: 100%;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 15px;
            height: 284px;
            object-fit: cover;
            &:focus, &:hover{
                .position{
                    display: none;
                }
            }
        }
        .arriba{
            position: absolute;
            top: 10%;
            z-index: 3;
            span{
                box-shadow: 0px 3px 10px #00000029;
                border-radius: 19px;
                background-color: rgba(26, 23, 35, 0.47);
                height: 40px;
                width: 90px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            h3{
                font-weight: 700;
                font-size: 16px;
                color: var(--bgPrincipal);
                
            }
        }
        .abajo{
            position: absolute;
            bottom: 5%;
            white-space: nowrap;
            z-index: 3;
            h4{
                color: #B8C1DB;
                font-weight: 400;
                font-size: 18px;
            }
        }
    }
    .descripcion{
        h2{
            font-weight: 600;
            font-size: 17px;
            margin-left: 5px;
            color: var(--colorSecondary);
            text-align: left;
            text-wrap: wrap;
            overflow: hidden;
            text-overflow: clip;
        }
    }
    .progress{
        height: 10px;
        margin-bottom: 10px;
        .progress-bar{
            background-color: #1679FC;
            border-radius: 50px;
        }
    }
}


// .slide{
//     .caption{
//         position: relative;
//         margin: 10px 0px;
//         &::before{
//             content: '';
//             position: absolute;
//             bottom: 0;
//             width: 100%;
//             height: 100%;
//             background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
//             filter: opacity(1);
//             border-radius: 15px;
//             z-index: 1;
//         }
//         video, img, .plyr__video-embed{
//             width: 100%;
//             max-width: 100%;
//             box-shadow: 0px 3px 6px #00000029;
//             border-radius: 15px;
//             height: 200px;
//             object-fit: cover;
//             &:focus, &:hover{
//                 .position{
//                     display: none;
//                 }
//             }
//         }
//         .arriba{
//             position: absolute;
//             top: 10%;
//             z-index: 3;
//             span{
//                 box-shadow: 0px 3px 10px #00000029;
//                 border-radius: 19px;
//                 background-color: rgba(26, 23, 35, 0.47);
//                 height: 40px;
//                 width: 90px;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//             }
//             h3{
//                 font-weight: 700;
//                 font-size: 16px;
//                 color: var(--bgPrincipal);
                
//             }
//         }
//         .abajo{
//             position: absolute;
//             bottom: 5%;
//             white-space: nowrap;
//             z-index: 3;
//             h4{
//                 color: #B8C1DB;
//                 font-weight: 400;
//                 font-size: 18px;
//             }
//         }
//     }
//     .descripcion{
//         h2{
//             font-weight: 600;
//             font-size: 17px;
//             margin-left: 5px;
//             color: var(--colorSecondary);
//             text-align: left;
//             text-wrap: wrap;
//             overflow: hidden;
//             text-overflow: clip;
//         }
//     }
//     .progress{
//         height: 10px;
//         margin-bottom: 10px;
//         .progress-bar{
//             background-color: #1679FC;
//             border-radius: 50px;
//         }
//     }
// }

.sheet{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 28px;
    padding: 25px;
    animation: 0.3s fadein ease;
    h2{
        font-size: 20px;
        font-weight: 600;
        color: var(--colorSecondary);
        margin-top: 20px;
    }
    .scroll-2{
        max-height: 65vh;
        overflow-y: scroll !important;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
            background-color: #29292b1e;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #29292b1e;
        }
    }
    .slide{
        background-color: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        min-height: auto;
        padding: 10px 0;
        margin-bottom: 20px;
        min-width: 100%;
        max-height: none;
        .see-more{
            font-size: 14px;
            color: var(--colorPrimary);
            font-weight: 500;
            text-decoration: underline;
        }
        .border-left{
            border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
        }
        h3{
            color: #0E1133;
            font-weight: 700;
            font-size: 16px;
            text-align: left;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        h4{
            font-weight: 400;
            font-size: 16px;
            color: #B8C1DB;
            text-align: left;
            padding-top: 3px;
            max-height: 60px;
            max-width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            
        }
        h5{
            color: #0E1133;
            font-weight: 600;
            font-size: 22px;
            padding: 10px 15px;
            text-align: left;
            max-width: 100%;
            overflow: hidden;
            word-break: break-all;
        }
        .acciones{
            color: #B8C1DB;
            font-size: 14px;
            font-weight: 500;
        }
        .dropdown-toggle{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 13px;
            text-decoration: underline;
            padding: 0;
            img{
                margin-left: 5px;
            }
            &::after{
                display: none;
            }
            @media (min-width: 425px) {
                font-size: 16px;
            }
        }
        .caption{
            position: relative;
            height:83px ;
            img{
                border-radius: 10px;
                width: 100%;
                height:83px ;
                object-fit: cover;
            }
            &::before{
                content: '';
                position: absolute;
                bottom: 0;
                width: 108%;
                max-width: 100%;
                height: 100%;
                background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
                filter: opacity(1);
                border-radius: 0px 0px 10px 10px;
                z-index: 1;
            }
        }
    }
    .slide2{
        background-color: #FFFFFF;
        box-shadow: none;
        border-radius: 0;
        min-height: auto;
        padding: 0;
        h3{
            font-size: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
        }
    }
    .slide3{
        background-color: #ebebeb29;
        box-shadow: 0px 3px 10px #0000002d;
    }
    .slide4{
        margin: 20px 0;
        background-color: #f5f5f5;
        h6{
            padding-top: 5px;
            padding-bottom: 10px;
            font-weight: 600;
            font-size: 16px;
            text-align: left;
        }
        h4{
            padding-top: 0;
            font-weight: 500;
            text-align: left;
        }
        .pending{
            color: #FEB842;
        }
        .deprecated{
            color: #FF576E;
        }
        .approved{
            color: #38E6A7;
        }
    }
    .slide5{
        background-color: #F5F5F5;
        box-shadow: 0px 3px 10px #0000002d;
        padding: 25px;
        h4{
            color: #697891;
            text-align: left;
        }
        .profile{
            margin: 20px 0;
            img{
                max-height: 50px;
                width: 50px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        .evaluation{
            img{
                width: 100%;
                height: 400px;
                border-radius: 20px;
                object-fit: cover;
            }
        }
        .pending{
            color: #FEB842;
        }
        .deprecated{
            color: #FF576E;
        }
        .approved{
            color: #38E6A7;
        }
    }
    .mirar__ahora{
        font-size: 14px;
        width: 90%;
        height: 40px;
        border-radius: 20px;
        border: 2px solid var(--colorSecondary);
    }
    .tipo__reunion{
        label{
            height: 30px;
            width: 100%;
            box-shadow: 0px 0px 6px #00000029;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            background-color: #EBEEF9;
            &::before{
                content: '';
                position: absolute;
                height: 100%;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #38E6A7;
                border-radius:  28px;
                left: 0;
                transition: all 0.5s;
            }
            p{
                font-weight: 500;
                font-size: 14px;
                color: var(--bgPrincipal);
                z-index: 1;
                transition: all 0.5s;
                &:first-child{
                    margin-right: 0%;
                }
                &:last-child{
                    margin-left: 0%;
                }
            }
            .left{
                color: var(--bgPrincipal);
                font-weight: 600;
            }
            .right{
                color: var(--colorSecondary);
            }
        }
        input{
            display: none;
        }
        input:checked + label{
            &::before{
                left: 49%;
                width: 50%;
                background-color: #FF576E;
            }
            p{
                color: var(--bgPrincipal);
                font-weight: 600;
                
            }
            .left{
                color: var(--colorSecondary);
                font-weight: 500;
            }
        }
        @media (min-width: 992px) {
            label{
                .right{
                    color: var(--colorSecondary);
                }
            }
            input:checked + label{
                .left{
                    color: var(--colorSecondary);
                }
            }
        }
    }
    .description{
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            padding: 7px 0px;
        }
        p{
            color: var(--colorPrimary);
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            padding: 7px 0px;
        }
        .width{
            width: -webkit-fill-available;
        }
        textarea{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: #FCFCFC;
            height: 20vh;
            padding-top: 50px;
            padding-left: 15px;
            outline: 0;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                
                color: #697891;
            }
            
        }
        span{
            background-color: #E4E4E4;
            height: 40px;
            width: 99.6%;
            position: absolute;
            top: 2px;
            border-radius: 10px 10px 0 0 ;
            padding: 10px 15px;
            h6{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 600;
                text-align: left;
            }
        }
    }
}
.title-fix{
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 14px;
    color: var(--colorSecondary);
    text-align: left;
}
.flex-cambio{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; 
    }
}
.scroll-2{
    max-height: 65vh;
    overflow-y: scroll !important;
    overflow-x: hidden;
    &::-webkit-scrollbar{
        -webkit-appearance: none;
        width: 6px;
        height: 4px;
        background-color: #272d3b0c;
        border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #272d3b18;
        border-radius: 100px;
    }
}
.class70plus{
    width: calc(70vw - 280px) !important;
}
.class70{
    width: 70vw !important;
}

@keyframes fadein {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.row__width{
    width: 100%;
}


</style>