<template>
    <form action="" class="row__width justify-content-center mb-5" @submit.prevent="fetchRegistro" @input="resetValidations($event)">
        <NombreApellidoTerminar/>
        <!-- <FechaNacimientoTerminar/> -->
        <!-- <Gender/> -->
        <Country/>
        <SelectTelefono/>
        <MailTerminar/>
        <PasswordTerminar/>
        <ErrorValidacion v-if="errorV"/>
        <TerminosYCondicionesTerminar :acepto="$t('message.iAgree')" :terminos="$t('message.terms')"/>
        <GrisInicioV2 :textoBtn="$t('message.createAccount')" type="submit" funcion="" :isValid="isValid"/>
    </form>
</template>

<script setup>
import NombreApellidoTerminar from '../Inputs/NombreApellidoTerminar.vue';
import FechaNacimientoTerminar from '../Inputs/FechaNacimientoTerminar.vue';
import SelectTelefono from '../Inputs/SelectTelefono.vue';
import MailTerminar from '../Inputs/MailTerminar.vue';
import Gender from '../Inputs/Gender.vue';
import Country from '../Inputs/Country.vue';
import ErrorValidacion from '../Errores/ErrorValidacion.vue';
import PasswordTerminar from '../Inputs/PasswordTerminar.vue';
import TerminosYCondicionesTerminar from '../TerminosYCondiciones/TerminosYCondicionesTerminar.vue';
import GrisInicioV2 from '../Botones/GrisInicioV2.vue';
import axios from "axios"
import { useRouter } from 'vue-router';
// Importo mi método
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../stores/user";
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia";
import { ref, provide, computed } from 'vue';

import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'

const { t }  = useI18n()
const msg = computed(() => t('message'))

const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;
const router = useRouter();
const users = useUsersStore();
const { cambioHeader, usuariosRegistro, tokenAccess, usuarioRegistroInfoExtra } = storeToRefs(users);
const { usuarioData } = users
//-------------------//
const url = process.env.VUE_APP_BASE_URL
const urlToRegister = '/api/auth/register';
const urlUserSlug = '/api/user/'
const validarMail = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i

//-------------------//
const errorV = ref(false)
const estados = ref({
    first_name: '',
    last_name: '',
    birthdate: '',
    gender: '',
    country: '',
    phone_number: '',
    email: '',
    password: '',
    terms: ''
})

const isValid = computed(() => {
    return Object.values(usuariosRegistro.value).every(field => {
        if (typeof field === 'boolean') {
            return field; // Para el checkbox terms
        }
        if (typeof field === 'string') {
            return field.trim() !== '';
        }
        return false; // Si no es ni booleano ni string, retornamos false
    });
});


const resetStates = (p) => {
    if (p == 'password') {
        errorV.value = false
    }
    let input = ''
    switch (p) {
        case 'gender':
            input = 'gender'
            break;
        case 'first_name':
            input = 'first_name'
            break;
        case 'last_name':
            input = 'last_name'
            break;
        case 'birthdate':
            input = 'birthdate'
            break;
        case 'gender':
            input = 'gender'
            break;
        case 'phone_number':
            input = 'phone_number'
            break;
        case 'email':
            input = 'email'
            break;
        case 'password':
            input = 'password'
            break;
        case 'terms':
            input = 'terms'
            break;
    }
    estados.value[input] = ''
}

const resetValidations = (e) => {
    if (e.srcElement.labels.length > 0) {
        const hasInputInvalido = e.srcElement.labels[0].classList.contains('input_invalido');
        const hasInputInvalidoMazui = e.srcElement.labels[0].classList.contains('input_invalido_mazui');
        if (hasInputInvalido) {
            resetStates(e.target.name)
        }
        else if (hasInputInvalidoMazui) {
            resetStates(e.target.name)
        }
        else if (e.target.name == 'terms'){
            resetStates('terms')
        }
    }
    else {
        const hasInputInvalido = e.srcElement.classList.contains('input_invalido');
        const hasInputInvalidoMazui = e.srcElement.classList.contains('input_invalido_mazui');
        if (hasInputInvalido) {
            resetStates(e.target.name)
        }
        else if (hasInputInvalidoMazui) {
            resetStates(e.target.name)
        }
    }
}

const validarNombre = () => {
    if (usuariosRegistro.value.first_name !== ''){
        estados.value.first_name = 'input_valido'
        return true
    }
    else {
        estados.value.first_name = 'input_invalido'
        return false
    }
}
const validarApellido = () => {
    if (usuariosRegistro.value.last_name !== ''){
        estados.value.last_name = 'input_valido'
        return true
    }
    else {
        estados.value.last_name = 'input_invalido'
        return false
    }
}
const validarFecha = () => {
    if (usuariosRegistro.value.birthdate !== '') {
        console.log(usuariosRegistro.value.birthdate)
        const parts = usuariosRegistro.value.birthdate.split('-');
        const year = parseInt(parts[0], 10);
        console.log(year)
        if (year > 1900) {
            estados.value.birthdate = 'input_valido';
            return true;
        } else {
            estados.value.birthdate = 'input_invalido';
            return false;
        }
    } else {
        estados.value.birthdate = 'input_invalido';
        return false;
    }
}
const validarGenero = () => {
    if (usuariosRegistro.value.gender !== ''){
        estados.value.gender = 'input_valido'
        return true
    }
    else {
        estados.value.gender = 'input_invalido'
        return false
    }
}
const validarPais = () => {
    if (usuariosRegistro.value.country !== ''){
        estados.value.country = 'input_valido_mazui'
        return true
    }
    else {
        estados.value.country = 'input_invalido_mazui'
        return false
    }
}
const validarPhone = () => {
    if (usuariosRegistro.value.phone_number !== ''){
        estados.value.phone_number = 'input_valido_mazui'
        return true
    }
    else {
        estados.value.phone_number = 'input_invalido_mazui'
        return false
    }
}
const validarEmail = () => {
    if (usuariosRegistro.value.email !== '' && validarMail.test(usuariosRegistro.value.email)){
        estados.value.email = 'input_valido'
        return true
    }
    else {
        estados.value.email = 'input_invalido'
        return false
    }
}
const validarPassword = () => {
    if (usuariosRegistro.value.password !== undefined && usuariosRegistro.value.password.length >= 6) {
        estados.value.password = "input_valido"
        errorV.value = false
        return true
    }
    else {
        estados.value.password = "input_invalido"
        errorV.value = true
        return false
    }
}
const validarTerminos = () => {
    if (usuarioRegistroInfoExtra.value.check == true){
        estados.value.terms = 'text_valido'
        return true
    }
    else {
        estados.value.terms = 'text_invalido'
        return false
    }
}

provide("estados", estados)
//Hago un post del usuario 
const fetchRegistro = async() => {
    const validName = validarNombre()
    const validLastName = validarApellido()
    const validCountry = validarPais()
    const validPhone = validarPhone()
    const validEmail = validarEmail()
    const validPass = validarPassword()
    const validTerms = validarTerminos()
    console.log( validName, validLastName, validCountry, validPhone, validEmail, validPass, validTerms)
    console.log('acá 1')
    if( validName && validLastName && validCountry && validPhone && validEmail && validPass && validTerms){
        try {
            console.log('acá 2')
            await axios.post(url+urlToRegister, usuariosRegistro.value).then((result) => {
                console.log(result, 'resultado registro')
                tokenAccess.value = result.data.data.token
                let userId = {id: result.data.data.user_id, team: result.data.data.team_id}
                let dataId = {id: result.data.data.user_id, team: result.data.data.team_id}
                localStorage.setItem('idUser', JSON.stringify(userId))
                localStorage.setItem('idData', JSON.stringify(dataId))
                localStorage.setItem('token', JSON.stringify(tokenAccess.value))
                usuarioData()
                cambioHeader.value = false
                return {cambioHeader}
            })
            //pusheo al index 
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: t('message.registeredSuccessfully')
            })
            setTimeout(() => {
                router.push('/')
            }, 4000);
            
        } catch (error) {
            console.log(error, 'error')
            console.log(usuariosRegistro.value)
            if(error){
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message
                })
            }
        }
    }else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: t('message.invalidData')
        })
    }
}
</script>


<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 0 0px 10px 0;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
</style>