 <template>
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" v-if="modality == 1 || modality == 4">
        <h3>Estado</h3>
        <div class="justify-content-center row__width">
            <div class="col d-flex justify-content-center align-items-start position px-0">
                <MazSelect
                    class="row__width"
                    v-model="information.state_id"
                    placeholder="Seleccionar estado"
                    color="info"
                    :options="states"
                    name="state"
                    search
                    search-placeholder="Buscar en la lista"
                    @update:model-value="fetchCities(information.state_id); information.city_id = null"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../../stores/general"    
import { useConfigStore } from "../../../stores/config"
import { storeToRefs } from "pinia";
import { inject } from "vue";
import MazSelect from 'maz-ui/components/MazSelect'


const information = inject('information')
const modality = inject('modality')

const general = useGeneralStore();
const {inputs} = storeToRefs(general);
const {} = general;
const informacionPerfilProfesional = inject('informacionPerfilProfesional')
const validez = inject('validez')

const config = useConfigStore()
const { countries, states, cities } = storeToRefs(config)
const { fetchCountries, fetchStates, fetchCities } = config
</script>

<style lang="scss" scoped>
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
    &:focus + i, &:hover + i{
        background-image: url('../../../img/panel-icons/ubi-active.svg');
    }
}
.contenedor{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    i{
        position: absolute;
        right: 10px;
        width: 20px;
        height: 25px;
        display: block;
        background-repeat: no-repeat;
        background-image: url('../../../img/panel-icons/ubi.svg');
    }
}
</style>