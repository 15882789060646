<template>
    <div class="col-12 d-flex flex-column justify-content-start align-items-start texto_mas ql-editor-cursos">
        <div class="ql-editor w-100" v-html="userAboutCorrected" v-if="description_is_html"></div>
        <div class="ql-editor w-100" v-else>{{ product.description }}</div>
        <!-- <BTNMostrarMas :funcionTexto="mostrarTexto" :img="iconoImg" class="d-lg-none"/> -->

    </div>
</template>

<script setup>
    import BTNMostrarMas from "../Botones/MostrarMas.vue";
    import { ref, inject, onMounted } from 'vue';

    const product = inject('product')

    const iconoImg = ref(false)

    const mostrarTexto = () => {
        let element = document.getElementById("filtro")
        console.log('funciona')
        element.classList.toggle("before");
        element.classList.toggle('display_all')
        if(iconoImg.value == false){
            iconoImg.value =true
        }else{
            iconoImg.value =false
        }
    }

    const description_is_html = ref(false);

    const userAboutCorrected = ref('');
    
    const fixLinks = (description) => {
        const div = document.createElement('div');
        div.innerHTML = description;

        const links = div.querySelectorAll('a');

        links.forEach(link => {
            const href = link.getAttribute('href');
            if (href && !href.startsWith('http://') && !href.startsWith('https://')) {
            link.setAttribute('href', 'https://' + href);
            }
        });

        return div.innerHTML;
    }

    const seeDescription = () => {
        userAboutCorrected.value = fixLinks(product.value.description)
        if (fixLinks(product.value.description)) {
            description_is_html.value = true
        } else {
            description_is_html.value = false
        }
    }

    onMounted(() => {
        seeDescription()
    })

    //console.log(product.value.description)

</script>

<style lang="scss" scoped>
.texto_mas{
    p, li{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
        height: max-content;
        @media (max-width: 992px) {
            display: -webkit-box;
            -webkit-line-clamp: 5; 
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .display_all{
        overflow: visible;
        text-overflow: unset;
        display: block;
    }
    ul{
        padding: 0;
        max-width: 100%;
        position: relative;
        li{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 30px;
            min-height: 40px;
            width: 100%;
            margin-bottom: 10px;
            &::before{
                content: url('../../img/home-icons/cursos-icons/tick.png');
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
            }
        }
    }
    .seccion{
        padding: 10px 10px;
        img{
            padding-right: 10px;
        }
    }
    h6{
        font-weight: 500;
        font-size: 18px;
        color: var(--colorSecondary);
        padding: 15px 0px;
        display: none;
        text-align: left;
        @media (min-width: 992px) {
            display: flex;
        }
    }
}



</style>