<template>
    <div class="container-fluid">
        <NavbarBuscador/>
        <HeaderTitulo class="d-none d-lg-flex" tituloHeader="Home / Perfil"/>
        <div class="row justify-content-center">
            <!-- <BannerV2   :banner="professional.banner"/> -->
            <!-- {{professional}} -->
            <DescripcionV2  :userName="professional.name"
                            :userPic="professional.avatar"
                            :country="professional.location.country"
                            :state="professional.location.state"
                            :city="professional.location.city"
                            :ranking="professional.ranking"
                            :role="professional.role"
                            :profession="professional.profession"
                            :social_network="professional.social_networks"
                            :contact="professional.contact"
                            :services="professional.services"
                            :average="professional.reviews.average"
                            :total="professional.reviews.total"
                            />
            <TabsPerfil/>
            <ContenidoPerfil    :userName="professional.name"
                                :userAbout="professional.about"
                                :avatar="professional.avatar"
                                :gallery="professional.gallery"
                                :courses_uploaded="professional.courses_uploaded"
                                :images_uploaded="professional.images_uploaded"
                                />
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 ">
                <!-- <PanelSeccionMarcas/> -->
            </div>
        </div>
        <Footer :activeHome="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
    import HeaderTitulo from "../../components/Titulo/HeaderTitulo.vue";
    import Footer from "../../components/Footer/Footer.vue";
    import NavbarBuscador from "../../components/Navs/NavbarBuscador.vue";
    import NavbarBack from "../../components/Navs/Navbar.vue";
    import PanelSeccionMarcas from "../../components/PanelSeccion/PanelSeccionMarcas.vue";
    import DescripcionV2 from "../../components/Perfil/DescripcionV2.vue";
    import TabsPerfil from "../../components/Perfil/TabsPerfil.vue";
    import ContenidoPerfil from "../../components/Perfil/ContenidoPerfil.vue";
    import BannerV2 from "../../components/Perfil/BannerV2.vue";
    import { useUsersStore } from "../../stores/user";
    import { storeToRefs } from "pinia";
    import { ref, onMounted, watch, provide, onBeforeMount } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useI18n } from "vue-i18n";
    import axios from 'axios';
    
    const users = useUsersStore();
    const {cambioHeader} = storeToRefs(users);
    const { url } = users

    const router = useRouter();
    const route = useRoute();

    const urlSearch = process.env.VUE_APP_MEILISEARCH_URL+'/multi-search'
    const urlApiSearch = '/search?q='
    const slugRef = ref('')
    const {locale} = useI18n({ useScope: 'global' })
    const langId = ref()
    const lang = ref('')
    const isSpeaker = ref(false)
    //Setea parametros de busqueda para el post
    // const slug = ref({
    //     queries:[{
    //         indexUid: 'product_products',
    //         q:''
    //     }]
    // })

    //Precarga parametros de resultados de busqueda
    const professional = ref({
        name: '',
        pic: '',
        banner: '',
        location: '',
        website: '',
        ranking: '',
        social_network: '',
        courses: '',
        services: '',
        contact: {
            phone: {
                code: '',
                number: ''
            }
        },
        gallery: {
            files: []
        },
        verified: '',
        reviews: {
            average: '',
            total: ''
        },
        location: {
            country: {
                id: '',
                name: ''
            },
            state: {
                id: '',
                name: ''
            },
            city: {
                id: '',
                name: ''
            }
        },
        courses: {
            type: Object
        },
        portfolio:{
            type: Object
        }
    })
    provide("professional", professional)

    const actual_tab = ref(1)
    provide('actual_tab', actual_tab)

    const courses = ref({})
    provide("courses", courses)
    const products = ref({})
    provide("products", products)

    const actual_gallery = ref(false)
    provide('actual_gallery', actual_gallery)

    const products_list = ref()
    provide('products_list', products_list)

    const resultCoursesLenght = ref({
        
    })
    const resultProductsLenght = ref({
        
    })

    const galeries = ref()
    provide('galeries', galeries)

    const fetchData = async () => {
        const lang = JSON.parse(localStorage.getItem('lang'))
        try {
            const response = await axios.get(`${url}/api/public/professionals/profile/main?professionalSlug=${route.params.slug}`)
            professional.value = response.data.data
            console.log(response, 'response prof')
            console.log(professional.value, 'professional value')
            fetchGaleries()
        }
        catch (error) {
            console.log(error)
        }
        
    }

    onBeforeMount(() => {
        fetchData()
        
    })

    const fetchGaleries = async () => {
    //console.log(professional.value)
    if(professional.value.id){
        try {
            const result = await axios.get(`${url}/api/public/professionals/profile/gallery?professionalId=${professional.value.id}`)
            console.log(result, 'result galerie')
            galeries.value = result.data.data
            console.log(galeries.value, 'galerie value')
        }
        catch (error) {
            console.log(error)
        }
    }
}

    

    //Hago un post del usuario x
    /* watch(locale, async () => {
        const lang = JSON.parse(localStorage.getItem('lang'))
        try {
            await axios.get(url+urlApi+lang+`/`+slugRef.value, ).then((result) => {
                professional.value = result.data
                slugRef.value = result.data.slug
                router.push({name: 'Profesional', params: {slug: slugRef.value}})
            })
        }
        catch (error) {
            console.log(error)
        }
    }) */
    //Hago un post del usuario x
    /* const fetchData = async () => {
        const lang = JSON.parse(localStorage.getItem('lang'))
        try {
            await axios.get(url+'/api/professionals/'+lang+`/`+slug).then((result) => {
                professional.value = result.data
                slugRef.value = result.data.slug
                console.log(result)
            })

        }
        catch (error) {
            console.log(error)
        }
        
    }
    fetchData() */

    //Setea parametros de busqueda para el post
    /* const search = ref({
        queries:[
            {
                indexUid: 'product_products',
                q: '',
                filter:'class.es = curso'
            },
            {
                indexUid: 'product_products',
                q: '',
                filter: 'class.es = producto'
            },
        ]
    }) */
    /* const fetchSearch =  async () => {
        search.value.queries[0].q = slug
        search.value.queries[1].q = slug
        try {
            await axios.post(urlSearch, search.value).then((result) => {
                const resultData = (x) => { return result.data.results[x].hits }
                const resultLength = (x) => { return result.data.results[x].hits.length }
                console.log(result)
                courses.value = resultData(0)
                products.value = resultData(1)
                resultCoursesLenght.value = resultLength(0)
                resultProductsLenght.value = resultLength(1)
            })
        }
        catch (error) {
        console.log(error)
        }
    }
    fetchSearch() */
</script>

<style lang="scss" scoped>
#app, body, .container-fluid{
    background-color: #FFFFFF !important;
}




</style>