<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start">
        <NavbarCertificados :seccion="$t('message.coursesPanel')"/>
        <div  class="col ms-lg-3 mt-3 d-flex flex-column justify-content-start align-items-center">
            <div class="row justify-content-center justify-content-lg-start row__width">
                <div class="col-11 col-lg d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 caja  contenedor">
                    <div class="row justify-content-center row__width">
                        <!--- CURSOS --->
                        <Loader :loading="loading" v-if="loading"/>
                        <CursosPanel v-else/>
                        <!-- PRODUCTOS -->
                        <!-- <ProductosPanel v-if="!courseSheet && products"/> -->
                    </div>
                    <PanelSeccionPanel/>
                </div>
            </div>
        </div>
        <FooterCertificados :products="true" class="d-lg-none"/>
        <div class="espacio2"></div>
        <div class="container-loader" v-if="loader">
            <div class="custom-loader"></div>
        </div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import NavLateralPanel from "../../../components/Navs/NavLateralPanel.vue";
import PanelSeccionPanel from "../../../components/PanelSeccion/PanelSeccionPanel.vue";
import ProductosPanel from "../../../components/Panel/Cursos/Productos/ProductosPanel.vue";
import CursosPanel from "../../../components/Panel/Cursos/Cursos/PanelConCursos.vue";
import Loader from "../../../components/Filtros/Loader.vue"

// Importo mi método
import { storeToRefs } from "pinia"
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import { onBeforeMount, ref, provide, watch, nextTick  } from "vue"
import axios from 'axios';

// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const { access, idUser, professionalData } = storeToRefs(users);
const { url, validate } = users;

const isSidebarOpen = ref(true)
const products = ref()
const courseSheet = ref(false)
const courses = ref([])
const loading = ref(false)
let loader = ref(true)
let tasks = ref()
let fp = ref()
let actualTask = ref()

const algo = onBeforeMount( () => {
    access.value = [1, 2, 3, 4, 5]
    //console.log(access.value) 
    validate();
}) 

provide('isSidebarOpen', isSidebarOpen)
provide('courseSheet', courseSheet)

provide('loader', loader)

provide('courses', courses)
provide('products', products)
provide('tasks', tasks)
provide('fp', fp)
provide('actualTask', actualTask)

const actual_page = ref(0)

const changePage = (page) => {
    actual_page.value = page
}

provide('actual_page', actual_page)
provide('changePage', changePage)

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

// PRODUCTOS DEL USUARIO
const fetchAdquiredProducts = async() => {
    loader.value = true
    let lang = 'ES'
    let idData = JSON.parse(localStorage.getItem('idData'))
    console.log(idData)
    let id = idData.id
    if(id){
        const token = JSON.parse(localStorage.getItem('token'))
        const headers = ref({'Authorization': `Bearer ${token}`})
        try {
            loading.value = true
            await axios.get(url+'/api/student/get/products/'+id, {headers: headers.value}).then(result => {
                console.log(result)
                products.value = result.data
                products.value = result.data.map((product) => {
                    // Encontrar la primera presentación que contenga una imagen
                    const firstImagePresentation = product.presentations.find(presentation => presentation.element === 'image');
                    // Si se encontró una presentación con imagen, agregar la propiedad image al curso con el valor de la fuente de la imagen
                    if (firstImagePresentation) {
                        product.image = firstImagePresentation.source;
                    }
                    return product;
                })
                console.log(products.value)
            })
            setTimeout(() => {
                loader.value = false
                loading.value = false
            }, 1000)
        } catch (error) {
            console.log(error)
            setTimeout(() => {
                loader.value = false
                loading.value = false
            }, 1000)
        }
    }else{
        router.push('/')
    }
    
}
fetchAdquiredProducts()


const fetchAdquiredCourses = async() => {
    loader.value = true
    let uId = idUser.value.id
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        loading.value = true
        await axios.get(url+'/api/student/get/courses/'+uId, {headers: headers.value}).then(result => {
            console.log(result)
            if (result.data.length > 0) {
                courses.value = result.data
                courses.value = result.data.map((course) => {
                    const firstImagePresentation = course.presentation.find(item => item.element === 'image');
                    if (firstImagePresentation) {
                        course.image = firstImagePresentation.source
                    }
                    return course;
                })
                console.log(courses.value)
            }
        })
        setTimeout(() => {
            loader.value = false
            loading.value = false
        }, 1000)
    } catch (error) {
        console.log(error)
        setTimeout(() => {
            loader.value = false
            loading.value = false
        }, 1000)
    }
}
fetchAdquiredCourses()

const fetchTasksStudent = async(courseId) => {
    let studentId = idUser.value.id
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.get(url+'/api/student/get/course/tasks/'+studentId+'/'+courseId, {headers: headers.value}).then(result => {
            console.log(result)
            tasks.value = result.data
        })
    } catch (error) {
        console.log(error)
    }
}

provide('fetchTasksStudent', fetchTasksStudent)

const fetchFPStudent = async(courseId) => {
    let studentId = idUser.value.id
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.get(url+'/api/student/get/course/fp/'+courseId+'/'+studentId, {headers: headers.value}).then(result => {
            console.log(result)
            fp.value = result.data
        })
    } catch (error) {
        console.log(error)
    }
}

provide('fetchFPStudent', fetchFPStudent)

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
}
.contenedor{
    @media (min-width: 992px) {
        max-width: 95vw;
        margin-top: 50px;
    }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}
.classSpecial{
    width: 70vw !important;
}
.tablas{
    h6{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
        padding: 2vh 0 6vh 0;
    }
}
.sheet{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 28px;
    padding: 25px;
    animation: 0.3s fadein ease;
    h2{
        font-size: 20px;
        font-weight: 600;
        color: var(--colorSecondary);
        margin-top: 20px;
    }
    .scroll-2{
        max-height: 80vh;
        overflow-y: scroll !important;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
            background-color: #29292b1e;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #29292b1e;
        }
    }
    .slide{
        background-color: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        min-height: auto;
        padding: 10px 0;
        margin-bottom: 20px;
        .see-more{
            font-size: 14px;
            color: var(--colorPrimary);
            font-weight: 500;
            text-decoration: underline;
        }
        .border-left{
            border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
        }
        h3{
            color: #0E1133;
            font-weight: 700;
            font-size: 16px;
            text-align: left;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        h4{
            font-weight: 400;
            font-size: 16px;
            color: #B8C1DB;
            text-align: left;
            padding-top: 3px;
            max-height: 60px;
            max-width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            
        }
        h5{
            color: #0E1133;
            font-weight: 600;
            font-size: 22px;
            text-align: left;
            padding: 10px 15px;
            text-align: left;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            word-break: break-all;
        }
        .acciones{
            color: #B8C1DB;
            font-size: 14px;
            font-weight: 500;
        }
        .dropdown-toggle{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 13px;
            text-decoration: underline;
            padding: 0;
            img{
                margin-left: 5px;
            }
            &::after{
                display: none;
            }
            @media (min-width: 425px) {
                font-size: 16px;
            }
        }
        .caption{
            position: relative;
            height:83px ;
            img{
                border-radius: 10px;
                width: 100%;
                height:83px ;
                object-fit: cover;
            }
            &::before{
                content: '';
                position: absolute;
                bottom: 0;
                width: 108%;
                max-width: 100%;
                height: 100%;
                background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
                filter: opacity(1);
                border-radius: 0px 0px 10px 10px;
                z-index: 1;
            }
        }
    }
    .slide2{
        background-color: #FFFFFF;
        box-shadow: none;
        border-radius: 0;
        min-height: auto;
        padding: 0;
        h3{
            font-size: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
        }
    }
    .slide3{
        background-color: #ebebeb29;
        box-shadow: 0px 3px 10px #0000002d;
    }
    .slide4{
        margin: 20px 0;
        background-color: #f5f5f5;
        h6{
            padding-top: 5px;
            padding-bottom: 10px;
            font-weight: 600;
            font-size: 16px;
            text-align: left;
        }
        h4{
            padding-top: 0;
            font-weight: 500;
            text-align: left;
        }
    }
    .slide5{
        background-color: #F5F5F5;
        box-shadow: 0px 3px 10px #0000002d;
        padding: 25px;
        h4{
            color: #697891;
            text-align: left;
        }
        .profile{
            margin: 20px 0;
            img{
                max-height: 50px;
                width: 50px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        .evaluation{
            img{
                width: 100%;
                height: 400px;
                border-radius: 20px;
                object-fit: cover;
            }
        }
    }
    .mirar__ahora{
        font-size: 14px;
        width: 90%;
        height: 40px;
        border-radius: 20px;
        border: 2px solid var(--colorSecondary);
    }
    .tipo__reunion{
        label{
            height: 30px;
            width: 100%;
            box-shadow: 0px 0px 6px #00000029;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            background-color: #EBEEF9;
            &::before{
                content: '';
                position: absolute;
                height: 100%;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #38E6A7;
                border-radius:  28px;
                left: 0;
                transition: all 0.5s;
            }
            p{
                font-weight: 500;
                font-size: 14px;
                color: var(--bgPrincipal);
                z-index: 1;
                transition: all 0.5s;
                &:first-child{
                    margin-right: 0%;
                }
                &:last-child{
                    margin-left: 0%;
                }
            }
            .left{
                color: var(--bgPrincipal);
                font-weight: 600;
            }
            .right{
                color: var(--colorSecondary);
            }
        }
        input{
            display: none;
        }
        input:checked + label{
            &::before{
                left: 49%;
                width: 50%;
                background-color: #FF576E;
            }
            p{
                color: var(--bgPrincipal);
                font-weight: 600;
                
            }
            .left{
                color: var(--colorSecondary);
                font-weight: 500;
            }
        }
        @media (min-width: 992px) {
            label{
                .right{
                    color: var(--colorSecondary);
                }
            }
            input:checked + label{
                .left{
                    color: var(--colorSecondary);
                }
            }
        }
    }
    .description{
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            padding: 7px 0px;
        }
        p{
            color: var(--colorPrimary);
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            padding: 7px 0px;
        }
        .width{
            width: -webkit-fill-available;
        }
        textarea{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: #FCFCFC;
            height: 20vh;
            padding-top: 50px;
            padding-left: 15px;
            outline: 0;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                
                color: #697891;
            }
            
        }
        span{
            background-color: #E4E4E4;
            height: 40px;
            width: 99.6%;
            position: absolute;
            top: 2px;
            border-radius: 10px 10px 0 0 ;
            padding: 10px 15px;
            h6{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 600;
                text-align: left;
            }
        }
    }
}

.tablas{
    h6{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
        padding: 2vh 0 6vh 0;
    }
    .nav-link{
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        position: relative;
        padding: 10px 0;
        border: 0;
    }
    .nav-link.active{
        font-weight: 700;
        border: 0;
        color: var(--colorSecondary);
        background-color: transparent;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 0;
            left: 0;
            background-color: var(--colorPrimary);
            
        }
        @media (min-width: 992px) {
            &::before{
                height: 4px;
            }   
        }
    }
    .scroll{
        overflow-x: scroll;
        max-width: 100%;
        min-width: 100%;
        ul{
            min-width: 100%;
            padding: 0 20px;
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
    }
}

@keyframes fadein {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
.flex-cambio{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; 
    }
}
.container-loader{
    background-color: var(--bgPrincipal);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 1045;
}
.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#1679FC 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#1679FC);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
}

@keyframes s3{ 
  100%{transform: rotate(1turn)}
}
</style>