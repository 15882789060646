
<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-lg-3" v-if="modality == 1 || modality == 4">
      <h3>Fechas</h3>
    </div>
    <div
      class="col-12 d-flex justify-content-start align-items-start"
      v-if="modality == 1 || modality == 4"
    > 
      <div class="row row__width">
        <div v-for="(schedule, index) in information.schedules" :key="index" class="mb-3 col-12 col-md-6 col-lg-3">
            <MazPicker
              v-model="information.schedules[index]"
              format="YYYY-MM-DD HH:mm"
              label="Selecciona una fecha y una hora"
              color="secondary"
              time
              class="ms-2"
            />
          </div>
      </div>
    </div>
    <div class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-start" v-if="modality == 1 || modality == 4">
      <button type="button" @click="addNewDatePicker" class="btn btn__primario mt-2">
        Agregar otra fecha
      </button>
    </div>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start" v-if="modality == 1 || modality == 4">
      <div class="mt-3">
        <h3>Fechas seleccionadas:</h3>
        <ul class="p-0">
          <li
            v-for="(date, index) in information.schedules"
            :key="index"
            class="mb-1 d-flex justify-content-center align-items-center"
          >
            <p v-if="date">{{ date }}</p>
            <button
              type="button"
              @click="removeDatePicker(index)"
              class="btn btn-danger btn-sm ms-2"
              v-if="date"
            >
              Eliminar
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12">
      <div class="row justify-content-start align-items-center error d-none" id="schedules-empty">
            <p>Por favor, seleccione por lo menos una fecha</p>
        </div>
    </div>
  </template>

<script setup>
import { inject } from "vue";
import MazPicker from "maz-ui/components/MazPicker";
import dayjs from "dayjs";

const information = inject("information");
const modality = inject("modality");

// Asegurar que schedules sea un array válido al cargar el componente
if (!information.value.schedules || information.value.schedules.length === 0) {
    information.value.schedules = [null]; // Usa null para evitar errores en MazPicker
}

// Agregar nueva fecha como `null` para que MazPicker lo maneje correctamente
const addNewDatePicker = () => {
    information.value.schedules.push('');
};

// Eliminar fecha
const removeDatePicker = (index) => {
    information.value.schedules.splice(index, 1);
};

// Obtener las fechas en formato deseado para enviar a la API
const getFormattedSchedules = () => {
    return information.value.schedules
        .filter(schedule => schedule) // Filtrar nulos o vacíos
        .map(schedule => dayjs(schedule, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD"));
};
</script>





<style lang="scss" scoped>
.inputs {
margin: 10px 0px !important;
}
h3 {
color: var(--colorSecondary);
font-size: 16px;
font-weight: 500;
text-align: left;
padding: 7px 0px;
}
input {
width: 100%;
border: 2px solid #E9E3E3;
border-radius: 10px;
background-color: #FCFCFC;
height: 50px;
padding-left: 15px;
outline: 0;
color: #697891;
font-size: 15px;
font-weight: 400;
&:focus,
&:hover {
    border: 2px solid #1679FC;
}
&::placeholder {
    color: #697891;
}
}
.valido {
background-image: url('../../../img/panel-icons/input-valido.svg');
background-repeat: no-repeat;
background-position: 98% center;
}
.error {
padding: 10px 0;
p {
    color: #FF6A6A;
    font-size: 16px;
    font-weight: 400;
}
}
</style>
  