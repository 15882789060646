<template>
    <div class="row justify-content-center my-3 row__width">
        <div class="col-12 d-flex flex-column justify-content-start align-items-center slide px-0">
            <router-link :to="{name: 'Descargable', params: {slug: slug}}" class="col-12 d-flex justify-content-center align-items-center px-3 my-2 position-relative">
                <img :src="image" alt="" class="ejemplo">
                <!-- <div class="row justify-content-center row__width position">
                    <div class="col-12 d-flex justify-content-center align-items-center px-1" style="padding: 0 0;">
                        <div class="row justify-content-center row__width row__slide">
                            <div class="col-12 d-flex justify-content-between align-items-start arriba">
                                <img src="../../img/home-icons/cursos-icons/verificado.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div> -->
            </router-link>
            <router-link :to="{name: 'Profesional', params: {slug: owner?.slug}}" class="row justify-content-center descripcion row__width" v-if="owner.slug">
                <div class="col-12 d-flex justify-content-start align-items-center creador">
                    <img :src="avatar" alt="" class="ejemplo">
                    <div class="d-flex flex-column justify-content-center align-items-start ps-2">
                        <h3>{{$t('message.creator')}}</h3>
                        <h4>{{ owner.name }}</h4>
                    </div>
                </div>
            </router-link>
            <router-link :to="{name: 'Descargable', params: {slug: slug}}" class="row justify-content-center row__width">
                <div class="col-12 d-flex flex-column justify-content-center align-items-start descripcion">
                    <h2>{{ name }}</h2>
                    <!-- <h5>En este manual te eneñaremos más de 300 recetas de repostería!</h5> -->
                    <span class="border-bottom w-100"></span>
                </div>
            </router-link>
            <router-link :to="{name: 'Descargable', params: {slug: slug}}" class="row justify-content-center row__width">
                <div class="col d-flex flex-column justify-content-center align-items-start padding pe-0 comprar my-3" v-if="typeof prices == 'object' || prices.length > 0">
                    <h2>MXN ${{ prices.MXN[0].finalPrice }}</h2>
                    <h5 v-if="prices.MXN[0].discount">MXN ${{ prices.MXN[0].totalPrice }}</h5>
                </div>
                <div class="col12 d-flex justify-content-center align-items-center mt-2  comprar">
                    <router-link :to="{name: 'Descargable', params: {slug: slug}}" class="btn__primario btn-width">
                            {{$t('message.buy')}}
                    </router-link>
                </div>
            </router-link>
        </div>
    </div>

</template>

<script setup>
import BTNPrimario from "../Botones/PrimarioRutas.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import { useGeneralStore } from "../../stores/general"
import { storeToRefs } from "pinia";
import { ref, watch } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';

const general = useGeneralStore();
const {textos, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const {locale} = useI18n({ useScope: 'global' })
const lang = ref('ES')
const X = ref('')

const props = defineProps({
        downloadable: '',
    })

//Setea las variables con los resultados para su uso
let id = props.downloadable.id
let name = props.downloadable.names?.translations[lang.value]?.name
let slug = props.downloadable.slugs?.translations[lang]?.slug || props.downloadable.dinamic_slug
let owner = props.downloadable.owner
let avatar = props.downloadable.owner?.avatar
let image = props.downloadable.image
let modality = props.downloadable.type?.translations[lang.value]?.name
let details = props.downloadable.details
let prices = props.downloadable.prices
let verified = props.downloadable.verified
let created_at = props.downloadable.created_at
let collabs = props.downloadable.collabs
let reviews = props.downloadable.reviews
let marketplace = props.downloadable.marketplaces[0]
let membership = props.downloadable.membership
let students = props.downloadable.students

</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 0px;
    margin: 0px 0;
}
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    height: 415px;
    max-height: 415px;
    .ejemplo{
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
        height: 150px;
    }
    .position{
        position: absolute;
        top: 0;
        min-height: 100%;
        width: 100%;
        z-index: 3;
        h6{
            font-family: 'Proxima Nova';
            font-weight: 500;
            font-size: 17px;
            color: #FEB230;
        }
        p{  font-family: 'Proxima Nova';
            font-weight: 500;
            font-size: 17px;
            color: var(--bgPrincipal);
            padding-left: 3px;
        }
        .row__slide{
            position: relative;
            min-height: 100% !important;
            min-height: 250px;
            width: 100%;
            .arriba{
                position: absolute;
                top: 10%;
            }
            .abajo{
                position: absolute;
                bottom: 10%;
            }
        }
        
    }
    .filtro{
        &::before{
            content: '';
            position: absolute;
            bottom: 10px;
            width: 96%;
            height: 40%;
            border-radius: 0px 0px 15px 15px;
            background: linear-gradient(to top,  #081026 , #252E4800);
            filter: opacity(1);
            z-index: 2;
        }
    }
    .descripcion{
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-align: left;
            min-height: 53px;
        }
        h3{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                margin-left: 5px;
            }
        }
        h5{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            padding-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.2;
            text-align: left;

        }
        p{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .creador{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
            padding-bottom: 10px;
            .ejemplo{
                height: 50px;
                width: 50px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        .border-bottom{
            width: 90%;
            height: 1px;
            background-color: rgba(184, 193, 219, 0.3);
        }
    }
    .comprar{
        margin-bottom: 5px;
        margin-top: 10px;
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            @media (max-width: 992px) {
                font-size: 16px;
            }
        }
        h5{
            font-size: 14px;
            font-weight: 400;
            color: #B8C1DB;
            text-decoration: line-through;
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }
        .padding{
            padding-left: 10px;
        }
    }
}
</style>