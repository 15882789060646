import { defineStore } from 'pinia'
import { ref } from 'vue'
import  axios from 'axios'
import { useRoute } from 'vue-router';

export const useVideoStore = defineStore('video', () => {
    const url = process.env.VUE_APP_BASE_URL

    const API_KEY = 'AIzaSyCN79R3hGl4rW5wB_2gKAuQy8crEO1HV_k'; // es de prueba luego cambiar por una del equipo MC
    const videoInfo = ref(null);
    const videosDurations = ref([])
    const totalTimeVideo = ref()

    const isYoutubeUrl = (url) => /youtube\.com|youtu\.be/.test(url);
    const isVimeoUrl = (url) => /vimeo\.com/.test(url);

    
    // Verificar la plataforma del vídeo
    const verifiedPlataform = async(url) =>{

        if(isYoutubeUrl(url)){
            // declaro mis variables
            let params = {
                videoType: '',
                videoID: '',
                time: {}
            }
            //Averiguro el id del vídeo
            const videoIdRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/;
            const match = url.match(videoIdRegex);
            if (match) {
                //console.log(match[1])
                try {
                    let hours = 0;
                    let minutes = 0;
                    let seconds = 0;
                    //Consulto a la api para saber la información del vídeo
                    const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos?id=${match[1]}&part=snippet,contentDetails&key=${API_KEY}`);
                    console.log(response)
                    videoInfo.value = response.data.items[0];
                    //console.log(videoInfo.value)
                    //Tomo la duración del vídeo
                    let duration = videoInfo.value.contentDetails.duration
                    const matchDuration = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
                    if (matchDuration) {
                        /* hours = matchDuration[1] ? parseInt(matchDuration[1]) : 0;
                        minutes = matchDuration[2] ? parseInt(matchDuration[2]) : 0;
                        seconds = matchDuration[3] ? parseInt(matchDuration[3]) : 0; */
                        params.videoType = 1
                        params.videoID = match[1]
                        // params.time = {hours, minutes, seconds}
                        params.youtube = response
                        //La pusheo a mi array de vídeos totales
                        // videosDurations.value.push(params.time)
                        //console.log(videosDurations.value)
                        //Sumo todas las duraciones
                        //console.log(videoInfo.value)
                        return params
                    }
                    else {
                        return params
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        } else if (isVimeoUrl(url)) {
            let params = {
                videoType: '',
                videoID: '',
                time: {}
            }
        
            const regex = new RegExp('(?:https?://)?(?:www\\.)?(?:player\\.)?vimeo\\.com\\/(?:video\\/)?(\\d+)')
            let match = url.match(regex)
            let endpoint = 'https://vimeo.com/api/oembed.json';
            let oEmbedUrl = `${endpoint}?url=${url}`;
        
            try {
                // Usa async/await para esperar a que se resuelva la petición antes de continuar
                const response = await fetch(oEmbedUrl);
                const data = await response.json();
        
                /* let durationInSeconds = data.duration;
                let hours = Math.floor(durationInSeconds / 3600);
                let minutes = Math.floor((durationInSeconds - (hours * 3600)) / 60);
                let seconds = durationInSeconds - (hours * 3600) - (minutes * 60); */
        
                // videosDurations.value.push({hours, minutes, seconds})
        
                params.videoType = 0
                params.videoID = match[1]
                // params.time = {hours, minutes, seconds}
                params.vimeo = data
            } catch (error) {
                console.error('Error fetching Vimeo data', error);
            }
            return params;
            /* if (params.videoID && params.time && Object.keys(params.time).length > 0) {
                return params;
            } else {
                console.error('params no ha obtenido contenido válido');
                // Aquí puedes decidir si retornar un valor por defecto, lanzar un error, o hacer algo más.
                // Por ejemplo, podrías retornar null o undefined para indicar que no se pudo obtener un valor válido.
                return null; 
            } */
        }
    }

    const getTotalDuration = (durations) => {
        totalTimeVideo.value = ''
        console.log(durations)
        const totalDurationInSeconds = durations.reduce(
            (acc, curr) => acc + curr.hours * 3600 + curr.minutes * 60 + curr.seconds,
            0
        );
        const hours = Math.floor(totalDurationInSeconds / 3600);
        const minutes = Math.floor((totalDurationInSeconds - (hours * 3600)) / 60);
        const seconds = totalDurationInSeconds - (hours * 3600) - (minutes * 60);

        totalTimeVideo.value = [hours, minutes, seconds]
        //console.log(totalTimeVideo.value)
    }

    const getTotalDurationUnit = (durations) => {
        // console.log(durations)
        const totalDurationInSeconds = durations.reduce(
            (acc, curr) => acc + curr.hours * 3600 + curr.minutes * 60 + curr.seconds,
            0
        );
        const hours = Math.floor(totalDurationInSeconds / 3600);
        const minutes = Math.floor((totalDurationInSeconds - (hours * 3600)) / 60);
        const seconds = totalDurationInSeconds - (hours * 3600) - (minutes * 60);

        //totalTimeVideo.value = [hours, minutes, seconds]
        //console.log(totalTimeVideo.value)
    }

    return{
        videosDurations, totalTimeVideo,

        verifiedPlataform, verifiedPlataform, getTotalDuration

    }
})