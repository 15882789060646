<template>
    <div class="col-12 tab-pane fade show active" v-if="userAbout">
        <div class="row justify-content-center mt-3 row__width">
            <div class="col-12 d-flex justify-content-center align-items-center sobre_marca px-2">
                <div class="row justify-content-center  justify-content-lg-start row__width">
                    <div class="col-12 d-flex justify-content-start align-items-center">
                        <div class="row justify-content-start row__width">
                            <div class="col-12 col-lg-8 d-flex justify-content-start align-items-center px-0">
                                <h2>{{ $t('message.about') }} {{ userName }}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-start align-items-start px-1">
                        <p v-html="userAboutCorrected" v-if="description_is_html"></p>
                        <p v-else>{{ props.userAbout }}</p>
                    </div>
                    <div class="col-12 col-lg-2 px-0">
                        <button class="btn__primario" v-if="isTruncatable" @click="toggleText">
                        {{ isExpanded ? 'Leer menos' : 'Leer más' }}
                        </button>
                    </div>
                </div>
            </div>
            <!-- <Portafolio :gallery="gallery" :avatar="avatar"/> -->
            <!-- <Cursos  :courses="courses"/> -->
           
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted,} from 'vue';
import Portafolio from "./Secciones/Portafolio.vue";
import Cursos from "./Secciones/Cursos.vue";
import ServiciosV2 from "./Secciones/ServiciosV2.vue";

const props = defineProps({
    sobre: '',
    detalle: '',
    miembro: '',
    userName: '',
    userAbout: '',
    courses_uploaded: '',
    images_uploaded: '',
    courses: {
        type: Object
    },
    avatar: '',
    gallery:{
        type: Object
    }
})

const isExpanded = ref(false);

const isTruncatable = computed(() => {
})

const description_is_html = ref(false);

const userAboutCorrected = ref('');
    
    const fixLinks = (description) => {
        const div = document.createElement('div');
        div.innerHTML = description;

        const links = div.querySelectorAll('a');

        links.forEach(link => {
            const href = link.getAttribute('href');
            if (href && !href.startsWith('http://') && !href.startsWith('https://')) {
            link.setAttribute('href', 'https://' + href);
            }
        });

        return div.innerHTML;
    }

    const seeDescription = () => {
        userAboutCorrected.value = fixLinks(props.userAbout)
        if (fixLinks(props.userAbout)) {
            description_is_html.value = true
        } else {
            description_is_html.value = false
        }
    }

    onMounted(() => {
        seeDescription()
    })

</script>

<style lang="scss" scoped>
.sobre_marca{
    // background-color: #fff;
    // box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    padding: 15px 0px;
    h2{
        font-weight: 700;
        font-size: 25px;
        color: var(--colorSecondary);
        text-align: left;
        padding: 5px 0;
    }
    h3{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    h4{
        font-weight: 700;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
        padding-left: 5px;
        
    }
    h5{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
        padding-left: 5px;
        img{
            padding: 0 5px;
        }
        
    }
    a{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    .scroll__y{
        max-height: 10vh;
        overflow-y: scroll;
    }
    @media (min-width: 992px) {
        // padding: 20px 30px;
        .scroll__y{
            max-height: none;
        }
        h3{
            padding: 10px 0 10px 0;
        }
    }
}

.btn__primario{
    height: 42px;
}
</style>