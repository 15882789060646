<template>
    <div class="row justify-content-center justify-content-lg-start row__width" :style="filtrosMapa == true ? 'z-index: -1;' : 'z-index: 1;'">
        <swiper-container
            :navigation="{
                nextEl: '#nextSliderCursosboton',
                prevEl: '#prevSliderCursosboton'
            }"
            :pagination="{
                el: '#pagCursos',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :slides-per-view="3"
            :slides-per-group="3"
            :spaceBetween="20"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                992: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                1875: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            }"
            @swiper="onSwiper"
            class="mySwiper">
            <swiper-slide v-for="(product, id) in products_list" class="d-flex justify-content-center align-items-center" v-if="products_list.length > 0">
                <CursosFotografiaSlideV2    
                :product="product"/>
            </swiper-slide>
            <swiper-slide v-for="(product, id) in 4" v-else>
                <div class="skeleton-card slide">
                    <div class="skeleton-header">
                        <div class="skeleton-image"></div>
                    </div>
                    <div class="skeleton-body">
                        <div class="skeleton-title skeleton-loading"></div>
                        <div class="skeleton-subtitle skeleton-loading"></div>
                        <div class="skeleton-meta">
                            <div class="skeleton-icon skeleton-loading"></div>
                        </div>
                        <div class="skeleton-footer" style="margin-bottom: 16px;">
                            <div class="skeleton-price skeleton-loading"></div>
                            <div class="skeleton-price skeleton-loading"></div>
                            <div class="skeleton-price skeleton-loading"></div>
                            <div class="skeleton-price skeleton-loading"></div>
                        </div>
                        <div class="skeleton-footer">
                            <div class="skeleton-price skeleton-loading"></div>
                            <div class="skeleton-button skeleton-loading"></div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
    <Colaboradores/>
</template>

<script setup>
import Colaboradores from "../Modales/Colaboradores.vue";

import CursosFotografiaSlideV2 from "./CursosFotografiaV2.vue";
import { useGeneralStore } from "../../stores/general"
import { useConfigStore } from "../../stores/config"
import { useUsersStore } from "../../stores/user" 
import { storeToRefs } from "pinia";
import { ref, watch, nextTick, inject, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';

const general = useGeneralStore();
const {filtrosMapa, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const config = useConfigStore();
const { coinSelected } = storeToRefs(config);

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

const {locale} = useI18n({ useScope: 'global' })
const lang = ref()
const X = ref('')

const api_products = '/api/public/products/home'

let priceRange = inject('priceRange')
const professional = inject('professional')

const products_list = ref([])

const onSwiper = (swiper) => {
    console.log(swiper, 'swiper')
    swiper.pagination.render()
}

const fetchProducts = async () => {
    //console.log(professional.value)
    if(professional.value.id){
        try {
            const result = await axios.get(`${url}/api/public/professionals/profile/products?professionalId=${professional.value.id}&type=COURSE`)
            //console.log(result.data.data, 'result course')
            products_list.value = result.data.data
        }
        catch (error) {
            console.log(error)
        }
    }
}
fetchProducts()

</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 5px;
    margin: 10px 0;
}
swiper-slide {
  height: auto !important;
}
@media (max-width: 991px){
    .mySwiper3 swiper-slide{
        padding-bottom: 10px;
    }
    .mySwiper{
        margin: 0 0 20px 0;
    }
}

.skeleton-card {
    min-width: 100%;
    min-height: 465px;
    max-height: 70vh;
    padding: 16px;
    border-radius: 8px;
    background-color: white;
}

.skeleton-header {
    height: 240px;
    background-color: #e0e0e0;
    border-radius: 8px;
    margin-bottom: 16px;
}

.skeleton-image {
    width: 100%;
    height: 100%;
    background-color: #ccc;
    border-radius: 8px;
    animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-body {
    padding: 8px 0;
}

.skeleton-title, .skeleton-subtitle, .skeleton-icon, .skeleton-price, .skeleton-button {
    animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-title {
    width: 70%;
    height: 20px;
    background-color: #e0e0e0;
    margin-bottom: 10px;
    border-radius: 4px;
}

.skeleton-subtitle {
    width: 50%;
    height: 20px;
    background-color: #e0e0e0;
    margin-bottom: 10px;
    border-radius: 4px;
}

.skeleton-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.skeleton-icon {
    width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 50%;
}

.skeleton-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.skeleton-price {
    width: 50px;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.skeleton-button {
    width: 80px;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 8px;
}

@keyframes skeleton-loading {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}

</style>