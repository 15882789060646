<template>
    <Suspense>
        <template #default>
            <div class="row justify-content-center row__width ">
                <div class="col-12 d-flex justify-content-center align-items-start caja" :class="pagoSeguro == true ? 'arreglo' : ''">
                    <div class="row justify-content-center row__width tarjetas">
                        <div class="col-12 d-flex justify-content-start align-items-center px-0" :class="pagoSeguro == true ? 'justify-content-lg-between paddingColArreglo' : ''">
                            <h2 :class="pagoSeguro == true ? 'paddingArreglo ' : ''"> {{ $t('message.paymentMethod') }} </h2>
                            <div class="justify-content-start align-items-center pago__seguro" :class="pagoSeguro == true ? 'd-lg-flex ' : 'd-none'">
                                <img src="../../img/carrito-icons/pago-seguro.svg" alt="">
                                <h6>{{$t('message.paymentSecure')}}</h6>
                            </div>
                        </div>
                        <!-- <div class="col-12 d-flex justify-content-start align-items-center px-0">
                            <input type="radio" id="index3" value="" name="metodosPago" v-model="selectedMethod">
                            <label for="index3">
                                <div class="padding row justify-content-center row__width">
                                    <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                        <div class="span"></div>
                                        <h5>MasterCard **********4658</h5>
                                        <span class="position me-0"><img src="../../img/carrito-icons/mastercard.svg" alt="" class="grande"></span>
                                    </div>
                                </div>
                                <div class="row justify-content-center row__width">
                                    <div class="col-12 justify-content-center align-items-center px-0 informacion">
                                        <div class="row justify-content-center row__width inputs">
                                            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 mt-3 mb-3">
                                                <h4>Número de tarjeta</h4>
                                                <div class="row justify-content-center align-items-center row__width position-relative">
                                                    <img src="../../img/carrito-icons/tarjeta.svg" alt="">
                                                    <input type="text" placeholder="">
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 mb-3">
                                                <h4>Código CVV</h4>
                                                <input type="text" placeholder="">
                                            </div>
                                            <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 mb-3">
                                                <h4>Exp. fecha</h4>
                                                <input type="text" placeholder="">
                                            </div>
                                            <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 mb-3">
                                                <h4>{{ $t('message.fullname') }}</h4>
                                                <input type="text" placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div> -->
                        <div class="col-12 d-flex flex-column justify-content-start align-items-center px-0">
                            <button class="w-100" :class="selectedMethod == 1 ? 'active' : ''" @click=" selectedMethod = 1">
                                <div class="padding row justify-content-center row__width">
                                    <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                        <div class="span"></div>
                                        <h5> {{ $t('message.cards') }} </h5>
                                        <div class="d-flex justify-content-between align-items-center position">
                                            <span class="chiquitos"><img src="../../img/carrito-icons/visa.svg" alt=""></span>
                                            <span class="chiquitos"><img src="../../img/carrito-icons/mastercard.svg" alt="" ></span>
                                        </div>
                                    </div>
                                </div>
                                <form class="col-12 justify-content-center align-items-center px-0" v-show=" selectedMethod == 1">
                                    <div id="card_element">
                                        <div id="card_number_element"></div>
                                        <div id="card_expiry_element"></div>
                                        <div id="card_cvc_element"></div>
                                    </div>
                                </form>
                            </button>
                        </div>
                        <!-- <div class="col-12 d-flex justify-content-start align-items-center px-0">
                            <input type="radio" id="index6" value="5" name="metodosPago" v-model="selectedMethod">
                            <label for="index6">
                                <div class="padding row justify-content-center row__width">
                                    <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                        <div class="span"></div>
                                        <h5> {{ $t('message.mercadopago') }} </h5>
                                        <img src="../../img/carrito-icons/mercadopago.svg" alt="" class="mercadopago position">
                                    </div>
                                </div>
                                <div class="row justify-content-center row__width">
                                    <div class="col-12 justify-content-center align-items-center px-0 informacion">
                                        <div class="row row justify-content-center row__width ">
                                            <div class="col-12 d-flex flex-column justify-content-between align-items-center">
                                                <p>Antes de seguir con la compra, inicia sesión con tu cuenta de MercadoPago:</p>
                                                <PrimarioV2 textoBTNPrimarioV2="Pagar con MERCADOPAGO" class="my-3" @click="payMP()"/>
                                                <h6>¿Tienes alguna duda? Visita nuestra página de <a href="">Ayuda</a> o ponte en<a href="">contacto con nosotros</a>.</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div> -->
                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                            <button class="w-100" :class="selectedMethod == 2 ? 'active' : ''" @click="selectedMethod = 2">
                                <div class="padding row justify-content-center row__width">
                                    <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                        <div class="span"></div>
                                        <h5> {{ $t('message.paypal') }} </h5>
                                        <img src="../../img/carrito-icons/paypal.svg" alt="" class="paypal position">
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center px-0 select">
                            
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #fallback>
            <p>Cargando...</p>
        </template>
    </Suspense>
</template>

<script setup>
import PrimarioV2 from "../Botones/PrimarioV2.vue";
import { inject, onMounted, ref } from 'vue';
import { loadStripe } from '@stripe/stripe-js';

let selectedMethod = inject('selectedMethod')
let payment_intention = inject('payment_intention')
let payment_data = inject('payment_data')
let pay = inject('pay')

const props = defineProps({
    pagoSeguro: Boolean
})

let stripe_pk = process.env.VUE_APP_STRIPE_PK
let stripe = inject('stripe')
let elements = null
let card_element = inject('card_element')
let is_processing = ref(false);
let error_message = ref(null);

const style = {
    base: {
        color: '#000000',
        fontFamily: '"Roboto", "Helvetica Neue", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',  // Puedes ajustarlo si lo ves necesario
        '::placeholder': {
            color: '#aab7c4',
        },
        padding: '12px',  // Ajuste del padding para hacer el campo más amplio verticalmente
        backgroundColor: '#ffffff',  // Fondo blanco como en la imagen
        border: '1px solid #ced4da',  // Color de borde similar al que se ve en la imagen
        borderRadius: '6px',  // Bordes más redondeados
        marginBottom: '25px !important',  // Añadir espacio entre los campos
        boxShadow: 'none',  // Eliminar sombras
        height: '45px',  // Controlar la altura del campo
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        borderColor: '#e3342f',  // Bordes rojos si es inválido
    },
};

onMounted(async () => {
  try {
    // Cargar Stripe
    stripe.value = await loadStripe(stripe_pk);
    
    let elements = stripe.value.elements();

    // card_element.value = elements.create('card', { style });
    // card_element.value.mount('#card_element');

    // Crear el campo de número de tarjeta
    card_element.value = elements.create('cardNumber', { style });
    card_element.value.mount('#card_number_element');

    // Crear el campo de expiración
    let cardExpiry = elements.create('cardExpiry', { style });
    cardExpiry.mount('#card_expiry_element');

    // Crear el campo de CVC
    let cardCvc = elements.create('cardCvc', { style });
    cardCvc.mount('#card_cvc_element');

    // console.log(cardNumber)
    console.log(card_element.value)
    // card_element.value.cardNumber = cardNumber
    console.log(card_element.value)

  } catch (error) {
    console.error('Error al inicializar Stripe:', error);
    error_message.value = 'No se pudo cargar el formulario de pago.';
  }
});


</script>

<style lang="scss" scoped>
h2{
    font-weight: 600;
    font-size: 30px;
    color: var(--colorSecondary);
    padding: 10px 0 30px 0;
    @media (max-width: 425px) {
        font-size: 25px;
    }
}
.caja{
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 20px;
    padding-bottom: 30px;
    @media (min-width: 992px) {
        padding: 10px 40px 40px 40px;
        margin-top: 40px;
    }
    button{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin-bottom: 15px;
        background-color: #FCFCFC;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #E4E4E4;
        border-radius: 10px;
        min-height: 77px;
        .span{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #B8C1DB;
            width: 28px;
            height: 28px;
            left: 0;
            &::after{
                content: '';
                position: absolute;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                width: 14px;
                height: 14px;
                display: none;
            }
            @media (max-width: 425px) {
                width: 22px;
                height: 22px;
            }
        }
        .informacion{
            display: none;
            p{
                font-weight: 400;
                font-size: 14px;
                color: var(--colorSecondary);
                padding: 10px 0px;
            }
            h6, a{
                font-weight: 400;
                font-size: 14px;
                color: #697891;
            }
            a{
                padding: 0 2px ;
            }
        }
    }
    button.active .span{
        border-color: var(--colorPrimary);
        &::after{
            display: block;
        }
    }
    button.active .informacion{
        display: flex;
    }
    button.active{
        border: 2px solid var(--colorPrimary);
        border-radius: 20px;
        box-shadow: 0px 3px 6px #00000029;
    }
    h5{
        font-weight: 600;
        font-size: 14px;
        color: var(--colorSecondary);
        padding-left: 10px;
        @media (max-width: 425px) {
            font-size: 12px;
            padding-left: 5px;
        }
    }
    .position{
        position: absolute;
        right: 15px;
    }
    .inputs{
        h4{
            font-weight: 400;
            font-size: 16px;
            color: var(--colorSecondary); 
            padding: 5px 0;
            text-align: left;
            width: 100%;
        }
        input{
            border: 1px solid #E9E3E3;
            width: 100%;
            height: 50px;
            border-radius: 10px;
            padding-left: 15px;
            &:focus{
                outline: 0;
                border: 2px solid var(--colorPrimary);
            }
        }
        img{
            position: absolute;
            left: 2%;
            height: 20px;
            width: 50px;
            padding: 0;
        }
        img + input{
            padding-left: 17%;
        }
    }
    .padding{
        padding: 15px 0;
    }
    .select{
        h3{
            font-weight: 600;
            font-size: 22px;
            color: var(--colorSecondary); 
            padding: 20px 0;
            @media (min-width: 992px) {
                padding: 30px 0;
            }
        }
        h6{
            font-weight: 600;
            font-size: 16px;
            color: var(--colorSecondary); 
            padding: 0 0 10px 0;
            @media (min-width: 992px) {
                padding: 0 0 20px 0;
            }
        }
        .caja2{
            position: relative;
            select{
                border: 1px solid #E9E3E3;
                background-color: #FCFCFC;
                height: 50px;
                width: 100%;
                outline: 0;
                padding-left: 45px;
                -webkit-appearance: none !important;
                border-radius: 8px;
                
            }
            img{
                position: absolute;
                left: 25px;
                width: 20px;
                height: 20px;
            }
            &::before{
                content: '';
                position: absolute;
                right: 23px;
                background-image: url('../../img/carrito-icons/arrow-left-gris.svg');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                width: 20px;
                height: 5px;
            }
        }
    }
}
.arreglo{
    @media (min-width: 992px) {
        min-height: 73vh;
        margin-top: 0;
    }
}
.tarjetas{
    margin: 20px 0 5px 0;
    span{
      width: 50px;
      height: auto;
      min-height: 30px;
      box-shadow: 0px 3px 6px #00000029;
      background-color: var(--bgPrincipal);
      border-radius: 8px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      img{
        height: 13px;
      }
      .grande{
        height: 20px;
      }
    }
    .paypal{
        height: 25px;
    }
    .mercadopago{
        height: 90px;
    }
    .chiquitos{
       width: 40px;
       min-height: 0px !important;
       margin-right: 5px;
       padding: 5px;
       &:last-child{
        margin-right: 0;
       }
       img{
        height: 13px;
       }
    }
}
.volver{
    img{
        padding-right: 10px;
    }
    a{
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
.pago__seguro{
    h6{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        padding-left: 10px;
    }
    @media (max-width: 991px) {
        display: none !important;
    }
}
@media (min-width: 992px) {
    .paddingArreglo{
        padding: 0;
    }
    .paddingColArreglo{
        padding: 10px 0 30px 0;
    }
}

#card_number_element, #card_expiry_element, #card_cvc_element {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    background-color: #ffffff;
    width: 100%;  
    height: auto; 
    margin-bottom: 15px;  
}


</style>