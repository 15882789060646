<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center texto_mas" v-if="professional">
        <p class="max-width" v-html="userAboutCorrected" v-if="description_is_html"></p>
        <p v-else>{{ professional?.user?.personal_data?.about }}</p>
    </div>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
        <router-link :to="{name: 'Profesional', params: {slug: professional.slug}}" class="ver_perfil py-2" v-if="professional">
            {{ $t('message.viewProfile') }}
        </router-link>
    </div>
</template>

<script setup>
import BTNVerPrerfil from "../Botones/VerPrerfil.vue";
import { inject, onMounted, ref, watch } from 'vue';

let product = inject('product')
let professional = inject('professional')
const mostrarTexto4 = () => {
    document.getElementById('desaparecer4').classList.toggle('d-none')
    document.getElementById('filtro4').classList.toggle('before')
}

    const description_is_html = ref(false);

    const userAboutCorrected = ref('');
    
    const fixLinks = (description) => {
        const div = document.createElement('div');
        div.innerHTML = description;

        const links = div.querySelectorAll('a');

        links.forEach(link => {
            const href = link.getAttribute('href');
            if (href && !href.startsWith('http://') && !href.startsWith('https://')) {
            link.setAttribute('href', 'https://' + href);
            }
        });

        return div.innerHTML;
    }

    const seeDescription = () => {
        userAboutCorrected.value = fixLinks(professional.value.user?.personal_data?.about)
        if (fixLinks(professional.value.user?.personal_data?.about)) {
            description_is_html.value = true
        } else {
            description_is_html.value = false
        }
    }

    onMounted(() => {
        watch(professional, () => {
            seeDescription()
        })
    })
    
</script>

<style lang="scss" scoped>
.texto_mas{
    p, li{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    ul{
        padding: 0;
        max-width: 100%;
        position: relative;
        li{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 30px;
            min-height: 40px;
            width: 100%;
            margin-bottom: 10px;
            &::before{
                content: url('../../img/home-icons/cursos-icons/tick.png');
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
            }
        }
    }
    .seccion{
        padding: 10px 10px;
        img{
            padding-right: 10px;
        }
    }
}
.ver_perfil{
    background-color: var(--bgPrincipal);
    border: 2px solid var(--colorPrimary);
    border-radius: 26px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: var(--colorPrimary);
    margin: 10px 0px;
}
.max-width{
    max-width: 80%;
    @media (max-width: 992px) {
        max-width: 100%;
    }
}
</style>