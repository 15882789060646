import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: 'ES',
      textos:{
        tituloRegistrarse: 'Registrate',
        subtituloRegistrarse: 'Regístrate para iniciar',
        registroMail: 'o regístrate con email',
        registrarseOtros:'o regístrate con',
        continuar: 'Continuar',
        iniciarFacebook: 'Iniciar con Facebook',
        iniciarGoogle: 'Iniciar con Google',
        continuarTelefono : 'Continuar con teléfono',
        yaTengoCuenta: 'Ya tengo cuenta',
        ingresar: 'Ingresar',
        volver: 'Volver',
        usuario: 'Usuario o email',
        inicio: 'INICIO',
        cursos: 'Cursos',
        categorias : 'Categorias',
        escuela: 'Escuela',
        concursos: 'Concursos',
        nosotros : 'Nosotros',
        contacto : 'Contacto',
        premium : 'Premium',
        modoNocturno: 'Modo nocturno',
        registrarseFacebook: 'Registrarse con Facebook',
        registrarseGoogle: 'Registrarse con Google',
        iniciarTelefono : 'Iniciar con teléfono',
        enlaceRegistro: 'o regístrate con email',
        acepto:'Acepto los',
        terminos: 'Términos, condiciones y Políticas',
        tituloInicio: 'Inicia sesión',
        subtituloInicio:'¡Bienvenido de vuelta!',
        password: 'Contraseña',
        recordar:'Recordarme',
        olvidar: 'Olvide mi contraseña',
        iniciar: 'Iniciar sesión',
        noTengocuenta: 'Aun no tengo cuenta',
        registrarme: 'Registrarme',
        roles: 'Selecciona tu rol',
        tuRol:'Para una mejor experiencia, crea una cuenta o inicia sesión como:',
        admin: 'Admin',
        profesor: 'Profesor',
        estudiante: 'Estudiante',
        usuarioInvitado: 'Usuario invitado',
        empezarBuscar: 'Empezar a buscar',
        tituloSplash: 'El mundo de la creatividad en un solo lugar',
        subtituloSplash: 'Encuentra cursos, fotógrafos, presets y mucho más',
        buscador: '¿Qué estas buscando?',
        placeholderBuscador: 'Cursos, eventos, concursos...',
        findYourCourse: 'Encuentra tu curso',
        findYourCoursePack: 'Encuentra tus pack de cursos',
        findYourDownloadable: 'Encuentra tu descargable',
        findYourProfessional: 'Encuentra tu profesional',
        serPremium: 'Ser Premium',
        unirseMasterclass: 'Únete a Masterclass',
        tituloCursos: 'Los mejores cursos de fotografía',
        subtituloCursos: 'Busca entre más de 200 cursos',
        todos:'Todos',
        ordenar: 'Ordenar por',
        accesoPremium:'Acceso Premium',
        uneteMasterclass: 'Únete a Masterclass Área Premium y accede a todos los beneficios',
        uneteMCV2:'Únete a Masterclass Área Premium y accede a  todos los beneficios en todos nuestros  los cursos',
        quieroPremium: 'Quiero ser Premium',
        buscarExperto:'Busca un experto',
        numCursosFooter:'10,000 +',
        masCursos:'Más cursos que puedes explorar',
        explorarCursos:'Explorar todos los cursos',
        encuentra: 'Encuentra cursos, creadores de contenido, fotógrafos, proveedores y mucho más',
        necesitasAyuda: '¿NECESITAS AYUDA?',
        direccion:'TeléPresa Oviachic 165, Col, Irrigación, 11500 CDMXfono',
        telefono:'(55) 64506593',
        planes: 'PLANES DE FORMACIÓN',
        legal: 'LEGAL',
        avisoPrivacidad: 'Aviso de privacidad',
        terminos:'Términos y condiciones',
        copy:'2022 Todos los derechos reservados',
        comprar: 'Comprar',
        online: 'Online',
        producido: 'Producido por:',
        mpc: 'MCP',
        rating: '3.0',
        opiniones: '(12)',
        tituloCurso:'Curso de Fotografía Newborn Profesional',
        creador: 'Creador',
        nombreCreador: 'Mónica Argas',
        colaboran: 'Colaboran',
        numColaboran: '+12',
        participantes: '872',
        presencial: 'Presencial',
        cursoHoras: '12 horas',
        cursosDia: '15 Abril',
        precioPromo: 'MX $6000',
        precio: 'MX $7000',
        total: 'Total',
        top: 'Top 4',
        ciudadCurso: 'Ciudad de méxico',
        desde: 'Desde',
        tituloRecuperar: 'Olvidé mi contraseña',
        subtituloRecuperar: 'Ingresá el email asociado a tu cuenta y te vamos a enviar un enlace para reestablecer tu contraseña',
        enviarEnlace: 'Enviar enlace',
        mail: 'Email',
        bienvenidoDeVuelta: 'Bienvenido de vuelta',
        noTengoCuenta: 'Aun no tengo cuenta',
        registrarme: 'Registrarme',
        continuarMail: 'Continuar con email',
        confirmarTelefono: 'Confirma tu número de teléfono',
        ingresarCodigo: 'Ingresá el código que enviamos por SMS al',
        numCodigo:'+1154455556',
        terminarRegistro: 'Termina tu registro',
        aceptarYContinuar: 'Aceptar y continuar',
        planFree:'PLAN FREE',
        nombreYApellido: 'Nombre y apellido',
        fechaNacimiento:'Fecha de nacimiento',
        fecha: 'dd/mm/aaaa',
        terminosV2:'Términos, condiciones y Políticas',
        marketplace: 'Ir al marketplace',
        tituloPackcursos: 'Pack de cursos',
        subtituloPackCursos: 'Arma tu pack',
        categoria: 'Categoría',
        tituloDirectorio: 'Directorio de fotógrafos',
        subtituloDirectorio: 'Inspírate en las fotos de nuestros expertos y contáctalos',
        zona: 'Zona',
        tituloProveedores: 'Proveedores oficiales',
        despliegueCarroPrecioPromo:' ARS $4,880.51',
        despliegueCarroPrecio: 'ARS $9,761.03',
        aprovechaDescuento: '¡Aprovecha nuestro descuento!',
        DTO50:'50% DTO.',
        comprarAhora: 'Comprar ahora',
        descripcionCurso: 'Descripción del curso',
        queIncluyeCurso: '¿Qué incluye el Curso?',
        fechasCursos: 'Fechas del curso:',
        contenidoCurso:'Contenido del curso',
        profesorCurso: 'Profesor del curso',
        beneficiosCurso: 'Beneficios del curso',
        portafolioEstudiantes: 'Portfolio de estudiantes',
        valoraciones: 'Valoraciones',
        opinionesCurso: 'Opiniones sobre este curso',
        preguntasFrecuentes: 'Preguntas frecuentes',
        verAntesYDespues: 'Ver el antes y el despues',
        sobrePonente: 'Sobre Ponente',
        cursosFotografía: 'Cursos de fotografía',
        cursosDescuento: 'Cursos con descuentos',
        explorarCategorias: 'Explora más categorías',
        encuentraMejoresFotografias: 'Encuentra las mejores tiendas de fotografía',
        topProfesores: 'Top Profesores',
        descargables: 'Descargables',
        asesoriasOnline: 'Asesorías Online',
        subtituloAsesorias: 'Fotografía, Producción Audiovisual, Marketing y Negocio',
        reservar: 'Reservar',
        tituloAsesoriasProfesionales:'Asesorías profesionales con líderes del rubro creativo',
        epson: 'Epson Latam',
        ciudadMX: 'Ciudad de méxico',
        equiposProveedores:' Equipos fotográficos, accesorios..',
        verPerfil: 'Ver perfil',
        conoceDescargables: 'Conoce los productos descargables',
        productosDescargables: 'Productos que se suben en formato descargable, que los estudiantes pueden comprar y adquirir descárgalos dentro de la plataforma.',
        presets: 'Presets',
        ebooks: 'Ebooks',
        manuales:'Manuales',
        productosCompranDescargables:'Productos que se compran y se obtienen en formato descargable',
        plataformaEstudiantes: 'Desde la plataforma los fotógrafos podrán subir un archivo descargable con la configuración de sus herramientas de edición (Photoshop, Lightroom) para ponerlos a la venta a los estudiantes.',
        interactuaPresets:'Interactua con un ejemplo de nuestros presets',
        nombreTopProfesores: 'Mónica Olivera',
        mexicoDF: 'México DF',
        tituloAsesorias: 'Asesorías profesionales con líderes del rubro creativo',
        tituloConsultorias:'Asesorías y Consultorías con profesionales de su área',
        subtituloConsultorias: '¿Buscas una asesoría o consultoría para mejorar tus habilidades, crecer tu modelo de negocio o mejorar como profesional? Toma una asesoría con experto de ',
        masterClassPhotographers:'Master Class Photographers',
        podrasConseguir: 'Podrás conseguir',
        conoceAsesorias: 'Conoce las asesorías que puedes obtener en Master Class',
        oneAndOne:'One & One',
        coachingCalls: 'Coaching Calls',
        oneAndOneSubtitulo:'Los One & One son reuniones individuales cortas mediante el cual podrás resolver algunas dudas con un profesional, actualmente contamos con reuniones de:',
        quieroAsesoramiento: 'Quiero asesoramiento',
        asesoriasSlideCreador:'Pablo Argas',
        tituloAsesoriasSlide:'Asesoría en marketing, negocio y alianzas comerciales.',
        subtituloAsesoriasSlide:'Experto en Marketing Digital, redes sociales, emprendimiento, negocios digitales y especialista en asesoría uno a uno en negocios en línea.',
        mostrarFiltros:'Mostrar Filtros',
        ocultarFiltros:'Ocultar Filtros',
        filtrar:'Filtrar',
        cancelar: 'Cancelar',
        cursosOnline:'Cursos Online',
        creaPack:'Crea un pack',
        categorias: 'Categorías',
        fotografiaNewborn:'Fotografía de Newborn.',
        deCiudadMX:'De Ciudad de México',
        nombreProfesor:'Salem McBunny [PROFESOR]',
        ubicacion:'Ubicación',
        ratingCurso:'Rating del curso',
        numRatingCurso:'4.9',
        testimonios:'Testimonios',
        mas12:'+12',
        tuOpinionImporta:'Tu opinión nos importa',
        mexico:'México',
        anadirCarrito: 'Añadir al carrito',
        aplicar:'Aplicar',
        codigoDescuento: 'Código de descuento',
        ofertaTerminaEn:'La oferta termina en:',
        tiempoDescuento:'22 : 14 : 34 : 00',
        dias:'días',
        horas:'horas',
        minutos:'min',
        segundos:'sec',
        informacionCurso:'Información del curso:',
        checkout:'Checkout',
        datosRevisar: 'Datos para revisar',
        clienteExistente: '¿Cliente existente?',
        ingreseCuenta: 'ingrese con su cuenta',
        ubicacion: 'Ubicación'
        
      },
      imgs:{
        fotografia: require('@/img/home-icons/fotografia.svg'),
      },
      checks: [
        {nombre: "Participantes", src:'perfil',},
        {nombre: "Preguntas", src: 'pregunta',},
        {nombre: "Temas", src:'temas'},
      ],
      colaboradoresViendo: [
        {titulo: "Colaboraciones", subtitulo: 'Enrique Alxarz, hace 2 días', comentarios:  '20k', foto:require('@/img/home-icons/fotografia.png'), activeLike: true },
        {titulo: "Foro de bienvenida", subtitulo: 'Enrique Alxarz, hace 2 días', comentarios:  '20k', foto:require('@/img/home-icons/fotografia.png'), activeDislike: true },
        {titulo: "David Ojeda", subtitulo: 'Enrique Alxarz, hace 2 días', comentarios:  '20k', foto:require('@/img/home-icons/fotografia.png'), activeDislike: true },
      ],
      descargas: [
        {titulo: "Acciones.psd", subtitulo: '1.6kb', foto: require('@/img/viendo-icons/psd-rojo.svg'), descargaBTN: true },
        {titulo: "Acciones.psd", subtitulo: '1.6kb', foto: require('@/img/viendo-icons/psd-azul.svg'), descargaBTN: false },
        {titulo: "Acciones.psd", subtitulo: '1.6kb', foto: require('@/img/viendo-icons/psd-rojo.svg'), descargaBTN: true },
        {titulo: "Acciones.psd", subtitulo: '1.6kb', foto: require('@/img/viendo-icons/psd-azul.svg'), descargaBTN: false },
      ],
      servicios: [
        {nombre: "Cursos de fotografía", src: require('@/img/home-icons/fotografia.png'), check: true},
        {nombre: "Clases Particulares", src: require('@/img/home-icons/clases-particulares.png'), check: false},
        {nombre: "Packs de cursos", src: require('@/img/home-icons/packs-cursos.png'), check: false},
        {nombre: "Workshops privados", src: require('@/img/home-icons/workshops.png'), check: false},
        {nombre: "Descarga Presets", src: require('@/img/home-icons/presets.png'), check: false},
      ],
      categorias: [
        {nombre: "Cursos", src: require('@/img/home-icons/fotografia.png')},
        {nombre: "Asesorias", src: require('@/img/home-icons/clases-particulares.png')},
        {nombre: "Presets", src: require('@/img/home-icons/packs-cursos.png')},
        {nombre: "Cursos", src: require('@/img/home-icons/fotografia.png')},
        {nombre: "Asesorias", src: require('@/img/home-icons/clases-particulares.png')},
        {nombre: "Presets", src: require('@/img/home-icons/packs-cursos.png'),},
      ],
      categoriasNuevo: [
        {nombre: "Cursos", src: require('@/img/home-icons/fotografia.png'), dLgFlex: false},
        {nombre: "Asesorias", src: require('@/img/home-icons/clases-particulares.png'), dLgFlex: false},
        {nombre: "Presets", src: require('@/img/home-icons/packs-cursos.png'), dLgFlex: false},
        {nombre: "Workshops privados", src: require('@/img/home-icons/workshops.png'), dLgFlex: true},
        {nombre: "Presets", src: require('@/img/home-icons/presets.png'), dLgFlex: true},
        {nombre: "Clases Particulares", src: require('@/img/home-icons/clases-particulares.png'), dLgFlex: true},
      ],
      opciones: [
        {titulo: "Mensaje de texto (SMS)", subtitulo: 'Te vamos a enviar un código', check: true},
        {titulo: "Whatsapp", subtitulo: 'Te vamos a enviar un código', check: false},
        {titulo: "Llamada", subtitulo: 'Te vamos a llamar para darte un código', check: false},
      ],
      inputs:{
        usuario: '',
        mail: '',
        password: '',
        fecha: '',
        error: false
      },
      condiciones:[
        {texto: 'Seguridad de la contraseña: poco segura'},
        {texto: 'No puede contener tu nombre o email'},
        {texto: 'Al menos 8 caracteres'},
        {texto: 'Contener un número o símbolo'},
      ],
      items:[
        {titulo: 'Fotografía Newborn Profesional', precioPromo:'MX $1.00', precio: 'MX $1.50'},
        {titulo: 'Fotografía Newborn Profesional', precioPromo:'MX $1.00', precio: 'MX $1.50'},
      ],
      tabHomecursos:[
        {titulo: 'Descripcion' , active: 1, displayM: true, src:require('@/img/home-icons/cursos-icons/descripcion.svg')},
        {titulo: 'Fechas' , active: 0, displayM: true, src:require('@/img/home-icons/cursos-icons/calendar-negro.svg') },
        {titulo: 'Contenido' , active: 0, displayM: true, src:require('@/img/home-icons/cursos-icons/contenido.svg') },
        {titulo: 'Ponente' , active: 0, displayM: false, src:require('@/img/home-icons/cursos-icons/ponente.svg') },
        {titulo: 'Beneficios' , active: 0, displayM: false, src:require('@/img/home-icons/cursos-icons/beneficios.svg') },
      ],
      tabHomeCursosResultados:[
        {titulo: 'Descripcion' , active: 1, displayM: true, src:require('@/img/home-icons/cursos-icons/descripcion.svg')},
        {titulo: 'Profesional' , active: 0, displayM: true, src:require('@/img/home-icons/cursos-icons/calendar-negro.svg') },
        {titulo: 'Valoraciones' , active: 0, displayM: true, src:require('@/img/home-icons/cursos-icons/contenido.svg') },
      ],
      categoriasResultadosCursos:[
        {titulo: 'Newborn', oculto: false},
        {titulo: 'Paisaje', oculto: false},
        {titulo: 'Boda', oculto: false},
        {titulo: 'Cumpleaños', oculto: true},
        {titulo: 'Fine art', oculto: true},
      ],
      cursos: [
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 0, tick: 1, paddingFirst : true},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 2, tick: 2, paddingFirst : false},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 1, tick: 2, paddingFirst : false},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 1, tick: 0, paddingFirst : false},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 1, tick: 1, paddingFirst : false},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 1, tick: 2, paddingFirst : false},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 1, tick: 1, paddingFirst : false},
      ],
      colaboradores:[
        {titulo: 'Mónica Duran', subtitulo: 'Fotografa profesional'},
        {titulo: 'Mónica Duran', subtitulo: 'Fotografa profesional'},
        {titulo: 'Mónica Duran', subtitulo: 'Fotografa profesional'},
        {titulo: 'Mónica Duran', subtitulo: 'Fotografa profesional'},
      ],
      perfil:{
        usuario: '',
        password: '',
        passwordNew: '',
        fecha: '',
        MCP: '',
        sexo: '',
        cuentaVefiricada: ''
      },
      filtrosDesplegados: false,
      nombreFiltros : false,
      mapaDesplegado : false,
      filtrosMapa: false,
      quiz: false
  },
  getters: {
  },
  mutations: {
    setValidacion(state){
      if(state.inputs.usuario != '', state.inputs.mail != '', state.inputs.password != '', state.inputs.fecha != ''){
        console.log('Es válido')
      }else{
        state.inputs.error = true
      }
    }
  },
  actions: {
    validacion({commit}) {
      commit('setValidacion')
    }
  },
  modules: {
  }
})
