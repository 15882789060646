<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center modulos" v-if="product.units || product.class.id == 1">
        <div class="accordion d-flex justify-content-center align-items-center" id="accordionExample" v-if="product.units[0]">
            <div class="row__width" :class="product.units.length > 4 ? 'before' : ''" id="filtro3">
                <template v-if="product.intro">
                    <div class="accordion-item row row__width justify-content-center">
                        <div class="accordion-header px-0" id="heading00" >
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse00" aria-expanded="false" :aria-controls="`collapse`">
                                <h3>{{ product.intro.es }}</h3>
                                <!-- <h4 v-if="product.intro.information"> {{ product.intro.information.time.minutes }} min</h4> -->
                            </button>
                        </div>
                        <div id="collapse00" class="accordion-collapse collapse" aria-labelledby="heading00" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                    <div class="row justify-content-center row__width play">
                                        <div class="col-1 d-flex justify-content-center align-items-center ">
                                            <i></i>
                                        </div>
                                        <div class="col flex-cambio">
                                            <h5 class="">{{ product.intro.es }}</h5>
                                            <!-- <h6 v-if="product.intro.information">{{ product.intro.information.time.minutes }}:{{ product.intro.information.time.seconds }}</h6> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-for="(item, index) in product.units">
                    <div class="accordion-item row row__width justify-content-center" v-if="item[0] && index <= 2 || showMore">
                        <div class="accordion-header px-0" id="headingTwo" >
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse`+index" aria-expanded="false" :aria-controls="`collapse`+index">
                                <h3>{{ item[0].title }}</h3>
                                <h4 v-if="item[0].totalTime">{{ item[0].lessons.length }} clases | {{ item[0].totalTime.minutes }} min</h4>
                            </button>
                        </div>
                        <div :id="`collapse`+index" class="accordion-collapse collapse" :aria-labelledby="`heading`+index" data-bs-parent="#accordionExample">
                            <div class="accordion-body" v-if="item[0].lessons">
                                <div class="col-12 d-flex justify-content-center align-items-center px-0" v-for="(lesson, id) in item[0].lessons">
                                    <div class="row justify-content-center row__width play">
                                        <div class="col-1 d-flex justify-content-center align-items-center">
                                            <i></i>
                                        </div>
                                        <div class="col flex-cambio">
                                            <h5 class="">{{ lesson.title }}</h5>
                                            <h6 v-if="lesson.information">{{ lesson.information.time.minutes }}:{{ lesson.information.time.seconds }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <BTNMostrarMas :funcionTexto="mostrarTexto3" :img="iconoImg" v-if="product.units.length > 4"/>
    </div>
</template>

<script setup>
import { inject, ref } from 'vue';
import BTNMostrarMas from "../Botones/MostrarMas.vue";
import modulos from "../Modulos/Modulos.vue";

const iconoImg = ref(false)
const showMore = ref(false)

let product = inject('product')

const startFrom = (array, index) => {
    console.log(array.slice(index))
    return array.slice(index)
}

const mostrarTexto3 = () => {
    showMore.value = !showMore.value
    document.getElementById('filtro3').classList.toggle('before')
    if(iconoImg.value == false){
        iconoImg.value = true
    }else{
        iconoImg.value = false
    }
}
</script>

<style lang="scss" scoped>
.modulos{
    .accordion{
        width: 100%;
        button{
            border-radius: 10px;
        }
    }
    .accordion-item{
        margin: 15px 0px;
        border: 0;
        border-radius: 10px;
    }
    .accordion-button::after{
        position: absolute;
        left: 5%;
        background-image: url('../../img/home-icons/cursos-icons/arrow-azul-abajo.svg');
        background-size: 70%;
        background-position: center;
    }
    .accordion-button{
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        background-color: rgba(247, 249, 250, 0.57);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        padding-left: 15%;
        @media (min-width: 992px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        &:focus{
            outline: 0;
            box-shadow: 0px 3px 6px #00000029;
            border-color: none;
        }
        &:not(.colapsed){
            background-color: inherit;
        }
    }
    h3{
        padding: 5px 0;
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #374557;
    }
    .accordion-body{
        padding: 10px 0;
        h5{
            padding: 5px 0px;
            font-weight: 400;
            font-size: 16px;
            color: var(--colorSecondary);
        }
        .active{
            color: var(--colorPrimary);
        }
        h6{
            color: #374557;
            font-weight: 400;
            font-size: 16px;
            padding: 0;
        }
        .img{
            max-width: 40px;
            padding: 0;
        }
        .flex-cambio{
            display: flex;
            justify-content: start;
            align-items: center;
            text-align: left;
            padding: 5px 0px;
            padding-left: 10px;
            @media (min-width: 992px) {
                    padding: 10px 0px;
                    padding-left: 10px;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
            }
        }
    }
    h6{
        font-weight: 400;
        font-size: 16px;
        color: #374557;
    }
    .play{
        i{
            background-image: url('../../img/home-icons/cursos-icons/play.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            min-height: 35px;
            min-width: 35px;
            max-height: 35px;
            max-width: 35px;
            display: block;
            transition: all ease 0.2s;
        }
        &:focus, &:hover{
            i{
                background-image: url('../../img/home-icons/cursos-icons/play-azul.svg');
            }
            h5, h6{
                color: var(--colorPrimary);
            }
        }
    }
}
</style>