<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
        <div class="col-12 d-flex justify-content-start align-items-center">
            <h3>{{ $t('message.tipeCurrency') }}</h3>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center">
            <select v-model="precification.coin">
                <option v-for="(item, index) in coins" :key="index" :value="item.id">
                    {{ item.abbr }}
                </option>
            </select>
        </div>
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../../stores/general"
import { inject, ref } from 'vue';
import { useUsersStore } from "../../../stores/user"
import { storeToRefs } from "pinia";
import axios from 'axios';

const users = useUsersStore();
const { access, idUser } = storeToRefs(users);
const { url, validate } = users;

const urlFetchCoin = '/api/payment/get/coin'

let precification = inject('precification')
const coins = ref([])

const fetchCoin = async() => {
    try {
        const result = await axios.get(url+urlFetchCoin);
        console.log(result, 'results')
        coins.value = result.data;
        const mxnCoin = coins.value.find(c => c.abbr === 'MXN');
        if (mxnCoin) {
            precification.value.coin = mxnCoin.id;
        }
    } catch (error) {
        console.log(error)
    }
}
fetchCoin()
</script>


<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
    @media (max-width: 991px) {
        font-size: 16px;
        font-weight: 600;
    }
}
input,select{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    -webkit-appearance: none;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
    @media (max-width: 991px) {
        width: 83%;
    }
}
span{
    background-color: #fff;
    border: 2px solid #E9E3E3;
    outline: 0;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    position: relative;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .bandera{
        width: 93%;
        height: 93%;
        object-fit: cover;
    }
    button{
        position: absolute;
        top: -10%;
        right: -5px;
        background-color: var(--bgPrincipal);
        border-radius: 50%;
        height: 25px;
        width: 25px;
        border: 0;
        padding: 0;
        .close{
           height: 100%;
           width: 100%;
           object-fit: cover;
        }
    }
}
.valido{
    background-image: url('../../../img/panel-icons/input-valido.svg');
    background-repeat: no-repeat;
    background-position: 98% center;
}
</style>