<template>
    <div class="row justify-content-center justify-content-lg-start row__width d-none d-lg-flex margin-top">
        <div class="col-10 d-none d-lg-flex justify-content-center align-items-center">
            <div class="row justify-content-center row__width">
                <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                    <h1 :title="$t('message.titleHomeDesk')">{{ $t('message.titleHomeDesk') }}</h1>
                    <h2 :title="$t('message.subtitleHomeDesk')">{{ $t('message.subtitleHomeDesk') }}</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
h1{
    font-weight: 700;
    font-size: 45px;
    color: var(--colorSecondary);
    padding-bottom: 20px;
    text-align: left;
}
h2{
    font-weight: 600;
    font-size: 20px;
    color: var(--colorSecondary);
    text-align: left;
}
@media (min-width: 992px) {
    
    .margin-top{
        margin: 7% 0 3% 0;
    }
}
@media (min-width: 992px) {
    h1{
        padding: 6vw 0 1.5vw 0;
    }
}
@media (min-width: 1366px) {
    h1{
        padding: 7vw 0 1.5vw 0;
    }
}
@media (min-width: 1500px) {
    h1{
        padding: 5vw 0 1.5vw 0;
    }
}
</style>