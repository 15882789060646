<template>
    <form class="row justify-content-center align-items-center row__width contenedor">
        <Loader :loading="loading" :style="isSidebarOpen ? 'padding-left: 280px !important;'  : ''"/>
        <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0" v-if="!temporal">
            <div class="row justify-content-center row__width border-bottom padding h-100">
                <!-- <AtrasAdmin/> -->
                <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center mb-4">
                    <TituloPanelAdmin :title="$t('message.newUser')" v-if="state == 'c'" />
                    <TituloPanelAdmin :title="$t('message.editUser')" v-if="state == 'e'" />
                </div>
                <div class="col-12  d-flex flex-column justify-content-center align-items-center tablas">
                    <div class="scroll d-flex justify-content-start justify-content-xxl-center align-items-center">
                        <ul class="row row_scroll justify-content-start pa-users-tabs" id="myTab" role="tablist">
                            <button type="button" class="col d-flex justify-content-center align-items-center tab-element p-3"
                                @click="changePage(1)"
                                :class="actual_page == 1 ? 'active' : ''"
                            >
                                <p>{{ $t('message.account') }}</p>
                            </button>
                            <button type="button" class="col d-flex justify-content-center align-items-center tab-element p-3"
                                @click="changePage(2)"
                                :class="actual_page == 2 ? 'active' : ''"
                            >
                                <p>{{ $t('message.personal') }}</p>
                            </button>
                            <button  type="button" class="col d-flex justify-content-center align-items-center tab-element p-3"
                                @click="changePage(3)"
                                :class="actual_page == 3 ? 'active' : ''"
                                v-if="new_user.team.id == 4"
                            >
                                <p>{{ $t('message.professional') }}</p>
                            </button>
                            <button  type="button" class="col d-flex justify-content-center align-items-center tab-element p-3"
                                @click="changePage(4)"
                                :class="actual_page == 4 ? 'active' : ''"
                                v-if="(new_user.team.id == 4) && state == 'e'"
                            >
                                <p>{{ $t('message.portfolio') }}</p>
                            </button>
                            <button  type="button" class="col d-flex justify-content-center align-items-center tab-element p-3"
                                @click="changePage(5)"
                                :class="actual_page == 5 ? 'active' : ''"
                                v-if="(new_user.team.id == 4) && state == 'e'"
                            >
                                <p>{{ $t('message.reviews') }}</p>
                            </button>
                            <button  type="button" class="col d-flex justify-content-center align-items-center tab-element p-3"
                                @click="changePage(6)"
                                :class="actual_page == 6 ? 'active' : ''"
                                v-if="(new_user.team.id == 4) && state == 'e'"
                            >
                                <p>Ranking</p>
                            </button>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 1">
                <div class="col col-lg-11">
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors.password ? '' : 'pb-3'">
                            <h3>Email *</h3>
                            <input type="mail" placeholder="example@gmail.com" v-model="new_user.email">
                            <span v-if="errors.email">{{ errors.email }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start password mt-3 ps-lg-0" :class="errors.email ? '' : 'pb-3'">
                            <div class="d-flex justify-content-between w-100">
                                <h3 v-if="state == 'e'">{{ $t('message.password') }}</h3>
                                <h3 v-else>{{ $t('message.password') }} *</h3>
                                <p class="weak" v-if="new_user.password.length <= 5 && new_user.password.length > 0">Debil</p>
                                <p class="moderate" v-if="new_user.password.length >= 6 && new_user.password.length <= 8">Moderada</p>
                                <p class="strong" v-if="new_user.password.length >= 9">Fuerte</p>
                            </div>
                            <input type="password" id="editUserPassword" v-model="new_user.password">
                            <button class="btn_none d-flex justify-content-center align-items-center" @click="showPassword()" type="button"><i class="ojo"></i></button>
                            <span v-if="errors.password">{{ errors.password }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start ps-lg-0" :class="errors.plan.id ? '' : 'pb-3'">
                            <h3>Rol *</h3>
                            <div class="justify-content-center align-items-start row__width">
                                <div class="col d-flex justify-content-end align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.team.id"
                                        placeholder="Seleccionar rol"
                                        color="info"
                                        :options="teams_list"
                                        name="team"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div>
                            <span v-if="errors.team.id">{{ errors.team.id }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start ps-lg-0" :class="errors.team.id ? '' : 'pb-3'">
                            <h3>Tipo de plan *</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.plan.id"
                                        placeholder="Seleccionar rol"
                                        color="info"
                                        :options="plans_list"
                                        name="role"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div>
                            <span v-if="errors.plan.id">{{ errors.plan.id }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" v-if="state == 'e'">
                            <h3>ID</h3>
                            <input style="border-color: #E9E3E3 !important;" type="number" placeholder="Generado por el servidor." disabled v-model="new_user.id">
                        </div>
                    </div>
                    <div class="row justify-content-start switch my-3 my-lg-2" v-if="state == 'e'">
                        <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center ps-lg-0">
                            <div class="w-100 d-flex justify-content-start align-items-center">
                                <h3>Estado</h3>
                            </div>
                            <input type="checkbox" id="pa-user-state" v-model="new_user.status" @change="changeUserStatus(new_user.status ? 'disable' : 'enable')">
                            <label for="pa-user-state" class="row justify-content-center align-items-center">
                                <div class="col-6 d-flex justify-content-center align-items-center">
                                    <p class="left">{{ $t('message.active') }}</p>
                                </div>
                                <div class="col-6 d-flex justify-content-center align-items-center px-0">
                                    <p class="right">{{ $t('message.inactive') }}</p>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 2">
                <div class="col col-lg-11">
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors.last_name ? '' : 'pb-3'">
                            <h3>Nombre *</h3>
                            <input type="text" placeholder="Nombre" v-model="new_user.personal_data.first_name">
                            <span v-if="errors.first_name">{{ errors.first_name }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors.first_name ? '' : 'pb-3'">
                            <h3>Apellido *</h3>
                            <input type="text" placeholder="Apellido" v-model="new_user.personal_data.last_name">
                            <span v-if="errors.last_name">{{ errors.last_name }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-between align-items-start mt-3 ps-lg-0" :class="errors.country ? '' : 'pb-3'">
                            <h3>Telefono *</h3>
                            <MazPhoneNumberInput
                                name="phone_number"
                                show-code-on-list
                                color="info"
                                orientation="row"
                                :preferred-countries="['MX']"
                                :ignored-countries="[]"
                                v-model="new_user.personal_data.phone_number"
                                v-model:country-code="new_user.personal_data.country_code"
                                @update="results_basic = $event"
                                :success="results_basic?.isValid"
                                :translations="{
                                    countrySelector: {
                                        placeholder: '',
                                        error: '',
                                    },
                                    phoneInput: {
                                        placeholder: 'Telefono',
                                        example: '',
                                        label: ''
                                    },
                                }"
                                no-example
                                fetch-country
                                no-use-browser-locale
                            />
                            <span v-if="errors.phone_number">{{ errors.phone_number }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors.phone_number ? '' : 'pb-3'">
                            <h3>Pais *</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.personal_data.country.id"
                                        placeholder="Seleccionar pais"
                                        color="info"
                                        :options="countries"
                                        name="country"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div>
                            <span v-if="errors.country">{{ errors.country }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-between align-items-start mt-3 ps-lg-0 gender-inputs" :class="errors.birthdate ? '' : 'pb-3'">
                            <h3>Género</h3>
                            <div class="col d-flex justify-content-between align-items-start position px-0">
                                <input type="radio" id="Male" value="MALE" name="gender" v-model.trim="new_user.personal_data.gender">
                                <label for="Male">
                                    <div class="span"></div>
                                    <i class="hombre me-2"></i>
                                    <p>Male</p>
                                </label>
                                <input type="radio" id="Female" value="FEMALE" name="gender" v-model.trim="new_user.personal_data.gender">
                                <label for="Female">
                                    <div class="span"></div>
                                    <i class="mujer me-2"></i>
                                    <p>Female</p>
                                </label>
                                <input type="radio" id="None" value="UNDEFINED" name="gender" v-model.trim="new_user.personal_data.gender">
                                <label for="None">
                                    <div class="span"></div>
                                    <i class="ambos me-2"></i>
                                    <p>None</p>
                                </label>
                            </div>
                            <span v-if="errors.gender">{{ errors.gender }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors.gender ? '' : 'pb-3'">
                            <h3>{{$t('message.birthdate')}}</h3>
                            <div class="position">
                                <input
                                    type="text"
                                    name="reg-user-day"
                                    id="reg-user-day"
                                    v-model="day"
                                    maxlength="2"
                                    @input="validateDay"
                                    @keydown.backspace="moveToPrevious($event, 'day')"
                                    placeholder="Día"
                                    :class="errors.birthdate"
                                />-
                                <input
                                    type="text"
                                    name="reg-user-month"
                                    id="reg-user-month"
                                    v-model="month"
                                    maxlength="2"
                                    @input="validateMonth"
                                    @keydown.backspace="moveToPrevious($event, 'month')"
                                    placeholder="Mes"
                                    :class="errors.birthdate"
                                />-
                                <input
                                    type="text"
                                    name="reg-user-year"
                                    id="reg-user-year"
                                    v-model="year"
                                    maxlength="4"
                                    @input="validateYear"
                                    @keydown.backspace="moveToPrevious($event, 'year')"
                                    placeholder="Año"
                                    :class="errors.birthdate"
                                />
                            </div>
                            <span v-if="errors.birthdate">{{ errors.birthdate }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0 pb-3">
                            <h3>Imagen de perfil</h3>
                            <div class="row justify-content-center align-items-center row__width padding-left">
                                <div class="col px-0 profile align-items-start" v-if="avatar_blob">
                                    <button type="button" class="button-close" @click="eraseImage()"><img src="../../../img/subir-productos-icons/close-azul.svg" alt="" class="button-close-image"></button>
                                    <img :src="avatar_blob" alt="">
                                </div>
                                <div class="col d-flex justify-content-start align-items-center position input-width px-lg-0" v-if="!avatar_blob">
                                    <label for="new_user_profile_pic" class="profile-plus">
                                        <img src="../../../img/panel-icons/plus.svg" alt="">
                                    </label>
                                    <input type="file" id="new_user_profile_pic" ref="fileInputAvatar" class="invisible wh-0" @change="handleFileChangeAvatar($event)">
                                </div>
                                <div class="col d-flex justify-content-start align-items-center position pe-0">
                                    <p class="ps-2 pb-1">Hasta 4 mb, formato jpg o png.</p>
                                </div>
                            </div>
                            <span v-if="errors.image">{{ errors.image }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 3" v-if="new_user.professional && new_user.professional.professional_data">
                <div class="col col-lg-11">
                    <div class="row justify-content-start mt-3">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.specializations ? '' : 'pb-3'">
                            <h3>Profesiones *</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.professional.professions_id"
                                        placeholder="Seleccionar profesión"
                                        color="info"
                                        :options="professions_list"
                                        name="profession"
                                        search
                                        search-placeholder="Buscar en la lista"
                                        @update:model-value="fetchSpecializations($event)"
                                    />
                                </div>
                            </div>
                            <span v-if="errors_prof.professions">{{ errors_prof.professions }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.professions ? '' : 'pb-3'">
                            <h3>Especializaciones *</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width select-bg-color-fix"
                                        v-model="new_user.professional.specializations_id"
                                        placeholder="Seleccionar especialización"
                                        color="info"
                                        :options="specializations_list"
                                        name="specialization"
                                        search
                                        search-placeholder="Buscar en la lista"
                                        multiple
                                    />
                                </div>
                            </div>
                            <span v-if="errors_prof.specializations">{{ errors_prof.specializations }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-3 ps-lg-0 ">
                            <div class="row row__width d-flex flex-column justify-content-start align-items-center">
                                <h3 class="mb-3 mb-lg-4">Banner</h3>
                            </div>
                            <div class="row row__width position-relative mb-4 row_banner" v-if="banner_blob">
                                <button type="button" class="button-close-banner" @click="eraseBanner()" ><img src="../../../img/subir-productos-icons/close-azul.svg" alt="" class="button-close-image"></button>
                                <label class="label blob d-flex justify-content-center align-items-center px-0 " v-if="banner_blob">
                                    <img :src="banner_blob" alt="">
                                </label>
                            </div>
                            <label for="pa-prof-banner" class="label d-flex justify-content-center align-items-center" v-if="!banner_blob">
                                <input type="file" name="pa-prof-banner" id="pa-prof-banner" ref="fileInputBanner" placeholder="Nombre" class="d-none" @change="handleFileChangeBanner($event)">
                                <span><img src="../../../img/panel-icons/perfil-mas.svg" alt=""></span>
                            </label>
                            <span v-if="errors_prof.banner">{{ errors_prof.banner }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.slug ? '' : 'pb-3'">
                            <h3>Email profesional *</h3>
                            <input type="mail" placeholder="example@gmail.com" v-model="new_user.professional.professional_data.email">
                            <span v-if="errors_prof.email">{{ errors_prof.email }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.email ? '' : 'pb-3'" v-if="state == 'e'">
                            <h3>Slug</h3>
                            <input type="text" placeholder="" v-model="new_user.professional.professional_data.slug">
                            <span v-if="errors_prof.slug">{{ errors_prof.slug }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" v-if="state == 'e'">
                            <h3>ID profesional</h3>
                            <input type="text" placeholder="Generado por servidor." disabled v-model="new_user.professional.professional_data.id">
                            <span v-if="errors_prof.id">{{ errors_prof.id }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0">
                            <h3>Acerca de *</h3>
                            <quill-editor 
                                class="w-100 no-resize" 
                                contentType="html" 
                                :toolbar="editorModules" 
                                theme="snow" 
                                style="height: 300px; overflow-y: auto;"
                                content-style="height: 100%;"
                                :options="{ scrollingContainer: '.ql-editor' }" 
                                v-model:content="new_user.professional.professional_data.about">
                            </quill-editor>
                            <span v-if="errors_prof.about">{{ errors_prof.about }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.country ? '' : 'pb-3'">
                            <h3>Telefono *</h3>
                            <MazPhoneNumberInput
                                name="phone_number"
                                show-code-on-list
                                color="info"
                                orientation="row"
                                :preferred-countries="['MX']"
                                :ignored-countries="[]"
                                v-model="new_user.professional.professional_data.phone_number"
                                v-model:country-code="new_user.professional.professional_data.country_code"
                                @update="results_prof = $event"
                                :success="results_prof?.isValid"
                                :translations="{
                                    countrySelector: {
                                        placeholder: '',
                                        error: '',
                                    },
                                    phoneInput: {
                                        placeholder: 'Telefono',
                                        example: '',
                                        label: ''
                                    },
                                }"
                                no-example
                                fetch-country
                                no-use-browser-locale
                            />
                            <span v-if="errors_prof.phone_number">{{ errors_prof.phone_number }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.phone_number ? '' : 'pb-3'">
                            <h3>Pais *</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.professional.professional_data.country.id"
                                        placeholder="Seleccionar pais"
                                        color="info"
                                        :options="countries"
                                        name="country"
                                        search
                                        search-placeholder="Buscar en la lista"
                                        @update:model-value="fetchStates($event);"
                                    />
                                </div>
                            </div>
                            <span v-if="errors_prof.country">{{ errors_prof.country }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.city ? '' : 'pb-3'">
                            <h3>Estado *</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.professional.professional_data.state.id"
                                        placeholder="Seleccionar estado"
                                        color="info"
                                        :options="states"
                                        name="state"
                                        search
                                        search-placeholder="Buscar en la lista"
                                        @update:model-value="fetchCities($event);"
                                    />
                                </div>
                            </div>
                            <span v-if="errors_prof.state">{{ errors_prof.state }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.state ? '' : 'pb-3'">
                            <h3>Ciudad *</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.professional.professional_data.city.id"
                                        placeholder="Seleccionar ciudad"
                                        color="info"
                                        :options="cities"
                                        name="city"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div>
                            <span v-if="errors_prof.city">{{ errors_prof.city }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.postal_code ? '' : 'pb-3'">
                            <h3>Direccion</h3>
                            <input type="text" placeholder="Direccion" v-model="new_user.professional.professional_data.address">
                            <span v-if="errors_prof.address">{{ errors_prof.address }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.address ? '' : 'pb-3'">
                            <h3>Codigo postal</h3>
                            <input type="text" placeholder="Codigo postal" v-model="new_user.professional.professional_data.postal_code">
                            <span v-if="errors_prof.postal_code">{{ errors_prof.postal_code }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start mt-3">
                        <div class="col-12 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0">
                            <h3>Redes sociales</h3>
                            <div class="d-flex justify-content-start row__width">
                                <div class="d-flex justify-content-start align-items-start px-0 pe-2" @click="selectSocialNetwork(social, index)" v-for="(social, index) in new_user.professional.professional_data.social_networks">
                                    <label class="profile-plus solid pointer" :class="actual_sn == social ? 'selected' : ''">
                                        <img src="../../../img/panel-icons/facebook-icon.svg" alt="" v-if="social.name.toLowerCase() == 'facebook'">
                                        <img src="../../../img/panel-icons/instagram.svg" alt="" v-if="social.name.toLowerCase() == 'instagram'">
                                        <img src="../../../img/panel-icons/twitter.svg" alt="" v-if="social.name.toLowerCase() == 'x'">
                                        <img src="../../../img/panel-icons/twitter.svg" alt="" v-if="social.name.toLowerCase() == 'web'">
                                        <img src="../../../img/panel-icons/likedin.svg" alt="" v-if="social.name.toLowerCase() == 'linkedin'">
                                    </label>
                                </div>
                                <div class="d-flex justify-content-start align-items-start px-0" @click="newSocialNetwork()">
                                    <label class="profile-plus pointer">
                                        <img src="../../../img/panel-icons/plus.svg" alt="">
                                    </label>
                                </div>
                            </div>
                            <span v-if="errors_prof.social_networks">{{ errors_prof.social_networks }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" v-if="actual_sn">
                            <h3>Red social</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="actual_sn.name"
                                        placeholder="Seleccionar red social"
                                        color="info"
                                        :options="social_network_list"
                                        name="social_network"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div>
                            <h3>URL</h3>
                            <input type="text" placeholder="https://www.ejemplo.com/usuario" v-model="actual_sn.url" @input="updateSocialNetwork(actual_sn.index, 'url', $event.target.value)">
                            <span v-if="errors_prof.social_networks">{{ errors_prof.social_networks }}</span>
                            <div class="d-flex col justify-content-between align-items-center mt-3 px-0 w-100">
                                <button type="button" class="btn_save btn-valid me-3" @click="resetActualSN()">Guardar</button>
                                <button type="button" class="btn_save btn-error me-3" @click="deleteSocialNetwork(actual_sn.index)">Eliminar</button>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-start mt-3" v-if="state == 'e'">
                        <div class="col-12 col-lg-4 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0">
                            <h3>Estado</h3>
                            <div class="estado-switch">
                                <button 
                                    :class="{ active: new_user.professional.active == true && new_user.professional.status_by == 'admin' }"
                                    type="button"
                                    @click="changeUserProfStatus(1)"
                                >
                                    Activo
                                </button>
                                <button 
                                    :class="{ active: new_user.professional.active == false }"
                                    type="button"
                                    @click="changeUserProfStatus(2)"
                                >
                                    Inactivo
                                </button>
                                <button 
                                    :class="{ active: new_user.professional.active == true && new_user.professional.status_by != 'admin' }"
                                    type="button"
                                    @click="changeUserProfStatus(3)"
                                >
                                    Default
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-evenly row__width px-lg-5 mt-3 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 4" v-if="!actual_gallery">
                <div class="d-flex justify-content-between ps-3 mt-3 mb-4 row__width">
                    <h2>Galerías</h2>
                </div>
                <!-- <div class="col-3 d-flex flex-column justify-content-start align-items-start mt-3 gallery" @click="getGallery(0), actual_gallery = null">
                    <div class="d-flex justify-content-center row__width">
                        <h3>Galería general</h3>
                    </div>
                </div> -->
                <div class="col-4 d-flex flex-column justify-content-start align-items-start mt-3 gallery" :class="gallery.active ? '' : 'inactive'" @click="gallery.active ? (getGallery(gallery), actual_gallery = null) : ''" v-for="(gallery, index) in user_specializations_list">
                    <div class="d-flex justify-content-center row__width">
                        <h3>{{ gallery.translation.name }}</h3>
                    </div>
                </div>
            </div>
            <div class="row justify-content-start row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 4" v-if="actual_gallery">
                <div class="col-12 d-flex flex-column justify-content-start align-items-start mt-3 gallery-show">
                    <div class="d-flex justify-content-between mt-3 mb-4 row__width ">
                        <h2>{{ actual_gallery.translation.name }}</h2>
                    </div>
                    <div class="d-flex justify-content-start row__width">
                        <div class="gallery-container">
                            <div class="thumbnail-list">
                                <div class="thumbnail-wrapper" v-for="image in actual_gallery.files" :key="image.id">
                                    <img
                                        :src="image.file"
                                        alt="Gallery Thumbnail"
                                        @click="openLightbox(image)"
                                        class="thumbnail"
                                    />
                                    <button type="button" class="delete-button" @click="openDeletePopup(image.id)">✖</button>
                                </div>
                                <label for="new_user_gallery_file" class="">
                                    <img src="../../../img/panel-icons/plus.svg" alt="" class="thumbnail thumbnail-new add-file-img">
                                </label>
                                <input type="file" multiple id="new_user_gallery_file" ref="fileInputGallery" class="invisible d-none wh-0" @change="handleFileGallery($event)">
                            </div>
                            <div v-if="lightbox_open" class="lightbox">
                                <div class="backdrop" @click="closeLightbox"></div>
                                <div class="lightbox-content">
                                    <div class="navigation">
                                        <span @click="previousImage" class="arrow left-arrow">❮</span>
                                        <img :src="selected_image?.file" alt="Expanded Image" class="expanded-image" />
                                        <span @click="nextImage" class="arrow right-arrow">❯</span>
                                    </div>
                                    <div class="lightbox-thumbnails" ref="thumbnail_container">
                                        <img
                                            v-for="image in actual_gallery.files"
                                            :key="image.id"
                                            :src="image.file"
                                            alt="Lightbox Thumbnail"
                                            class="lightbox-thumbnail"
                                            :class="{'lightbox-thumbnail': true, 'selected': image.id === selected_image?.id}"
                                            @click="selectImage(image)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div v-if="delete_popup_open" class="delete-popup-backdrop">
                                <div class="delete-popup">
                                    <p>Estas seguro que quieres eliminar esta imagen?</p>
                                    <div class="">
                                        <button type="button" @click="confirmDeleteImage">Yes</button>
                                        <button type="button" @click="closeDeletePopup">No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 5">
                <div class="d-flex justify-content-between ps-3 mt-3 mb-4 row__width">
                    <h2>Reviews</h2>
                </div>
            </div>
            <div class="row justify-content-center row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 6">
                <div class="d-flex justify-content-between ps-3 mt-3 mb-4 row__width">
                    <h2>Reviews</h2>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 approvals" v-if="temporal">
            <div class="row justify-content-center row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''">
                <div class="col-12 d-flex justify-content-start align-items-center mb-4">
                    <h2>Modificaciones pendientes</h2>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                    <div class="row justify-content-center justify-content-lg-center row__width ">
                        <!-- <div class="w-100 table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{{ $t('message.field') }}</th>
                                        <th>{{ $t('message.original') }}</th>
                                        <th>{{ $t('message.modified') }}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(change, index) in changes" :key="index">
                                        <td>{{ change.field }}</td>
                                        <td v-if="!change.isImage">{{ change.original }}</td>
                                        <td v-else>
                                            <img :src="change.original" alt="Original Image" class="image-preview" @click="openLightboxTemporary(change.original)"/>
                                        </td>
                                        <td v-if="!change.isImage">{{ change.modified }}</td>
                                        <td v-else>
                                            <img :src="change.modified" alt="Modified Image" class="image-preview" @click="openLightboxTemporary(change.modified)"/>
                                        </td>
                                    </tr>
                                    <tr v-for="(change, index) in galleries_changes" :key="index">
                                        <td><p>{{ change.gallery[change.gallery_index].translation.name }}</p></td>
                                        <td>
                                            <img :src="change.original.file" alt="Original Image" class="image-preview" @click="openLightboxTemporary(change.original.file)"/>
                                        </td>
                                        <td>
                                            <img :src="change.modified.file" alt="Modified Image" class="image-preview" @click="openLightboxTemporary(change.modified.file)"/>
                                        </td>
                                        <td><img src="../../../img/panel-icons/close-red.svg" alt="delete" style="cursor: pointer;" @click="openDeleteTemporaryImagePopup(change)"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
                        <div class="col-12 scroll">
                            <div class="row row_scroll row_color">
                                <div class="col-3 d-flex justify-content-center align-items-center pt-0"><p>{{ $t('message.field') }}</p></div>
                                <div class="col-3 d-flex justify-content-center align-items-center pt-0"><p>{{ $t('message.original') }}</p></div>
                                <div class="col-3 d-flex justify-content-center align-items-center pt-0"><p>{{ $t('message.modified') }}</p></div>
                                <div class="col-3 d-flex justify-content-center align-items-center pt-0"></div>
                            </div>
                            <div class="row row_scroll" v-for="(change, index) in changes" :key="index">
                                <div class="col-3 d-flex justify-content-center align-items-center">
                                    {{ change.field }}
                                </div>
                                <div class="col-3 d-flex justify-content-center align-items-center" v-if="!change.isImage">{{ change.original }}</div>
                                <div class="col-3 d-flex justify-content-center align-items-center" v-else>
                                    <img :src="change.original" alt="Original Image" class="image-preview" @click="openLightboxTemporary(change.original)"/>
                                </div>
                                <div class="col-3 d-flex justify-content-center align-items-center" v-if="!change.isImage">{{ change.modified }}</div>
                                <div class="col-3 d-flex justify-content-center align-items-center" v-else>
                                    <img :src="change.modified" alt="Modified Image" class="image-preview" @click="openLightboxTemporary(change.modified)"/>
                                </div>
                            </div>
                            <div class="row row_scroll" v-for="(change, index) in galleries_changes" :key="index">
                                <div class="col-3 d-flex justify-content-start align-items-center"><p>{{ change.gallery[change.gallery_index].translation.name }}</p></div>
                                <div class="col-3 d-flex justify-content-center align-items-center">
                                    <img :src="change.original.file" alt="Original Image" class="image-preview" @click="openLightboxTemporary(change.original.file)"/>
                                </div>
                                <div class="col-3 d-flex justify-content-center align-items-center">
                                    <img :src="change.modified.file" alt="Modified Image" class="image-preview" @click="openLightboxTemporary(change.modified.file)"/>
                                </div>
                                <div class="col-3 d-flex justify-content-center align-items-center"><img src="../../../img/panel-icons/close-red.svg" alt="delete" style="cursor: pointer;" @click="openDeleteTemporaryImagePopup(change)"></div>
                            </div>
                        </div>
                        <div v-if="lightbox_temporary_open" class="lightbox">
                            <div class="backdrop" @click="closeLightboxTemporary()"></div>
                            <div class="lightbox-content">
                                <div class="navigation">
                                    <img :src="selected_temporary_image" alt="Expanded Image" class="expanded-image" />
                                </div>
                            </div>
                        </div>
                        <div class="d-flex col-12 col-md-6 mt-3">
                            <button type="button" class="btn_save btn-valid me-2" @click="openApprovePopup()">Aprobar</button>
                            <Cancelar @click="openRejectPopup()" text="Rechazar" color="red"/>
                        </div>
                        <div v-if="approve_popup_open" class="delete-popup-backdrop">
                            <div class="delete-popup">
                                <p>{{ $t('message.areYouSureApproveChanges') }}</p>
                                <p class="w-100 mt-2">{{ $t('message.reason') }}:</p>
                                <input type="text" v-model="approve_comment">
                                <div class="mt-3">
                                    <button type="button" @click="approveChanges()">Yes</button>
                                    <button type="button" @click="closeApprovePopup()">No</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="reject_popup_open" class="delete-popup-backdrop">
                            <div class="delete-popup">
                                <p>{{ $t('message.areYouSureRejectChanges') }}</p>
                                <p class="w-100 mt-2">{{ $t('message.reason') }}:</p>
                                <input type="text" v-model="reject_comment">
                                <div class="mt-3">
                                    <button type="button" @click="rejectChanges()">Yes</button>
                                    <button type="button" @click="closeRejectPopup()">No</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="delete_temporary_image_popup_open" class="delete-popup-backdrop">
                            <div class="delete-popup">
                                <p>{{ $t('message.areYouSureApproveChanges') }}</p>
                                <div class="mt-3">
                                    <button type="button" @click="deleteTemporaryImage(delete_temporary_change.spec_id, delete_temporary_change.file_id)">Yes</button>
                                    <button type="button" @click="closeDeleteTemporaryImagePopup()">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-5 border-top">
            <div class="row justify-content-center justify-content-lg-between px-lg-5 row__width">
                <div class="col-6 col-lg-3 d-flex justify-content-center align-items-center ps-0">
                    <Cancelar @click="back()" v-if="!actual_gallery?.files"/>
                    <button id="save-button" ref="buttonRef" type="button" class="btn__inicio"
                        @click="actual_gallery = null"
                        v-if="actual_page == 4 && actual_gallery?.files"
                    >
                        {{$t('message.return')}}
                    </button>
                </div>
                <div class="col-6 col-lg-3 d-flex justify-content-center align-items-center pe-0" v-if="!temporal">
                    <button id="save-button" ref="buttonRef" type="button" class="btn_save"
                        @click="isValid ? save() : ''"
                        :class="{ 'btn-valid': isValid }"
                        v-if="state == 'c'"
                    >
                        {{$t('message.createUser')}}
                    </button>
                    <button id="edit-button" ref="buttonRefEdit" type="button" class="btn_save"
                        @click="isValid && !button_loading ? edit() : ''"
                        :class="{ 'btn-valid': isValid }"
                        v-show="actual_page == 1 || actual_page == 2 || actual_page == 3"
                        v-else
                    >
                        {{$t('message.save')}}
                        <ButtonLoader class="ms-2" :loading="button_loading"/>
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script setup>
import TituloPanelAdmin from "../../../components/Titulo/TituloPanelAdmin.vue";
import Loader from "../../../components/Filtros/Loader.vue"
import ButtonLoader from "../../../components/Filtros/ButtonLoader.vue"
import Cancelar from "../../../components/Botones/Cancelar.vue"
import MazSelect from 'maz-ui/components/MazSelect'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import countriesJson from '../../../assets/countries.json'
import { ref, provide, onBeforeMount, computed, inject, watch, nextTick, createApp, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"
import { useConfigStore } from "../../../stores/config";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n'
import { parsePhoneNumberFromString, getPhoneCode } from 'libphonenumber-js';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

polyfillCountryFlagEmojis()

let props = defineProps({
    isSidebarOpen: Boolean
})

const { t }  = useI18n()

const users = useUsersStore()
const { headers } = storeToRefs(users)
const { url } = users

const config = useConfigStore()
const { countries, states, cities } = storeToRefs(config)
const { fetchCountries, fetchStates, fetchCities } = config

const router = useRouter()

const urlUser = '/api/admin/users'
const urlTeams = '/api/admin/teams'
const urlPlans = '/api/admin/plans'
const urlProfessions = '/api/admin/professions'
const urlEsp = '/api/admin/specializations'

const actual_page = ref(1)
let page_id = inject('page_id')
let user_id = inject('user_id')
let state = inject('state')

let fetchUsers = inject('fetchUsers')

const app = createApp()
app.component('QuillEditor', QuillEditor)

const editorModules = {
  toolbar: [
    [{ header: [] }], // Encabezados de h1 a h6
    ['bold', 'italic', 'underline'],  
    [{ list: 'ordered' }, { list: 'bullet' }], // Listas
    ['blockquote', 'code-block'],        // Bloque de cita y bloque de código
    [{ script: 'sub' }, { script: 'super' }], // Superíndice y subíndice
    [{ align: [] }],                     // Alineación
    ['link', 'image', 'video'],          // Insertar link, imagen y video
    [{ color: [] }, { background: [] }], // Colores de texto y fondo
    ['clean']                            // Limpiar formato
  ]
};

const loading = ref(false);
const button_loading = ref(false);

let results_basic = ref()
let results_prof = ref()

let new_user = inject('new_user')

const original_user = ref({
    id: 0,
    personal_data: {
        first_name: '',
        last_name: '',
        country_code: '',
        phone_number: '',
        birthdate: '',
        country: {
            id: ''
        },
        nickname: ''
    },
    email: '',
    plan: {
        id: ''
    },
    team: {
        id: ''
    },
    avatar: null,
    password: '',
    status: false,
    professional: {
        id: '',
        active: '',
        professional_data: {
            id: '',
            email: '',
            banner: '',
            country: {
                id: ''
            },
            state: {
                id: ''
            },
            city: {
                id: ''
            },
            address: '',
            postal_code: '',
            country_code: '',
            phone_number: '',
            social_networks: [],
            about: '',
            slug: ''
        },
        professions_id: '',
        professions: [
            {
                specializations: []
            }
        ],
        specializations_id: '',
        attatchments: {
            gallery: []
        },
        portfolio: null,
        reviews: null
    }
})

const errors = ref({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    country: '',
    country_code: '',
    phone_number: '',
    birthdate: '',
    plan: '',
    team: '',
    avatar: null,
    password: ''
})

const errors_prof = ref({
    email: '',
    banner: '',
    country: '',
    state: '',
    city: '',
    postal_code: '',
    country_code: '',
    phone_number: '',
    social_networks: '',
    about: '',
    professions: '',
    specializations: '',
    slug: ''
})

const plans_list = ref([])

const teams_list = ref([])

const professions_list = ref([])
const specializations_list = ref([])

const user_specializations_list = ref([])

const fileInputAvatar = ref(null)
const fileInputBanner = ref(null)
const fileInputGallery = ref(null)

const actual_gallery = ref(null)
const selected_image = ref(null)
const lightbox_open = ref(false)
const thumbnail_container = ref(null)
const delete_popup_open = ref(false)
const image_to_delete = ref(null)

const social_network_list = ref([
    {
        label: 'Facebook',
        value: 'facebook'
    },
    {
        label: 'Instagram',
        value: 'instagram'
    },
    {
        label: 'LinkedIn',
        value: 'linkedIn'
    },
    {
        label: 'X',
        value: 'x'
    },
    {
        label: 'Web',
        value: 'web'
    }
])

const avatar_blob = ref(null)
const banner_blob = ref(null)

const actual_sn = ref()

const day = ref('')
const month = ref('')
const year = ref('')
const currentYear = new Date().getFullYear()

const temporal = ref(false)
const approve_popup_open = ref(false)
const reject_popup_open = ref(false)
const delete_temporary_image_popup_open = ref(false)
const lightbox_temporary_open = ref(false)
const selected_temporary_image = ref(null)

const approve_comment = ref(null)
const reject_comment = ref(null)
const delete_temporary_change = ref(null)

const changes = computed(() => {
    return mapTemporaryChanges(new_user.value.professional.professional_data, new_user.value.professional.professional_data.temporary)
})

const galleries_changes = computed(() => {
    return mapTemporaryGalleriesChanges(
        new_user.value.professional.attatchments.gallery, 
        new_user.value.professional.attatchments.gallery.map(gallery => gallery.temporary)
    )
})

const changePage = (page) => {
    actual_page.value = page
}

const isValidDate = computed(() => {
    const dayNum = parseInt(day.value, 10)
    const monthNum = parseInt(month.value, 10)
    const yearNum = parseInt(year.value, 10)

    const validDay = dayNum >= 1 && dayNum <= 31
    const validMonth = monthNum >= 1 && monthNum <= 12
    const validYear = yearNum >= 0 && yearNum <= currentYear

    return validDay && validMonth && validYear
})

const isValid = computed(() => {
    const user = new_user.value
    const isCreateMode = state.value === 'c'
    let professionalEmailValid = true
    let professionalCountryValid = true
    let professionalPhoneValid = true
    let professionalProfessionValid = true
    let professionalSpecializationValid = true
    let professionalStateValid = true
    let professionalCityValid = true
    let professionalSlugValid = true
    let professionalAboutValid = true
    let professionalSocialMediaValid = true

    console.log('user', user.value, user)

    const emailValid = user.email && user.email.trim() !== ''
    const passwordValid = isCreateMode ? (user.password && user.password.trim() !== '') : true
    const teamValid = user.team.id === null || typeof user.team.id === 'number'
    const planValid = user.plan.id === null || typeof user.plan.id === 'number'

    const firstNameValid = user.personal_data.first_name && user.personal_data.first_name.trim() !== ''
    const lastNameValid = user.personal_data.last_name && user.personal_data.last_name.trim() !== ''
    const phoneValid = (user.personal_data.country_code && user.personal_data.phone_number) && results_basic.value.isValid
    const countryValid = user.personal_data.country.id && typeof user.personal_data.country.id === 'number'

    console.log('primero', emailValid, passwordValid, teamValid, planValid, firstNameValid, lastNameValid, phoneValid, countryValid)

    if (user.team.id == 4 || user.plan.id == 2) {
        professionalEmailValid = user.professional.professional_data.email && user.professional.professional_data.email.trim() !== ''

        professionalCountryValid = user.professional.professional_data.country.id && typeof user.professional.professional_data.country.id === 'number'
        professionalStateValid = user.professional.professional_data.state.id && typeof user.professional.professional_data.state.id === 'number'
        professionalCityValid = user.professional.professional_data.city.id && typeof user.professional.professional_data.city.id === 'number'

        professionalPhoneValid = (user.professional.professional_data.country_code && user.professional.professional_data.phone_number) && results_prof.value.isValid
        
        professionalProfessionValid = user.professional.professions_id && user.professional.professions_id > 0
        professionalSpecializationValid = Array.isArray(user.professional.specializations_id) && user.professional.specializations_id.length > 0

        professionalAboutValid = user.professional.professional_data.about && user.professional.professional_data.about.trim() !== ''
    }

    console.log('segundo', emailValid,
           firstNameValid,
           lastNameValid,
           teamValid,
           planValid,
           passwordValid,
           phoneValid,
           countryValid,)
    console.log('emailValid', emailValid)
    console.log('firstNameValid', firstNameValid)
    console.log('lastNameValid', lastNameValid)
    console.log('teamValid', teamValid)
    console.log('planValid', planValid)
    console.log('passwordValid', passwordValid)
    console.log('phoneValid', phoneValid)
    console.log('countryValid', countryValid)

    console.log('professionalEmailValid', professionalEmailValid)
    console.log('professionalCountryValid', professionalCountryValid)
    console.log('professionalCountryValid', professionalCountryValid)
    console.log('professionalPhoneValid', professionalPhoneValid)
    console.log('professionalProfessionValid', professionalProfessionValid)
    console.log('professionalSpecializationValid', professionalSpecializationValid)
    console.log('professionalStateValid', professionalStateValid)
    console.log('professionalCityValid', professionalCityValid)
    console.log('professionalAboutValid', professionalAboutValid)
    if(user.team.id == 4){
        return emailValid &&
           firstNameValid &&
           lastNameValid &&
           teamValid &&
           planValid &&
           passwordValid &&
           phoneValid &&
           countryValid &&
           professionalEmailValid &&
           professionalCountryValid &&
           professionalPhoneValid &&
           professionalProfessionValid &&
           professionalSpecializationValid &&
           professionalStateValid &&
           professionalCityValid &&
           professionalAboutValid
    }else{
        return emailValid &&
           firstNameValid &&
           lastNameValid &&
           teamValid &&
           planValid &&
           passwordValid &&
           phoneValid &&
           countryValid
    }
})

const convertDateFormat = (dateString) => {
    if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
        throw new Error('Formato de fecha no válido. Debe ser YYYY-MM-DD.')
    }
    const [yearValue, monthValue, dayValue] = dateString.split('-')
    day.value = dayValue
    month.value = monthValue
    year.value = yearValue
}

const showPassword = () => {
    if(document.getElementById('editUserPassword').getAttribute('type') == 'password'){
        document.getElementById('editUserPassword').setAttribute('type', 'text')
    }else{
        document.getElementById('editUserPassword').setAttribute('type','password')
    }
}

const handleFileChangeAvatar = (event) => {
    const file = event.target.files[0]
    if (file) {
        new_user.value.avatar = file
        avatar_blob.value = URL.createObjectURL(file)
        fileInputAvatar.value.value = ''
    }
}

const handleFileChangeBanner = (event) => {
    const file = event.target.files[0]
    if (file) {
        new_user.value.professional.professional_data.banner = file
        banner_blob.value = URL.createObjectURL(file)
        fileInputBanner.value.value = ''
    }
}

const handleFileGallery = (event) => {
    const files = event.target.files
    if (files) {
        createFileGallery(files)
        fileInputGallery.value.value = ''
    }
}

const openLightbox = (image) => {
    selected_image.value = image
    lightbox_open.value = true
}

const openLightboxTemporary = (image) => {
    selected_temporary_image.value = image
    lightbox_temporary_open.value = true
}

const closeLightbox = () => {
    lightbox_open.value = false
    selected_image.value = null
}

const closeLightboxTemporary = () => {
    lightbox_temporary_open.value = false
    selected_temporary_image.value = null
}

const selectImage = (image) => {
    selected_image.value = image
}

const previousImage = () => {
    const current_index = actual_gallery.value.files.findIndex(image => image.id === selected_image.value.id)
    const previous_index = (current_index - 1 + actual_gallery.value.files.length) % actual_gallery.value.files.length
    selected_image.value = actual_gallery.value.files[previous_index]
}

const nextImage = () => {
    const current_index = actual_gallery.value.files.findIndex(image => image.id === selected_image.value.id)
    const next_index = (current_index + 1) % actual_gallery.value.files.length
    selected_image.value = actual_gallery.value.files[next_index]
}

const scrollToSelectedThumbnail = () => {
    if (thumbnail_container.value) {
        const selected_thumbnail = thumbnail_container.value.querySelector('.selected')
        if (selected_thumbnail) {
            const container_width = thumbnail_container.value.offsetWidth
            const thumbnail_width = selected_thumbnail.offsetWidth
            const scroll_position = selected_thumbnail.offsetLeft - (container_width / 2) + (thumbnail_width / 2)
            thumbnail_container.value.scrollTo({
                left: scroll_position,
                behavior: 'smooth'
            })
        }
    }
}

const eraseImage = () => {
    new_user.value.avatar = null
    avatar_blob.value = null
}

const eraseBanner = () => {
    new_user.value.professional.professional_data.banner = null
    banner_blob.value = null
}

const resetErrors = () => {
    errors.value = {
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        plan: '',
        team: '',
        image: null,
        password: '',
        professional: {
            id: '',
            active: '',
            professional_data: {
                id: '',
                email: '',
                banner: '',
                country: '',
                state: '',
                city: '',
                province: '',
                locality: '',
                address: '',
                postal_code: '',
                country_code: '',
                phone_number: '',
                social_networks: '',
                about: '',
                slug: ''
            },
            professions: '',
            portfolio: '',
            reviews: ''
        }
    }
}

const setProfessionalMembership = () => {
    new_user.value.professional = {
        id: '',
        active: '',
        professional_data: {
            id: '',
            email: '',
            banner: '',
            country: '',
            state: '',
            city: '',
            province: '',
            locality: '',
            address: '',
            postal_code: '',
            country_code: '',
            phone_number: '',
            social_networks: [],
            about: '',
            slug: ''
        },
        professions: [],
        portfolio: null,
        reviews: null
    }
}

const handleServerErrors = (serverErrors) => {
    if (serverErrors) {
            errors.value = serverErrors
    }
}

const handleServerErrorsProf = (serverErrors) => {
    if (serverErrors) {
            errors_prof.value = serverErrors
    }
}

const loadPhoneNumber = (phoneNumber, countryCode) => {
    const phoneNumberObject = parsePhoneNumberFromString(phoneNumber, countryCode)

    if (phoneNumberObject) {
        return {
        countryCode: phoneNumberObject.countryCallingCode,
        localNumber: phoneNumberObject.nationalNumber
        }
    } else {
        throw new Error('Número de teléfono no válido')
    }
}

const convertNumericCodeToString = (countryCode, phoneNumber) => {
    const testPhoneNumber = `+${countryCode}${phoneNumber}`
    
    const phoneNumberObject = parsePhoneNumberFromString(testPhoneNumber)

    if (!phoneNumberObject) {
        throw new Error(`No se encontró un código de país para el código numérico ${countryCode}`)
    }

    return {
        countryCode: phoneNumberObject.country,
        phoneNumber: phoneNumberObject.nationalNumber
    }
}

const updateSocialNetwork = (index, key, value) => {
    const social_network_key = new_user.value.professional.professional_data.social_networks[index].name
    new_user.value.professional.professional_data.social_networks[social_network_key] = value
}

const newSocialNetwork = () => {
    console.log(new_user.value.professional.professional_data)
    let index = new_user.value.professional.professional_data.social_networks.length
    actual_sn.value = {
        name: '',
        url: '',
        index: index,
        new: true
    }
    new_user.value.professional.professional_data.social_networks.push(actual_sn.value)
}

const selectSocialNetwork = (social, index) => {
    if (actual_sn.value == social) {
        actual_sn.value = null
    }
    else {
        actual_sn.value = social
        actual_sn.value.index = index
        actual_sn.value.new = false
    }
}

const deleteSocialNetwork = (index) => {
    new_user.value.professional.professional_data.social_networks.splice(index, 1)
    resetActualSN()
}

const resetActualSN = () => {
    actual_sn.value = null
}

const validateDay = (event) => {
    let value = event.target.value;
    if (!/^\d{0,2}$/.test(value)) {
        value = value.slice(0, -1);
    } else {
        let intValue = parseInt(value);
        if (value.length === 1 && intValue >= 4 && intValue <= 9) {
            value = '0' + value;
            day.value = value;
            setTimeout(() => {
                moveToNext(event, 'day');
            }, 0);
        } else if (value.length === 2) {
            if (intValue > 31) {
                value = '31';
                day.value = value;
            } else if (intValue < 1) {
                value = '01';
                day.value = value;
            } else {
                day.value = value;
            }
            moveToNext(event, 'day');
        } else {
            day.value = value;
        }
    }
}

const validateMonth = (event) => {
    let value = event.target.value;
    if (!/^\d{0,2}$/.test(value)) {
        value = value.slice(0, -1);
    } else {
        let intValue = parseInt(value);
        if (value.length === 1 && intValue > 1 && intValue <= 9) {
            value = '0' + value;
            month.value = value;
            setTimeout(() => {
                moveToNext(event, 'month');
            }, 0);
        } else if (value.length === 2) {
            if (intValue > 12) {
                value = '12';
                month.value = value;
            } else if (intValue < 1) {
                value = '01';
                month.value = value;
            } else {
                month.value = value;
            }
            moveToNext(event, 'month');
        } else {
            month.value = value;
        }
    }
}

const validateYear = (event) => {
    let value = event.target.value;
    if (!/^\d{0,4}$/.test(value)) {
        value = value.slice(0, -1);
    } else if (value.length === 4) {
        let intValue = parseInt(value);
        if (intValue > currentYear) {
            value = currentYear.toString();
            year.value = value;
        } else {
            year.value = value;
        }
        moveToNext(event, 'year');
    } else {
        year.value = value;
    }
}

const moveToNext = (event, field) => {
    const input = event.target
    if (input.value.length >= input.maxLength) {
        switch (field) {
        case 'day':
            input.nextElementSibling.focus()
            break
        case 'month':
            input.nextElementSibling.focus()
            break
        case 'year':
            break
        }
    }
}

const moveToPrevious = (event, field) => {
    const input = event.target
    if (input.value.length === 0 && event.key === 'Backspace') {
        switch (field) {
        case 'day':
            break
        case 'month':
            input.previousElementSibling.focus()
            break
        case 'year':
            input.previousElementSibling.focus()
            break
        }
    }
}

const fetchTeams = async () => {
    try {
        const response = await axios.get(url+urlTeams, { headers: headers.value })
        console.log(response)
        teams_list.value = response.data.data
        teams_list.value.forEach((team) => {
            team.label = team.translation.name,
            team.value = team.id
        })
    } catch (error) {
        console.log(error)
    }
}

const fetchPlans = async () => {
    try {
        const response = await axios.get(url+urlPlans, { headers: headers.value })
        plans_list.value = response.data.data
        plans_list.value.forEach((plan) => {
            plan.label = plan.translation.name,
            plan.value = plan.id
        })
    } catch (error) {
        console.log(error)
    }
}

const fetchProfessions = async () => {
    try {
        const response = await axios.get(`${url}${urlProfessions}`, {headers: headers.value})
        console.log(response)
        professions_list.value = response.data.data
        professions_list.value.forEach((prof) => {
            prof.label = prof.translation.name,
            prof.value = prof.id
        })
    } catch (error) {
        console.log(error)
    }
}

const fetchSpecializations = async (prof_id) => {
    try {
        const response = await axios.get(`${url}${urlEsp}?professionId=${prof_id}`, {headers: headers.value})
        console.log(response, 'response esp')
        if (response.data.status == 'Success') {
            new_user.value.professional.specializations_id = []
            specializations_list.value = response.data.data
            specializations_list.value.forEach((spec) => {
                spec.label = spec.translation.name,
                spec.value = spec.id
            })
        }
    } catch (error) {
        console.log(error)
    }
}

const deepAssign = (target, source) => {
    Object.keys(source).forEach(key => {
        if (source[key] !== null) {
            if (typeof source[key] === 'object') {
                if (!target[key]) {
                    target[key] = {}
                }
                deepAssign(target[key], source[key])
            } else {
                target[key] = source[key]
            }
        }
    })
}

const fetchUser = async (id) => {
    try {
        new_user.value = {
            id: 0,
            personal_data: {
                first_name: '',
                last_name: '',
                country_code: '',
                phone_number: '',
                birthdate: '',
                country: {
                    id: ''
                },
                nickname: ''
            },
            email: '',
            plan: {
                id: ''
            },
            team: {
                id: ''
            },
            avatar: null,
            password: '',
            status: false,
            professional: {
                id: '',
                active: '',
                professional_data: {
                    id: '',
                    email: '',
                    banner: '',
                    country: {
                        id: ''
                    },
                    state: {
                        id: ''
                    },
                    city: {
                        id: ''
                    },
                    address: '',
                    postal_code: '',
                    country_code: '',
                    phone_number: '',
                    social_networks: [],
                    about: '',
                    slug: ''
                },
                professions_id: '',
                professions: [
                    {
                        specializations: []
                    }
                ],
                specializations_id: '',
                attatchments: {
                    gallery: []
                },
                portfolio: null,
                reviews: null
            }
        }
        temporal.value = false
        avatar_blob.value = null
        banner_blob.value = null
        const response = await axios.get(url+urlUser+`/${id}`, { headers: headers.value })
        console.log(response, 'response user')
        deepAssign(new_user.value, response.data.data)
        new_user.value.password = ''
        new_user.value.status = !response.data.data.active
        if (new_user.value.personal_data.birthdate) {
            convertDateFormat(new_user.value.personal_data.birthdate)
        }
        if(new_user.value.personal_data.country_code && new_user.value.personal_data.country_code.startsWith('+')) {
            new_user.value.personal_data.country_code = new_user.value.personal_data.country_code.slice(1)
            let formattedNumber = convertNumericCodeToString(new_user.value.personal_data.country_code, new_user.value.personal_data.phone_number)
            new_user.value.personal_data.country_code = formattedNumber.countryCode
            new_user.value.personal_data.phone_number = formattedNumber.phoneNumber
        }
        if (new_user.value.avatar) {
            avatar_blob.value = new_user.value.avatar
        }
        if (new_user.value.professional) {
            if (new_user.value.professional.professional_data.banner) {
                banner_blob.value = new_user.value.professional.professional_data.banner
            }
            new_user.value.professional.status = !response.data.data.professional.active
            new_user.value.professional.professional_data.social_networks = Object.entries(new_user.value.professional.professional_data.social_networks).map(([key, value]) => ({
                name: key,
                url: value
            }))
            console.log(new_user.value.professional.professional_data.social_networks)
            if(new_user.value.professional.professional_data.country_code && new_user.value.professional.professional_data.country_code.startsWith('+')) {
                new_user.value.professional.professional_data.country_code = new_user.value.professional.professional_data.country_code.slice(1)
                let formattedNumber = convertNumericCodeToString(new_user.value.professional.professional_data.country_code, new_user.value.professional.professional_data.phone_number)
                new_user.value.professional.professional_data.country_code = formattedNumber.countryCode
                new_user.value.professional.professional_data.phone_number = formattedNumber.phoneNumber
            }
            new_user.value.professional.professions_id = new_user.value.professional.professions[0].id
            console.log(new_user.value.professional.professions_id, 'new_user.value.professional.professions_id')
            await fetchSpecializations(new_user.value.professional.professions_id)
            new_user.value.professional.specializations_id = new_user.value.professional.professions[0].specializations.map(prof => prof.id)
            await fetchStates(new_user.value.professional.professional_data.country.id)
            await fetchCities(new_user.value.professional.professional_data.state.id)
            if (new_user.value.professional.professional_data.temporary) { 
                temporal.value = true 
            }
            new_user.value.professional.attatchments.gallery.forEach(gallery => {
                if (gallery.temporary) { 
                    temporal.value = true 
                }
            })

        }
        else {
            setProfessionalMembership()
        }
        original_user.value = structuredClone(new_user.value)
        loading.value = false
        console.log(new_user.value, 'new user')
        await nextTick()
    } catch (error) {
        console.log(error)
        loading.value = false
    }
}

const back = () => {
    new_user.value = {
        id: 0,
        personal_data: {
            first_name: '',
            last_name: '',
            country_code: '',
            phone_number: '',
            birthdate: '',
            country: {
                id: ''
            },
            nickname: ''
        },
        email: '',
        plan: {
            id: ''
        },
        team: {
            id: ''
        },
        avatar: null,
        password: '',
        status: false,
        professional: {
            id: '',
            active: '',
            professional_data: {
                id: '',
                email: '',
                banner: '',
                country: {
                    id: ''
                },
                state: {
                    id: ''
                },
                city: {
                    id: ''
                },
                address: '',
                postal_code: '',
                country_code: '',
                phone_number: '',
                social_networks: [],
                about: '',
                slug: ''
            },
            professions: [
                {
                    specializations: []
                }
            ],
            attatchments: {
                gallery: []
            },
            portfolio: null,
            reviews: null
        }
    }
    page_id.value = 1
    state.value = 'c'
    temporal.value = false
    user_id.value = 0
    actual_page.value = 1
}

const saveAccountInfo = async () => {
    let endpoint = {
        create: `${url}${urlUser}`,
        edit: `${url}${urlUser}/${new_user.value.id}`
    }

    console.log(new_user.value.id)

    let formattedNumber = loadPhoneNumber(new_user.value.personal_data.phone_number, new_user.value.personal_data.country_code)

    const form_data = new FormData()

    form_data.append('email', new_user.value.email)
    form_data.append('password', new_user.value.password)
    form_data.append('team_id', new_user.value.team.id)
    form_data.append('plan_id', new_user.value.plan.id)

    if (new_user.value.avatar && new_user.value.avatar instanceof File) {
        form_data.append('_method', 'PATCH')
        form_data.append('avatar', new_user.value.avatar)
    }

    if (state.value == 'c') {
        form_data.append('first_name', new_user.value.personal_data.first_name)
        form_data.append('last_name', new_user.value.personal_data.last_name)
        form_data.append('birthdate', new_user.value.personal_data.birthdate)
        form_data.append('country_code', '+'+formattedNumber.countryCode)
        form_data.append('phone_number', formattedNumber.localNumber)
        form_data.append('gender', new_user.value.personal_data.gender)
        form_data.append('country_id', new_user.value.personal_data.country.id)
    }
    if (state.value == 'e') {
        form_data.append('_method', 'PATCH')
    }

    for (let [key, value] of form_data.entries()) {
        console.log(`${key}: ${value}`);
    }

    const response = await axios.post(state.value == 'c' ? endpoint.create : endpoint.edit, form_data, { headers: headers.value })
    console.log(new_user.value.id)
    if(!new_user.value.id){
        console.log(response, 'response')
        new_user.value.id = response.data.data.user_id
    }

    return response
}

const saveBasicInfo = async () => {
    let endpoint = `${url}${urlUser}/${new_user.value.id}/personal_data`

    let formattedNumber = loadPhoneNumber(new_user.value.personal_data.phone_number, new_user.value.personal_data.country_code)

    const form_data = new FormData()

    form_data.append('first_name', new_user.value.personal_data.first_name)
    form_data.append('last_name', new_user.value.personal_data.last_name)
    form_data.append('birthdate', new_user.value.personal_data.birthdate)
    form_data.append('country_code', '+'+formattedNumber.countryCode)
    form_data.append('phone_number', formattedNumber.localNumber)
    form_data.append('gender', new_user.value.personal_data.gender)
    form_data.append('country_id', new_user.value.personal_data.country.id)

    form_data.append('_method', 'PATCH')

    for (let [key, value] of form_data.entries()) {
        console.log(`${key}: ${value}`);
    }

    const response = await axios.post(endpoint, form_data, { headers: headers.value })

    return response
}

const saveProfessionalInfo = async (userId) => {
    let endpoint = {
        create: `${url}${urlUser}/${userId}/professional`,
        edit: `${url}${urlUser}/${userId}/professional/data`
    }

    let formattedNumber = loadPhoneNumber(new_user.value.professional.professional_data.phone_number, new_user.value.professional.professional_data.country_code)

    console.log(formattedNumber)

    const transformedSocialNetworks = {}

    const form_data = new FormData()

    form_data.append('email', new_user.value.professional.professional_data.email)
    form_data.append('country_id', new_user.value.professional.professional_data.country.id)
    form_data.append('state_id', new_user.value.professional.professional_data.state.id)
    form_data.append('city_id', new_user.value.professional.professional_data.city.id)
    form_data.append('address', new_user.value.professional.professional_data.address)
    form_data.append('postal_code', new_user.value.professional.professional_data.postal_code)
    form_data.append('country_code', '+'+formattedNumber.countryCode)
    form_data.append('phone_number', formattedNumber.localNumber)
    form_data.append('slug', new_user.value.professional.professional_data.slug)
    for (var i = 0; i < new_user.value.professional.professional_data.social_networks.length; i++) {
        const network = new_user.value.professional.professional_data.social_networks[i]
        transformedSocialNetworks[network.name.toLowerCase()] = network.url
    }
    form_data.append('social_networks[]', JSON.stringify(transformedSocialNetworks))
    form_data.append('about', new_user.value.professional.professional_data.about)

    if (!new_user.value.professional.id) {
        console.log(new_user.value.professional)
        form_data.append('profession_id', new_user.value.professional.professions_id)
        for (var i = 0; i < new_user.value.professional.specializations_id.length; i++) {
            form_data.append('specialization_ids[]', JSON.stringify(new_user.value.professional.specializations_id[i]))
        }
    }

    if (new_user.value.professional.professional_data.banner && new_user.value.professional.professional_data.banner instanceof File) {
        form_data.append('_method', 'PATCH')
        form_data.append('banner', new_user.value.professional.professional_data.banner)
    }
    else if (state.value == 'e' && new_user.value.professional.id) {
        form_data.append('_method', 'PATCH')
    }

    for (let [key, value] of form_data.entries()) {
        console.log(`${key}: ${value}`);
    }

    const response = await axios.post(state.value == 'c' || !new_user.value.professional.id ? endpoint.create : endpoint.edit, form_data, { headers: headers.value })

    return response
}

const saveProfessionalSpecializations = async (userId) => {
    const form_data = new FormData()

    form_data.append('profession_id', new_user.value.professional.professions_id)
    for (var i = 0; i < new_user.value.professional.specializations_id.length; i++) {
        form_data.append('specialization_ids[]', JSON.stringify(new_user.value.professional.specializations_id[i]));
    }

    const response = await axios.post(`${url}${urlUser}/${userId}/professional/specializations`, form_data, { headers: headers.value })

    return response
}

const save = async () => {
    try {
        resetErrors()
        const response = await saveAccountInfo()
        console.log(response)
        if (response.data.status == 'Success') {
            if (new_user.value.plan.id == 2 || new_user.value.team.id == 4) {
                const professionalResponse = await saveProfessionalInfo(new_user.value.id)
                console.log(professionalResponse)
                const professionsResponse = await saveProfessionalSpecializations(new_user.value.id)
                console.log(professionsResponse)
            }
            fetchUsers()
            page_id.value = 1
            actual_page.value = 1
            state.value = 'c'
            user_id.value = 0
            new_user.value = {
                id: 0,
                personal_data: {
                    first_name: '',
                    last_name: '',
                    country_code: '',
                    phone_number: '',
                    birthdate: '',
                    country: {
                        id: ''
                    },
                    nickname: ''
                },
                email: '',
                plan: {
                    id: ''
                },
                team: {
                    id: ''
                },
                avatar: null,
                password: '',
                status: false,
                professional: {
                    id: '',
                    active: '',
                    professional_data: {
                        id: '',
                        email: '',
                        banner: '',
                        country: {
                            id: ''
                        },
                        state: {
                            id: ''
                        },
                        city: {
                            id: ''
                        },
                        address: '',
                        postal_code: '',
                        country_code: '',
                        phone_number: '',
                        social_networks: [],
                        about: '',
                        slug: ''
                    },
                    professions: [
                        {
                            specializations: []
                        }
                    ],
                    attatchments: {
                        gallery: []
                    },
                    portfolio: null,
                    reviews: null
                }
            }
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: response.data.message
            })
            setTimeout(() => {
                router.push('/usuarios')
            }, 4000)
        }
    } catch (error) {
        handleServerErrors(error.response.data.data)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
        console.log(error)
    }
}

const edit = async () => {
    try {
        resetErrors()
        button_loading.value = true
        const response = await saveAccountInfo()
        console.log(response)
        if (response.data.status == 'Success') {
            const response = await saveBasicInfo()
            console.log(response)
            if (response.data.status == 'Success') {
                if (new_user.value.plan.id == 2 || new_user.value.team.id == 4) {
                    const professionalResponse = await saveProfessionalInfo(new_user.value.id)
                    console.log(professionalResponse)
                    const professionsResponse = await saveProfessionalSpecializations(new_user.value.id)
                    console.log(professionsResponse)
                }
                fetchUsers()
                button_loading.value = false
                const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
                })
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                })
            }
        }
    } catch (error) {
        console.log(error, 'error')
        if(error.response){
            handleServerErrorsProf(error.response.data.data)
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'error',
                title: error.response.data.message
            })
        }
    }
}

const changeUserStatus = async (action) => {
    try {
        const response = await axios.patch(`${url}${urlUser}/${new_user.value.id}/${action}`, null, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: response.data.message
            })
            console.log(response.data)
        }
    } catch (error) {
        console.log(error)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const changeUserProfStatus = async (id) => {
    try {
        const action = {
            1: 'enable',
            2: 'disable',
            3: 'default'
        }
        const response = await axios.patch(`${url}${urlUser}/${new_user.value.id}/professional/${action[id]}`, null, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: response.data.message
            })
        }
    } catch (error) {
        console.log(error)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const getUserSpecializations = async () => {
    try {
        const response = await axios.get(`${url}${urlUser}/${new_user.value.id}/professional/specializations`, {headers: headers.value})
        console.log(response)
        user_specializations_list.value = response.data.data
    } catch (error) {
        console.log(error)
    }
}

const getGallery = async (gallery) => {
    try {
        const response = await axios.get(`${url}${urlUser}/${new_user.value.id}/professional/specializations/${gallery.id}/gallery`, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            actual_gallery.value = response.data.data
            console.log(actual_gallery.value)
        }
    } catch (error) {
        console.log(error)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const createFileGallery = async (files) => {
    console.log(files)
    try {
        const form_data = new FormData()

        for (var i = 0; i < files.length; i++) {
            form_data.append('files[]', files[i])
        }

        const response = await axios.post(`${url}${urlUser}/${new_user.value.id}/professional/specializations/${actual_gallery.value.id}/gallery`, form_data, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            console.log(actual_gallery.value)
            getGallery(actual_gallery.value)
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: response.data.message
            })
        }
    } catch (error) {
        console.log(error)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const deleteImageGallery = async (imageId) => {
    try {
        const response = await axios.delete(`${url}${urlUser}/${new_user.value.id}/professional/specializations/${actual_gallery.value.id}/gallery/${imageId}`, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            actual_gallery.value.gallery = actual_gallery.value.gallery.filter(image => image.id !== imageId);
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: response.data.message
            })
        }
    } catch (error) {
        console.log(error)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const openDeletePopup = (imageId) => {
    image_to_delete.value = imageId
    delete_popup_open.value = true
}

const closeDeletePopup = () => {
    delete_popup_open.value = false
    image_to_delete.value = null
}

const confirmDeleteImage = async () => {
    try {
        deleteImageGallery(image_to_delete.value)
        if (selected_image.value?.id === image_to_delete.value) {
            closeLightbox()
        }
        closeDeletePopup()
    } catch (error) {
        console.error('Error deleting image:', error)
    }
}

const mapTemporaryChanges = (original, temporary) => {
    const changes = []
    
    for (const key in temporary) {
        if (key !== 'id' && temporary[key] !== null) {
            let originalValue = original[key]
            
            if (key === 'country_id') {
                originalValue = original.country.id
            } else if (key === 'state_id') {
                originalValue = original.state.id
            } else if (key === 'city_id') {
                originalValue = original.city.id
            } 
            
            changes.push({
                field: key,
                original: originalValue,
                modified: temporary[key],
                isImage: key === 'banner' || key === 'avatar'
            })
        }
    }

    return changes
}

const mapTemporaryGalleriesChanges = (original_gallery, temporary_gallery) => {
    const galleries_changes = []

    for (let i = 0; i < original_gallery.length; i++) {
        const original = original_gallery[i];
        const temporary = temporary_gallery[i];

        if (temporary && Array.isArray(temporary)) {
            temporary.forEach((new_image, index) => {
                galleries_changes.push({
                    gallery: original_gallery,
                    gallery_index: i,
                    image_index: index,
                    original: original.temporary ? original.temporary[index] : null,
                    modified: new_image
                })
            });
        }
    }

    return galleries_changes;
}

const openApprovePopup = () => {
    approve_popup_open.value = true
}

const closeApprovePopup = () => {
    approve_popup_open.value = false
}

const openRejectPopup = () => {
    reject_popup_open.value = true
}

const closeRejectPopup = () => {
    reject_popup_open.value = false
}

const openDeleteTemporaryImagePopup = (change) => {
    delete_temporary_change.value = {
        spec_id: change.gallery[change.gallery_index].id,
        file_id: change.modified.id
    }
    console.log(delete_temporary_change.value)
    delete_temporary_image_popup_open.value = true
}

const closeDeleteTemporaryImagePopup = () => {
    delete_temporary_change.value = null
    delete_temporary_image_popup_open.value = false
}

const approveChanges = async () => {
    try {
        const response = await axios.patch(`${url}${urlUser}/${new_user.value.id}/professional/publish`, {comment: approve_comment.value}, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            closeApprovePopup()
            reject_comment.value = ''
            temporal.value = false
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: response.data.message
            })
        }
    } catch (error) {
        console.log(error)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const rejectChanges = async () => {
    try {
        const response = await axios.patch(`${url}${urlUser}/${new_user.value.id}/professional/reject`, {comment: reject_comment.value}, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            closeRejectPopup()
            reject_comment.value = ''
            temporal.value = false
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: response.data.message
            })
        }
    } catch (error) {
        console.log(error)
        if(error){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'error',
                title: error.response.data.message
            })
        }
    }
}

const deleteTemporaryImage = async (spec_id, file_id) => {
    try {
        const response = await axios.delete(`${url}${urlUser}/${new_user.value.id}/professional/specializations/${spec_id}/gallery/temporary/${file_id}`, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            closeDeleteTemporaryImagePopup()
            fetchUser(new_user.value.id)
            loading.value = true
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: response.data.message
            })
        }
    } catch (error) {
        console.log(error)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

onBeforeMount(() => {
    fetchTeams()
    fetchPlans()
    fetchProfessions()
    fetchCountries()
    watch(selected_image, () => {
        if (lightbox_open.value) {
            nextTick(() => scrollToSelectedThumbnail())
        }
    })
    watch([day, month, year], () => {
        if (isValidDate.value) {
            new_user.value.personal_data.birthdate = `${year.value.padStart(4, '0')}-${month.value.padStart(2, '0')}-${day.value.padStart(2, '0')}`
        } else {
            new_user.value.personal_data.birthdate = ''
        }
    })
    watch(state, async (newVal, oldVal) => {
        if (state.value == 'e') {
            loading.value = true;
            await fetchUser(user_id.value)
            getUserSpecializations()
        }
        if (state.value == 'c') {
            day.value = ''
            month.value = ''
            year.value = ''
        }
    }, {deep: true})
})
</script>

<style lang="scss" scoped>
.contenedor{
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 40px;
    min-height: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    @media (min-width: 992px) {
        border-radius: 50px;
        padding: 22px 15px;
    }
    @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
    } 
    @media (max-width: 992px) {
        max-width: 95vw;
    }
    .addReferrals{
        input{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
        
        textarea{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            height: 150px;
            padding-top: 5px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
        .label{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            height: 100px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            cursor: pointer;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
        .blob {
            img:not(.button-close-image){
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
        }
        span{
            color: #FF576E;
            padding-top: 5px;
        }
        .user{
            p{
                display: flex;
                align-self: start;
                color: #FF576E;
                padding-top: 5px;
            }
        }
        .password{
            p{
                display: flex;
                align-self: start;
                padding-top: 7px;
            }
            button{
                position: relative;
                right: -89%;
                top: -32%;
                padding: 0;
            }
            .ojo{
                background-image: url('../../../img/roles-icons/ojo.svg');
                background-repeat: no-repeat;
                display: block;
                min-height: 20px !important;
                min-width: 20px !important;
            }
            .weak{
                color: #FF576E;
                padding-right: 5px;
            }
            .moderate{
                color: #FEB230;
                padding-right: 5px;
            }
            .strong{
                color: #38E6A7;
                padding-right: 5px;
            }
        }
        p{
            display: flex;
            color: var(--grisPrimario);
            padding-top: 5px;
        }
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            padding: 7px 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h5{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 16px;
            text-align: left;
            padding-top: 30px;
        }
        .rol{
            max-width: 57px;
            max-height: 57px;
            min-width: 57px;
            min-height: 57px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                max-width: 100%;
                object-fit: cover;
                border-radius: 50%;
                border: 2px solid var(--colorPrimary);
            }
        }
        .position{
            width: 100%;
            display: flex;
            justify-content: end;
            align-items: center;
            position: relative;
            i{
                width: 10px;
                height: 15px;
                background-image: url('../../../img/subir-productos-icons/cursos-icons/arrow-abajo-gris.svg');
                background-repeat: no-repeat;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                right: 5%;
                top: 30%;
                transform: rotate(-90deg);
                transition: all ease 0.2s;
            }
        }
        select{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: #FCFCFC;
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            -webkit-appearance: none;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
            &:focus + i{
                transform: rotate(90deg);
                transition: all ease 0.2s;
            }
        }
        .profile{
            max-width: 47px;
            max-height: 47px;
            min-width: 47px;
            min-height: 47px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            img:not(.button-close-image){
                max-width: 47px;
                max-height: 47px;
                min-width: 47px;
                min-height: 47px;
                object-fit: cover;
                border-radius: 50%;
                // border: 2px solid #E9E3E3;
                // padding: 1px;
            }
        }
        .profile-plus{
            max-width: 47px;
            max-height: 47px;
            min-width: 47px;
            min-height: 47px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px dashed #00000029;
            box-shadow: 0px 3px 5px #c7c7c7;
        }
        .pointer{
            cursor: pointer;
        }
        .solid{
            border: 1px solid #00000029;
        }
        .selected{
            border: 1px solid var(--colorPrimary);
        }
        .margin{
            margin-top: 3px;
        }
        .padding-left{
            padding-left: 10px;
        }
        .switch {
            label{
                height: 40px;
                width: 100%;
                box-shadow: 0px 0px 6px #00000029;
                border-radius: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;
                background-color: #EBEEF9;
                &::before{
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #38E6A7;
                    border-radius:  28px;
                    left: 0;
                    transition: all 0.5s;
                }
                p{
                    font-weight: 500;
                    font-size: 14px;
                    padding-top: 0px;
                    color: var(--bgPrincipal);
                    z-index: 1;
                    transition: all 0.5s;
                    &:first-child{
                        margin-right: 0%;
                    }
                    &:last-child{
                        margin-left: 0%;
                    }
                }
                .left{
                    color: var(--bgPrincipal);
                    font-weight: 600;
                }
                .right{
                    color: var(--colorSecondary);
                }
            }
            input{
                display: none;
            }
            input:checked + label{
                &::before{
                    left: 50%;
                    width: 50%;
                    background-color: #FEB230;
                }
                p{
                    color: var(--bgPrincipal);
                    font-weight: 600;
                    
                }
                .left{
                    color: var(--colorSecondary);
                    font-weight: 500;
                }
            }
            @media (min-width: 992px) {
                label{
                    .right{
                        color: var(--colorSecondary);
                    }
                }
                input:checked + label{
                    .left{
                        color: var(--colorSecondary);
                    }
                }
            }
        }
        .button-close{
            background-color: var(--bgPrincipal);
            border: 0;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            margin-left: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
        }
        .button-close-banner{
            background-color: var(--bgPrincipal);
            border: 0;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 90%;
            top: 14%;
            @media (min-width: 992px){
                left: 95%;
            }
        }
        .input-width{
            max-width: 47px;
            max-height: 47px;
        }
        .gender-inputs{
            .inputs{
                margin: 10px 0px !important;
            }
            input{
                display: none;
            }
            label{
                font-size: 16px;
                color: #697891;
                font-weight: 400;
                border: 2px solid #E9E3E3;
                border-radius: 10px;
                height: 50px;
                width: 32%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                i{
                    position: relative;
                    background-repeat: no-repeat;
                    background-size: cover;
                    right: 0;
                    top: 0;
                    width: 26px;
                    height: 26px;
                    display: block;
                    transform: rotate(0deg);
                    margin-right: 10px;
                }
                .hombre{
                    width: 26px;
                    height: 26px;
                    background-image: url('../../../img/panel-icons/hombre.svg');
                }
                .mujer{
                    width: 26px;
                    height: 26px;
                    margin: 0 0;
                    background-image: url('../../../img/panel-icons/mujer.svg');
                }
                .ambos{
                    width: 25px;
                    height: 26px;
                    background-image: url('../../../img/panel-icons/ambos.svg');
                }
            }
            label:hover {
                cursor: pointer;
            }
            input:checked + label{
                border-color: var(--colorPrimary);
                color: var(--colorPrimary);
                p{
                    color: var(--colorPrimary);
                }
                &::before{
                    content: '';
                    position: absolute;
                    top: -10px;
                    right: -7px;
                    background-image: url('../../../img/panel-icons/input-valido.svg');
                    background-repeat: no-repeat;
                    z-index: 1;
                    display: block;
                    width: 25px;
                    height: 25px;
                }
                .hombre{
                    background-image: url('../../../img/panel-icons/hombre-active.svg');
                }
                .mujer{
                    background-image: url('../../../img/panel-icons/mujer-active.svg');
                }
                .ambos{
                    background-image: url('../../../img/panel-icons/ambos-active.svg');
                }
            }
        }
    }  
    .loader{
        background-color: alpha($color: #ffffff21);
        filter: blur(3px);
        width: 100%;
        height: 100%;
    }
    .pa-users-tabs{
        .tab-element{
            p{
                font-weight: 500;
                font-size: 16px;
                color: #B8C1DB;
                white-space: nowrap;
                overflow: visible;
            }
            cursor: pointer;
        }
        .active{
            p{
                font-weight: 700;
                border-color: transparent;
                color: var(--colorPrimary);
                background-color: transparent;
                border-radius: 0;
                &::before{
                    content: '';
                    display: flex;
                    position: relative;
                    width: 100%;
                    height: 5px;
                    top: 41px;
                    left: 0;
                    background-color: var(--colorPrimary);
                    border-radius: 10px;
                }
            }
        }
    } 
}
.btn__inicio{
    border: 2px solid #B8C1DB;
    color: #B8C1DB;
    background-color: var(--bgPrincipal);
    width: 100%;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 27px;
    font-size: 16px;
    font-weight: 600;
    &:hover, &:focus{
        background-color: #B8C1DB;
        color: var(--colorBTNPrimario);
        border-color: transparent;
    }
}
.btn_save{
    background-color: #B8C1DB;
    color: var(--colorBTNPrimario);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 27px;
    width: 100%;
    text-decoration: none;
    min-height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    cursor: default !important;
}
.btn_save_no-w{
    background-color: #B8C1DB;
    color: var(--colorBTNPrimario);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 27px;
    text-decoration: none;
    min-height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    cursor: default !important;
}
.btn-valid{
    background-color: var(--colorPrimary);
    color: var(--colorBTNPrimario);
    border-color: transparent;
    cursor: pointer !important;
    &:hover{
        background-color: transparent;
        color: var(--colorPrimary);
        border: 2px solid var(--colorPrimary);
    }
}
.btn-error{
    background-color: var(--colorRed);
    color: var(--colorBTNPrimario);
    border-color: transparent;
    cursor: pointer !important;
    &:hover{
        background-color: transparent;
        color: var(--colorRed);
        border: 2px solid var(--colorRed);
    }
}
.border-top{
    border-top: 2px solid #E4E4E4;
    padding-top: 20px;
}

.no-resize {
    resize: none;
    width: 100%;
    height: 100px;
}

.inactive {
    color: #E9E3E3 !important;
    text-decoration: line-through;
    cursor: default !important;
    &:hover {
        border-color: #E9E3E3 !important;
    }
    h3 {
        color: #E9E3E3 !important;
    }
}

.gallery {
    flex-wrap: wrap;
    box-sizing: border-box;
    border: 2px solid #E9E3E3;
    border-radius: 15px;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    &:hover {
        border-color: var(--colorPrimary);
    }
    h3 {
        padding-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.gallery-show {
    h2 {
        font-weight: 600;
        font-size: 24px;
    }
}

.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ECECEC;
    border-radius: 15px;
}

.thumbnail-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    @media(min-width: 992px){
        gap: 8px;
    }
}

.thumbnail-wrapper {
    position: relative;
}

.thumbnail {
    width: 90px;
    height: 90px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    @media(min-width: 992px){
        width: 100px;
        height: 100px;
    }
}

.thumbnail-new {
    border: 2px dotted #E9E3E3;
    padding-right: 10px;
    object-fit: contain;
}

.thumbnail:hover {
    transform: scale(1.1);
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 0, 0, 0.8);
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: none;
    justify-content: center;
    align-items: center;
}

.thumbnail-wrapper:hover .delete-button {
    display: flex;
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}

.lightbox-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10001;
}

.expanded-image {
    max-width: 90%;
    max-height: 70vh;
    margin-bottom: 20px;
    border-radius: 15px;
}

.lightbox-thumbnails {
    position: fixed;
    bottom: 10px;
    width: 100%;
    display: flex;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 10002;
}

.lightbox-thumbnail {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    border: 2px solid transparent;
}

.lightbox-thumbnail .delete-button {
    display: none;
}

.lightbox-thumbnail:hover {
    transform: scale(1.1);
}

.lightbox-thumbnail.selected {
    border-color: var(--colorPrimary);
}

.delete-popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10003;
}

.delete-popup {
    background: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.delete-popup p {
    margin-bottom: 20px;
}

.delete-popup button {
    margin: 5px;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.delete-popup button:first-of-type {
    background: red;
    color: white;
}

.delete-popup button:last-of-type {
    background: grey;
    color: white;
}

.navigation {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    font-size: 2rem;
    color: white !important;
    cursor: pointer;
    user-select: none;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 10%;
    z-index: 10002;
    &:hover {
        color: var(--colorPrimary) !important;
    }
}

.left-arrow {
    left: 0;
}

.right-arrow {
    right: 0;
}

.estado-switch {
    display: flex;
    justify-content: space-between;
    button {
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 25px;
        background-color: var(--bgPrincipal);
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        padding: 10px 15px;
        margin: 0 5px;
        cursor: pointer;
        &:focus, &:hover{
            border-color: var(--colorPrimary);
        }
        &.active {
            background-color: var(--colorPrimary);
            border-color: var(--colorPrimary);
            color: white;
        }
    }
}

.m-phone-number-input{
    height: max-content;
}

.approvals {
    .scroll {
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        max-width: 100vw;
        border: 1px solid #ccc; 
        border-radius: 12px; 
        padding: 0 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
        
        .row_scroll {
            min-width: max-content;
            border-bottom: 1px solid #e0e0e0; // Borde inferior para las filas
            &:last-child {
                border-bottom: none; // Eliminar el borde de la última fila
            }

            .col-3 {
                padding: 10px 15px;
                color: var(--grisPrimario); 
                font-size: 16px; 
                font-weight: 500; 
                text-align: left;
                border-left: 1px solid #ddd; // Borde izquierdo
                min-width: 200px;
                &:first-child {
                    border-left: none; // Eliminar borde izquierdo de la primera columna
                }
                &:last-child {
                    border-right: 1px solid #ddd; // Borde derecho
                }
                @media(min-width: 1200px){
                    min-width: auto;
                }
            }

            .image-preview {
                width: 100%; 
                max-width: 150px; 
                height: 150px; 
                object-fit: cover;
                border-radius: 10px;
            }

            &:nth-child(even) {
                background-color: #fafafa; // Color de fondo para filas pares
            }

            &:hover {
                background-color: #e0e0e0; // Color de fondo al hacer hover
            }

            &:last-child {
                .col-3:first-child {
                    border-bottom-left-radius: 10px; // Bordes redondeados en la esquina inferior izquierda
                }
                .col-3:last-child {
                    border-bottom-right-radius: 10px; // Bordes redondeados en la esquina inferior derecha
                }
            }
        }
        .row_color{
            background-color: #b9bfc9;
            p{
                color: #fff;
            }
        }
        &::-webkit-scrollbar {
            height: 6px;
            background-color: rgba(219, 219, 219, 0.25);
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #DBDBDB;
            border-radius: 20px;
        }
        @media(min-width: 1200px){
            overflow: hidden;
        }
    }
}

.tablas{  
    background-color: transparent;
    min-height: 56px;
    margin-bottom: 10px;
    // border-bottom: 1px solid #70707023;
    // box-shadow: 0px 3px 6px #00000016;
    // border-radius: 10px;
    .scroll{
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        max-width: 96vw;
        .row_scroll{
            min-width: max-content;
            button{
                min-width: 5vw;
            }
        }
        @media (min-width: 1300px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            .row_scroll{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
        &::-webkit-scrollbar{
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
            background-color: #272d3b0c;
            border-radius: 100px;
            border: 0;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #272d3b27;
            border-radius: 100px;
        }
    }
    @media (max-width: 991px) {
        border-radius: 0px;
        margin-bottom: 10px;
    }
    
}

.row_banner{
    height: 100px;
    @media (min-width: 992px){
        height: 140px;
    }
}

</style>