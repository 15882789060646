<template>
    <div class="col-12 col-lg ms-lg-3 mt-lg-3 d-flex justify-content-center align-items-center align-items-lg-start p-lg-3 caja">
        <div class="row justify-content-center justify-content-lg-start w-100" v-if="modZero">
            <div class="col-12 botones d-flex justify-content-center align-items-center px-0 d-lg-none">
                <div class="row justify-content-end row__width">
                    <div class="col-6 d-flex justify-content-center align-items-center px-0">
                        <button type="button" @click="previousLesson" :class="actualLessonId == 0 ? 'disabled' : ''">
                            <span><img src="../../img/viendo-icons/arrow-right-negra.svg" alt=""></span>
                            {{ $t('message.previous') }}
                        </button>
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-center px-0">
                        <button type="button" class="siguiente" @click="nextLesson" :class="nextBlock ? 'disabled' : ''">
                            {{ $t('message.continueV2') }}
                            <span><img src="../../img/viendo-icons/arrow-left-azul.svg" alt=""></span>
                        </button>    
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-start align-items-center d-none d-lg-flex titulo px-0 pb-3" v-if="quiz">
                <h2>{{ response.name }}</h2>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 video" v-if="response.intro.information">
                <template v-if="response.intro.information.videoType == 1">
                    <vue-plyr ref="player" @timeupdate="videoTimeUpdated" v-if="response.intro.information.videoType == 1"
                        :resetOnEnd="false" :options="{ youtube: { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 } }">
                        <div class="plyr__video-embed">
                            <iframe
                            :src="response.intro.video+'?rel=0&autoplay=0&cc_load_policy=1&controls=1&disablekb=1&fs=0&hl=es&iv_load_policy=3&loop=0&modestbranding=1&mute=1&playsinline=1'"
                            allowfullscreen
                            allowtransparency
                            ></iframe>
                        </div>
                    </vue-plyr>
                    <div class="div" v-else>
                        No hay vídeo
                    </div>    
                </template>
                <template v-else>
                    <vue-plyr ref="player" @timeupdate="videoTimeUpdated" v-if="response.intro.video" :resetOnEnd="false">
                        <div class="plyr__video-embed">
                            <iframe
                            :src="response.intro.video+'?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media'"
                            allowfullscreen
                            allowtransparency
                            ></iframe>
                        </div>
                    </vue-plyr>
                    <div class="div" v-else>
                        No hay vídeo
                    </div>
                </template>
                
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0 border-bottom-viendo d-lg-none" v-if="quiz">
                <h6><img src="../../img/viendo-icons/unidad-finalizada.svg" alt=""> {{ $t('message.finishedUnit') }}</h6>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0 tablas" v-if="quiz">
                <div class="row justify-content-center justify-content-lg-start row__width">
                    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center mt-2 px-0">
                        <div class="scroll d-flex justify-content-start align-items-center">
                            <ul class="nav nav-tabs row justify-content-center " id="myTab2" role="tablist">
                                <li class="nav-item col d-flex justify-content-center align-items-center d-lg-none" role="presentation" >
                                    <button class="nav-link" :class="quiz == false ? 'active' : ''" id="modulos-tab" data-bs-toggle="tab" data-bs-target="#modulos" type="button" role="tab" aria-controls="modulos" :aria-selected="quiz == true ? true : false ">{{ $t('message.modules') }}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                        <div class="tab-content row justify-content-center row__width" id="myTab2">
                            <div class="col-12 tab-pane fade" :class="quiz == false ? 'show active d-lg-none' : ''" id="modulos" role="tabpanel" aria-labelledby="modulos-tab" >
                                <div class="row justify-content-center justify-content-lg-start mt-4 modulosTab">
                                    <div class="col-12 d-flex justify-content-center align-items-center py-3 px-0">
                                        <div class="row justify-content-center row__width px-3">
                                            <div class="col-9 d-flex flex-column justify-content-center align-items-start">
                                                <h5>{{ $t('message.modules') }} 1/{{ content.length }}</h5>
                                                <h4>1 de {{ content.length }} modulos terminados</h4>
                                            </div>
                                            <div class="col-3 d-flex justify-content-center align-items-center px-0 progreso">
                                                <div class="row justify-content-center row__width">
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                        <div class="gris">
                                                            <div class="blanco">
                                                                <h6>5%</h6>
                                                            </div>
                                                            <div class="azul"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ModulosNavMobile/>
                                </div>
                            </div>
                            <div class="col-12 tab-pane fade" :class="quiz == true ? 'show active' : ''" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="row justify-content-center mt-4">
                                    <Modulos/>
                                </div>
                                <div class="row justify-content-center justify-content-lg-start">
                                    <div class="col-12 col-lg-9 d-flex justify-content-center align-items-center">
                                        <div class="row justify-content-center">
                                            <TituloHomeCursos tituloCurso="Lo que aprenderás" :tituloMediano="true" class=""/>
                                            <DescripcionCursoTexto class="mb-3"/>
                                            <!-- <QueIncluye v-if="response.values"/> -->
                                            <div class="col-12  d-flex justify-content-center align-items-center px-0 profesor">
                                                <div class="row justify-content-center row__width">
                                                    <div class="col-3 col-lg-1 d-flex justify-content-center align-items-center px-2 max-width">
                                                        <div class="contenedor">
                                                            <img :src="response.owner.image" alt="" class="perfil">
                                                            <!-- <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="verificado d-lg-none"> -->
                                                        </div>
                                                    </div>
                                                    <div class="col col-lg-8 d-flex justify-content-start align-items-center">
                                                        <div class="row justify-content-center">
                                                            <div class="col-12 d-flex justify-content-start align-items-center py-1">
                                                                <h2 class="ms-2">{{ response.owner.name }}</h2><h4>{{ $t('message.instructor') }}</h4>
                                                                <!-- <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="ms-2"> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center mt-4 mt-lg-0">
                                                        <router-link :to="{name: 'Profesional', params: {slug: response.owner.slug}}" class="btn__primario">
                                                            {{$t('message.viewProfile')}}
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-11 col-lg d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 caja">
                <div class="row justify-content-center row__width">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import TituloHomeCursos from "../../components/Titulo/TituloHomeCursos.vue";
import Primario from "../../components/Botones/Primario.vue";
import DescripcionCursoTexto from "../../components/Viendo/DescripcionCursoTexto.vue";
import Modulos from "../../components/Viendo/Modulos.vue";
import EditarViendo from "../../components/Botones/EditarViendo.vue";
import ModulosNavMobile from "../../components/Viendo/ModulosNavMobile.vue";
import QueIncluye from "../../components/Cursos/QueIncluye.vue";
import { useGeneralStore } from "../../stores/general"
import { ref, inject, onMounted, computed, onBeforeMount, watch, getCurrentInstance } from 'vue';
// Importo mi método
import { storeToRefs } from "pinia";
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../stores/user";
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';


const route = useRoute();
const router = useRouter();


const url = process.env.VUE_APP_BASE_URL
const urlProgressLesson = '/api/product/viewing/lesson/' //lId
const urlLesson = '/api/product/course/modules/lesson/' //lID



const response = inject('response')
const content = inject('content')
const modules = inject('modules')
let modZero = inject('modZero')
let actualLessonId = inject('actualLessonId')
let nextPageName = inject('nextPageName')
let lessonIds = inject('lessonIds')
let success = inject('success')
let nextBlock = inject('nextBlock')


const quiz = ref(true)
const player = ref(null);
const currentTime = ref(null);
const duration = ref(null);
const currentTimeFormated = ref(null)
const durationFormated = ref(null)


const progressLesson = ref({
    user_id: null,
    percent: null
})

const setModule = (x) =>{
    modules.value = x
}

const formatTime = (timeInSeconds) => {
  
  return new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
};

const videoTimeUpdated = (event) => {
    currentTime.value = event.detail.plyr.currentTime;
    duration.value = event.detail.plyr.duration;

    if (currentTime.value) {
        currentTimeFormated.value = formatTime(currentTime.value);
    }
    if (duration.value) {
        durationFormated.value = formatTime(duration.value);
    }
};

const goToLesson = (id) => {
    console.log(id)
    actualLessonId.value = id.id
}

const previousLesson = () => {
    nextBlock.value = false
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    if(success.value == true){
        goToLesson(lessonIds.value[lessonIds.value.length - 1])
        success.value = false
    } else if (index > 0) {
        if(index - 1 == 0){
            modules.value = 0
        }
        goToLesson(lessonIds.value[index - 1])
    }
}

const nextLesson = () => {
    console.log(lessonIds.value)
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    console.log(index)
    nextBlock.value = false
    success.value = false
    if (index < lessonIds.value.length - 1) {
        goToLesson(lessonIds.value[index + 1])
    } else if (index === lessonIds.value.length - 1) {
        success.value = true
        nextBlock.value = true
        actualLessonId.value = -1
    }
    if(index + 1 <= lessonIds.value.length - 1 && lessonIds.value[index + 1].isBlocked){
        console.log("BLOQUEO")
        nextBlock.value = true
    }
}

const getNextLessonTitle = () => {
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    console.log(index)
    if (index < lessonIds.value.length - 1 && index >= 0) {
        nextPageName.value = lessonIds.value[index + 1].title
    } else if (index == -1 || index >= lessonIds.value.length - 1) {
        nextPageName.value = 'Finalizar curso'
    }
}
onMounted(() =>{
    getNextLessonTitle()
    console.log(lessonIds.value)
})
watch(lessonIds, () => {
    // console.log("Watch - lessonIds")
    getNextLessonTitle()
})
watch(actualLessonId, (newValue, oldValue) => {
    // console.log("Watch - actualLessonId")
    getNextLessonTitle()
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    console.log(index, oldValue)
    if(index + 1 <= lessonIds.value.length - 1 && lessonIds.value[index + 1].isBlocked){
        success.value = false
        nextBlock.value = true
    } else if(index == -1 && oldValue){
        // success.value = true
        nextBlock.value = true
    } else {
        success.value = false
        nextBlock.value = false
    }
})

computed(()=>{
    if(currentTimeFormated.value === durationFormated.value){
        alert('Has completado esta lección!')
    }
})

const sentProgress = async(x) =>{
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    progressLesson.value.user_id = JSON.parse(localStorage.getItem('idData'))
    progressLesson.value.percent = x
    try {
        await axios.post(url+urlProgressLesson+actualLessonId.value, progressLesson.value, {headers: headers.value}).then((result) => {
            console.log(result)
            setModule(1)
        })
    } catch (error) {
        console.error(error)
    }
}
const currentPage = async() =>{
    //console.log(current, 'current')
    if(actualLessonId.value == 0){
        modules.value = 0
        //console.log(modules.value, 'modulo value 1')
    }else {
        modules.value = actualLessonId.value
        //console.log(modules.value , 'modulo value 2')
    }
    
}
currentPage()

watch(
  () => actualLessonId.value,
  () => {
    //console.log(actualLessonId.value)
    currentPage()
  }
)
</script>

<style lang="scss" scoped>
#app, .container-fluid{
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.caja{
    transition: width 0.3s ease-in-out !important;
    @media (max-width: 991px) {
        padding: 0;
    }
    @media (min-width: 992px) {
        position: relative;
        padding: 20px 30px;
    }
}
.padding{
    padding: 30px 0 15px 0;
}
.show + .caja{
    @media (min-width: 992px) {
        transition: width 0.3s ease-in-out !important;
    }
}
.botones{
    span{
        background-color: var(--bgPrincipal);
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #F5F5F5;
        border-radius: 21px;
        width: 42px;
        height: 42px;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    button{
        border: 0;
        background-color: transparent;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        color: #697891;
        padding: 10px 20% 10px 0;
        border-bottom: 1px solid #E4E4E4;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .siguiente{
        font-weight: 600;
        padding: 10px 0 10px 20%; 
        border-left: 1px solid #E4E4E4;
    }
}
.border-bottom-viendo{
    border-bottom: 1px solid #E4E4E4;
    height: 80px;
}
.video{
    video{
        width: 100%;
    }
    max-width: 1100px;
}
.titulo{
    h2{
        font-weight: 700;
        font-size: 25px;
        color: var(--colorSecondary);
        text-align: left;
    }
    input{
        display: none;
    }
    input + label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        font-weight: 500;
        font-size: 14px;
        color: var(--colorSecondary);
        text-align: left;
        @media (min-width: 1600px) {
            font-size: 16px;
        }
        &::before{
            content: '';
            position: absolute;
            border: 2px solid #E4E4E4;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    input:checked + label::before{
        background-image: url('../../img/viendo-icons/unidad-finalizada.svg');
        background-size: cover;
    }
}
.tablas{
    h6{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
        padding: 2vh 0 6vh 0;
    }
    .tab-pane{
        padding: 0;
    }
    .nav-link{
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        position: relative;
        padding: 10px 0;
        border: 0;
    }
    .nav-link.active{
        font-weight: 700;
        border: 0;
        color: var(--colorSecondary);
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 0;
            left: 0;
            background-color: var(--colorPrimary);
            
        }
        @media (min-width: 992px) {
            &::before{
                height: 4px;
            }   
        }
    }
    .scroll{
        overflow-x: scroll;
        max-width: 100%;
        padding: 0 15px;
        ul{
            min-width: max-content;
            li{
                min-width: 150px;
                padding: 0;
            }
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
                li{
                    min-width: max-content;
                }
            }
        }
    }
    .texto_mas{
        p{
            font-weight: 400;
            font-size: 14px;
            color: var(--colorSecondary);
            text-align: left;
        }
    }
    .profesor{ 
        padding: 20px 5px 20px 15px;
        margin: 20px 0px;
        h2{
            font-weight: 700;
            font-size: 16px;
            color: var(--colorSecondary);
            text-align: left;
            @media (min-width: 425px) {
                font-size: 18px;
            }
        }
        h3{
            font-weight: 400;
            font-size: 16px;
            color: var(--colorSecondary);
            padding: 0;
            text-align: left;
        }
        h4{
            font-weight: 700;
            font-size: 16px;
            color: var(--colorSecondary);
            padding: 0;
            text-align: left;
            padding-left: 5px;
            @media (min-width: 425px) {
                font-size: 18px;
            }
        }
        .perfil{
            height: 80px;
            width: 80px;
            border-radius: 50%;
            object-fit: cover;
            @media (min-width: 992px) {
                height: 72px;
                width: 72px;
            }
        }
        .max-width{
            max-width: 80px;
        }
        .contenedor{
            position: relative;
            .verificado{
                position: absolute;
                bottom: 0;
                right: 0;
                height: 27px;
            }
        }
    }
    .apuntes{
        span{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            color: var(--bgPrincipal);
            font-weight: 600;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .texto{
            max-width: 100%;
            overflow: hidden;
        }
        h3{
            font-weight: 700;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            white-space: pre;
            width: 100%;
        }
        .cortado{
            font-weight: 400;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            padding-left: 5px;
        }
        .button1{
            border: 0;
            background-color: transparent;
            outline: 0;
            &:focus{
                outline: 0;
            }
        }
        .contenedor{
            border: 2px solid #E4E4E4;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            min-height: 50px;
            margin-bottom: 10px;
            padding: 0 10px;
            input{
                border: 0;
                outline: 0;
                background-color: transparent;
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                height: 100%;
                width: 95%;
                &:focus{
                    outline: 0;
                }
            }
            .button{
                border: 0;
                outline: 0;
                font-size: 20px;
                font-weight: 600;
                color: #697891;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                &:focus{
                    outline: 0;
                }
            }
            a{
                font-weight: 600;
                font-size: 16px;
                color: var(--colorSecondary);
            }
        }
        .ejemplo{
            border-radius: 10px;
            background-color: #E4E4E4;
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            color: #697891;
            border: 0;
            width: 100%;
            margin-bottom: 10px;
            padding-left: 15px;
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                background-color: #F5F5F5;
            }
        }
    }
    .colaboradores{
        box-shadow: 0px 7px 6px #00000029;
        background-color: #FFFFFF;
        height: 106px;
        border-bottom: 1px solid #00000029;
        &:first-child{
            margin-top: 20px;
        }
        .perfil{
            height: 56px;
            width: 56px;
            border-radius: 50%;
            object-fit: cover;
        }
        h3{
            font-weight: 600;
            font-size: 16px;
            color: #374557;
            text-align: left;
        }
        h4{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            text-align: left;
            padding: 5px 0;
        }
        .comentar{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            text-align: left;
        }
        i{
            width: 25px;
            height: 20px;
            background-repeat: no-repeat;
            display: block;
        }
        .like{
            background-image: url('../../img/viendo-icons/like.svg');
        }
        .dislike{
            background-image: url('../../img/viendo-icons/dislike.svg');
        }
        .btn-none{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .max-widthButtons{
            max-width: 40px;
            &:focus, &:hover{
                .like{
                    background-image: url('../../img/viendo-icons/like-a.svg');
                }
                .dislike{
                    background-image: url('../../img/viendo-icons/dislike-a.svg');
                }
            }
        }
        .activeButton{
            .like{
                background-image: url('../../img/viendo-icons/like-a.svg');
            }
            .dislike{
                background-image: url('../../img/viendo-icons/dislike-a.svg');
            }
        }
    }
    .descargas{
        height: 50px;
        &:first-child{
            margin-top: 20px;
        }
        .perfil{
            max-width: 40px;
            padding: 0;
            img{
                max-width: 100%;
                max-height: 25px;
            }
        }
        h3{
            font-weight: 700;
            font-size: 14px;
            color: #0E1133;
            text-align: left;
        }
        h4{
            font-weight: 400;
            font-size: 14px;
            color: #B8C1DB;
            text-align: left;
            padding: 5px 0;
        }
        .comentar{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            text-align: left;
        }
        i{
            width: 25px;
            height: 20px;
            background-repeat: no-repeat;
            display: block;
        }
        .btn-none{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .recursos{
        h6{
            font-weight: 700;
            font-size: 22px;
            padding:10px 0 20px 0;
            color: var(--colorSecondary);
        }
    }
    .modulosTab{
        h5{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 25px;
            padding-bottom: 10px;
        }
        h4{
            font-weight: 400;
            font-size: 16px;
            color: #697891;
            text-align: left;
        }
        .progreso{
            .gris{
                border-radius: 50%;
                width: 56px;
                height: 56px;
                position: relative;
                background-color: #E3E3E3;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                .blanco{
                    background-color: #fff;
                    height: 48px;
                    width: 48px;
                    border-radius: 50%;
                    display: flex;       
                    justify-content: center;
                    align-items: center; 
                    z-index: 1;
                    h6{
                        font-weight: 600;
                        font-size: 18px;
                        color: var(--colorPrimary);
                        padding: 0;
                    }
                }
                .azul{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: var(--colorPrimary);
                    width: 20%;
                    height: 110%;
                    max-height: 103%;
                    border-radius: 35%;
                    transform: rotate(0);
                    transition: all 1s ease-out;;
                }
            }
        }
    }
}
.quizSeccion{
    padding: 20px 0;
    .titulo{
        h2{
            font-weight: 700;
            font-size: 25px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h3{
            font-weight: 600;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            padding-top: 5px;
        }
        h4{
            font-weight: 600;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
            padding: 15px 0;
        }
        h5{
            font-weight: 400;
            font-size: 14px;
            color: var(--colorSecondary);
            text-align: left;
            line-height: 22px;
        }
        
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-left: 30px;
            font-weight: 500;
            font-size: 14px;
            color: var(--colorSecondary);
            text-align: left;
            @media (min-width: 1600px) {
                font-size: 16px;
            }
            &::before{
                content: '';
                position: absolute;
                border: 2px solid #E4E4E4;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                left: 24%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/unidad-finalizada.svg');
            background-size: cover;
        }
        
    }
    .border_bottom{
            border-bottom: 1px solid #E4E4E4;
        }
    .checks1{
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border: 2px solid #E9E3E3;
            border-radius: 5px;
            padding: 10px;
            margin: 10px 0 15px 0;
            height: 50px;
            .span{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 2px solid #E4E4E4;
                width: 21px;
                height: 21px;
                left: 0;
                &::after{
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                    background-color: var(--colorPrimary);
                    width: 11px;
                    height: 11px;
                    display: none;
                }
            }
            h5{
                color: #697891;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                padding-left: 15px;
            }
        }
        input:checked + label .span{
            border-color: var(--colorPrimary);
            &::after{
                display: block;
            }
        }
    }
    .checks2{
        border: 2px solid #E9E3E3;
        border-radius: 5px;
        background-color: transparent;
        height: auto;
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0;
            h5{
                color: #697891;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                padding-left: 35px;
            }
            &:before{
                width: 21px;
                height: 21px;
                border: 2px solid #E4E4E4;
                border-radius: 5px;
                content: '';
                position: absolute;
                border-radius: 8px;
                left: 0;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/tick-azul.svg');
            background-color: var(--colorPrimary);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    .checks3{
        border: 2px solid #E9E3E3;
        border-radius: 5px;
        background-color: transparent;
        height: 200px;
        .scroll{
            max-width: 100%;
            overflow: scroll;
            overflow-y: hidden;
        }
        .margin{
            margin-right: 15px;
            max-width: max-content;
        }
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 150px;
            width: 240px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
            }
            &::after{
                content: '';
                position: absolute;
                background-color: #0D0505;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0.16;
            }
            &::before{
                width: 21px;
                height: 21px;
                border-radius: 50%;
                content: '';
                position: absolute;
                right: 15px;
                bottom: 15px;
                z-index: 1;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/tick-azul.svg');
            background-color: var(--colorPrimary);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        input:checked + label{
            border: 2px solid var(--colorPrimary);
            border-radius: 5px;
            box-shadow: 0px 3px 6px #00000029;
        }
    }
    h6{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
        line-height: 24px;
    }
    .validar{
        input{
            display: none;
        }
        .checkNombre{
            input + label{
                width: 100%;
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 0;
                h5{
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    text-align: left;
                    padding-left: 30px;
                }
                &:before{
                    width: 21px;
                    height: 21px;
                    border: 2px solid #E4E4E4;
                    border-radius: 5px;
                    content: '';
                    position: absolute;
                    border-radius: 8px;
                    left: 0;
                }
            }
            input:checked + label::before{
                background-image: url('../../img/viendo-icons/tick-azul.svg');
                background-color: var(--colorPrimary);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            } 
        }
        
    }
    .checkUnidad{
        padding: 40px 0;
        border-top: 1px solid #E4E4E4;
        border-bottom: 1px solid #E4E4E4;
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            h5{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 600;
                text-align: left;
                padding-left: 30px;
            }
            &:before{
                width: 30px;
                height: 30px;
                border: 2px solid #E4E4E4;
                border-radius: 50%;
                content: '';
                position: absolute;
                left:20%;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/unidad-finalizada.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }  
    }
}

#player{
    width: 100%;
    height: 600px;
}
.plyr--video{
    width: 100%;
}
.plyr{
    width: 100%;
}
</style>