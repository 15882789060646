<template>
    <div class="col d-flex flex-column justify-content-start justify-content-lg-start align-items-center slide px-0">
        <div class="row justify-content-between align-items-start caption row__width">
            <router-link :to="{ name: 'Curso', params: { slug: slug }}" class="col-12 d-flex justify-content-center align-items-center px-1 position-relative" >
                <img :src="image" alt="image-course" class="ejemplo">
                <div class="row justify-content-center row__width position">
                    <div class="col-12 d-flex justify-content-center align-items-center px-1" style="padding: 0 0;">
                        <div class="row justify-content-center align-items-center row__width row__slide">
                            <div class="col-12 d-flex justify-content-between align-items-start arriba">
                                <img src="../../img/home-icons/cursos-icons/verificado.svg" alt="verified" v-if="membership">
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center abajo px-0">
                                <div class="row justify-content-center row__width">
                                    <div class="col col-lg-7 d-flex flex-row justify-content-start align-items-center px-2 px-lg-auto producer_div" v-if="marketplace">
                                        <p class="producer">{{ $t('message.produced') }}</p>
                                        <p class="px-0 ps-md-1 marketplace">{{ marketplace.translation.name }}</p>
                                    </div>
                                    <div class="col col-lg-5 d-flex justify-content-end align-items-center" v-if="reviews">
                                        <p class="rate"><img src="../../img/home-icons/cursos-icons/rate.svg" alt="rate" class="">{{ reviews.average }}</p>
                                        <p class="reviews">{{ reviews.count ? `(${reviews.count})` : $t('message.new') }}</p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>
            <div class="col d-flex flex-column justify-content-start justify-content-lg-center align-items-center px-0">
                <div class="row justify-content-center row__width descripcion">
                    <router-link :to="{ name: 'Curso', params: { slug: slug }}" class="col-12 d-flex justify-content-start justify-content-lg-start align-items-start">
                        <h3 :title="name" class="pt-lg-2">{{ name }}</h3>
                    </router-link>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                        <div class="row justify-content-start row__width py-2 py-lg-3">
                            <router-link :to="{name: 'Profesional', params: {slug: owner_slug ? owner_slug : '0'}}" class="col-12 col-lg d-flex justify-content-start align-items-center" v-if="owner_slug">
                                <img :src="avatar" alt="avatar" class="avatarImage">
                                <div class="d-flex flex-column justify-content-center align-items-start" >
                                    <p class="creator">{{$t('message.creator')}}</p>
                                    <p class="owner">{{ owner }}<img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="profile-verified" v-if="verified"></p>
                                </div>
                            </router-link>
                            <div class="col-12 col-lg d-flex justify-content-start align-items-center" v-else>
                                <img :src="avatar" alt="avatar" class="avatarImage">
                                <div class="d-flex flex-column justify-content-center align-items-start" >
                                    <p class="creator">{{$t('message.creator')}}</p>
                                    <p class="owner">{{ owner }}<img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="profile-verified" v-if="verified"></p>
                                </div>
                            </div>
                            <button type="button" class="btn_none col-12 col-lg d-flex justify-content-start justify-content-lg-end align-items-center colaboradores" v-if=" collabs && collabs.length >= 1" @click="openModal(id)">
                                <img :src="collabs[0].avatar" alt="avatar-collab" class="img_ejemplo">
                                <div class="colaboran"><p class="collabs">+{{ collabs.length }}</p></div>
                                <p class="collaborate">{{$t('message.collaborate')}}</p>
                            </button>
                            <!-- <div class="btn_none col-12 col-lg d-flex justify-content-start justify-content-lg-end align-items-center colaboradores " v-if="collabs.length >= 1">
                                <img :src="collabs[0].avatar" alt="" class="img_ejemplo">
                                <div class="colaboran"><p>+{{ collabs.length }}</p></div>
                                <h3>{{$t('message.collaborate')}}</h3>
                            </div> -->
                            
                            <!-- <button type="button" class="btn_none col-12 col-lg d-flex justify-content-start justify-content-lg-end align-items-center colaboradores" @click="openModal(id)" v-else>
                                <img src="../../img/home-icons/cursos-icons/creador-ejemplo.png" alt="" class="img_ejemplo">
                                <h3>{{$t('message.collaborate')}}</h3>
                            </button> -->
                        </div>
                        <span class="border-bottom"></span>
                    </div>
                </div>
                <router-link  :to="{ name: 'Curso', params: { slug: slug }}" class="row justify-content-center row__width horarios">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                        <div class="row justify-content-start row__width py-2 py-lg-2">
                            <div class="col-4 col-lg-5 d-flex justify-content-start justify-content-lg-center align-items-center border-2">
                                <img src="../../img/home-icons/cursos-icons/participantes.svg" alt="users">
                                <p class="purchases">{{ purchases_count }}</p>
                            </div>
                            <!-- <span class="border2"></span> -->
                            <div class="col-3 col-lg-4 d-flex justify-content-start align-items-center pe-0" v-if="modality">
                                <p class="line-title">{{ modality.translation.name }}</p>
                            </div>
                            <div class="col col-lg d-flex justify-content-start align-items-center px-0 mt-3 mt-lg-0" v-if="details">
                                <div class="d-flex justify-content-center align-items-center px-0 me-2">
                                    <img src="../../img/home-icons/cursos-icons/horario.svg" alt="time" v-if="details.time_duration">
                                    <p class="purchases" v-if="details.time_duration">{{ details.time_duration }} {{ $t('message.hours') }}</p>
                                </div>
                                <div class="d-flex justify-content-center align-items-center px-0" v-if="details.schedules">
                                    <img src="../../img/home-icons/cursos-icons/calendario.svg" alt="schedules"><p class="purchases">{{ details.schedules[0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center mb-1 pe-lg-0 horarios" v-if="modality.id == 1 && details || modality.id == 4 && details">
                        <p class="location" v-if="details.location">
                            <template v-if="country?.name">
                                {{ countryCodeToFlagEmoji(country.code) }} {{ country.name }},
                            </template>
                            <template v-if="state?.name">
                                {{ state.name }},
                            </template>
                            <template v-if="city?.name">
                                {{ city.name }}
                            </template>
                        </p>
                    </div>
                </router-link >
                <!-- <router-link  :to="{ name: 'Curso', params: { slug: slug }}" class="row justify-content-center align-items-center comprar row__width" v-if="realPrice == 'GRATIS'">
                    <button class="mirar" @click="router.push({ name: 'Curso', params: { slug: slug }})">
                        <span>{{$t('message.freeUppercase')}}</span>
                        <img src="../../img/home-icons/cursos-icons/mirar-ahora.svg" alt=""> {{ $t('message.lookNow') }}
                    </button>
                </router-link > -->
                <router-link  :to="{ name: 'Curso', params: { slug: slug }}" class="row justify-content-start comprar row__width">
                    <div class="col d-flex flex-column justify-content-center align-items-start padding pe-0" v-if="price">
                        <p class="coin-price">{{ price.coin_type[0].abbr }} ${{ price.finalPrice }}</p>
                        <p class="price">{{ price.coin_type[0].abbr }} ${{ price.totalPrice }}</p>
                    </div>
                    <div class="col d-flex justify-content-center align-items-center px-0">
                        <router-link :to="{name: 'Curso', params: {slug: slug}}" class="btn__primario btn-width">
                            {{$t('message.buy')}}
                        </router-link>
                    </div>
                </router-link >
            </div>
        </div>
    </div>
    
</template>

<script setup>
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
    import BTNPrimario from "../Botones/PrimarioRutas.vue";
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";
    import { useRouter } from 'vue-router';
    import { onMounted } from "vue";

    const router = useRouter()

    const general = useGeneralStore();
    const {textos, filtrosDesplegados, mapaDesplegado} = storeToRefs(general);
    const {} = general;

    //Recibe los parametros de busqueda del componente padre
    const props = defineProps({
        product: '',
    })

    //Setea las variables con los resultados para su uso
    let id = props.product.id
    let name = props.product.name
    let slug = props.product.slug
    let owner = props.product.professional?.name
    let ownerSlug = props.product.professional?.slug ?? null;
    let avatar = props.product.professional?.avatar
    let image = props.product.presentations[0]?.url
    let modality = props.product.modality
    let details = props.product.details
    let price = props.product.prices[0]
    let verified = props.product.professional?.verified
    let productVerified = props.product.false
    let createdAt = 0
    let collabs = props.product.collaborators
    let reviews = props.product.reviews
    let marketplace = props.product.marketplace
    let membership = props.product.membership
    let purchases_count = props.product.purchases_count
    let country = props.product.details?.location?.country
    let state = props.product.details?.location?.state
    let city = props.product.details?.location?.city

    const roundHours = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (remainingMinutes >= 45) {
            return hours + 1;
        } else {
            return hours;
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('es-ES', { month: 'long' });

        // Capitaliza la primera letra del mes
        const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);

        return `${day} ${formattedMonth}`;
    }

    const openModal = (id) => {
        console.log(id)
        let element = document.getElementById('collabModal'+id)
        console.log(element)
        console.log(element.classList)
        element.classList.remove("d-none")
    }

    onMounted(() => {
        if (details) {
            if (details.time_duration) {
                details.time_duration = roundHours(details.time_duration)
            }
            if (details.schedules) {
                details.schedules[0] = formatDate(details.schedules[0])
            }
        }
        polyfillCountryFlagEmojis();
    })

    function countryCodeToFlagEmoji(countryCode) {
        if(!countryCode){
            return
        }
        return countryCode
            .toUpperCase()
            .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt()));
    }
    //console.log(props)
</script>

<style lang="scss" scoped>
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    height: 530px !important;
    max-height: 530px;
    max-width: 100%;
    @media (max-width: 992px) {
        height: 430px !important;
        max-height: 430px;
    }
    .ejemplo{
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
        height: 225px !important;
        max-height: 2225px;
    }
    .caption{
        position: relative;
        margin: 10px 0;
        padding: 0 5px;
        height: 100%;
        .position{
            position: absolute;
            top: 0;
            min-height: 100%;
            h3{
                font-weight: 700;
                font-size: 16px;
                color: var(--bgPrincipal);
            }
            .producer{
                font-weight: 400;
                font-size: 15px;
                color: var(--bgPrincipal);
            }
            .marketplace{
                font-weight: 700;
                font-size: 15px;
                color: var(--bgPrincipal);
                padding-left: 5px;
            }
            .rate{
                font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: #FEB230;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: max-content;
                position: relative;
                top: 2px;
                img{
                    height: 20px;
                    width: 20px;
                    top: -2px;
                    position: relative;
                    padding-left: 5px
                }
            }
            .reviews{  font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: var(--bgPrincipal);
                padding-left: 3px;
            }
            .row__slide{
                position: relative;
                min-height: 100% !important;
                min-height: 250px;
                border-radius: 15px;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 80%);
                .arriba{
                    position: absolute;
                    top: 3%;
                }
                .abajo{
                    .producer_div{
                        flex-wrap: wrap;
                    }
                    position: absolute;
                    bottom: 3%;
                }
            }
            
        }
    }
    .descripcion{
        h3{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.2;
            text-align: left;
        }
        .creator, .collaborate{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
            margin-top: 5px;
        }
        .owner{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: start;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            img{
                margin-left: 5px;
            }
        }
        .collabs{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .colaboradores{
            position: relative;
            width: 100%;
            .img_ejemplo{
                object-fit: cover;
                border-radius: 50%;
                min-width: 25px;
                min-height: 25px;
                max-width: 25px;
                max-height: 25px; 
                margin-right: 5px;
                position: relative;
                left: 0;
                
            }
            .colaboran{
                background-color: #697891;
                box-shadow: 0px 3px 6px #00000029;
                border: 2px solid #FFFFFF;
                border-radius: 50%;
                min-width: 25px;
                min-height: 25px;
                max-width: 25px;
                max-height: 25px;                
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                right: 5%;
                z-index: 1;
                p{
                    font-size: 10px
                }
            }
            .colaboran + h3{
                position: relative;
                right: 3%;
            }
            @media (min-width: 992px) {
                max-width: 50%;
                .img_ejemplo, .colaboran{
                    min-width: 40px;
                    min-height: 40px;
                    max-width: 40px;
                    max-height: 40px; 
                    // position: absolute;
                    // left: 2%;
                }
                .img-ejemplo{
                    left: 15px;
                }
                // .colaboran + h3{
                //     position: relative;
                //     right: 0;
                // }
                .colaboran {
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
        .creador{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
        }
        .border-bottom{
            width: 90%;
            height: 1px;
            background-color: rgba(184, 193, 219, 0.3);
        }
    }
    .horarios{
        .purchases{
            color: #7D93B2;
            font-weight: 500;
            font-size: 12px;
            padding-left: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .border2{
            width: 1px;
            height: 100%;
            background-color: rgba(184, 193, 219, 0.3);
            margin: 0 10px;
        }
        .border-2{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
            max-width: max-content;
        }
        .line-title{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 15px;
        }
        .col-perso2{
            max-width: 25%;
        }
        .location{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 15px;
        }
        .location{
            color: #697891;
        }
    }
    .comprar{
        margin-bottom: 5px;
        margin-top: 15px;
        @media (max-width: 992px) {
            margin-top: 0px;
            margin-bottom: 0px;
        }
        .coin-price{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }
        .price{
            font-size: 14px;
            font-weight: 400;
            color: #B8C1DB;
            text-decoration: line-through;
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }
        .padding{
            padding-left: 10px;
        }
    }
    @media (min-width: 992px) {
        padding: 5px 5px !important;
        .ejemplo{
            max-height: 240px;
        }
        .max-width-btn{
            max-width: 200px;
        }
        
    }
    @media (max-width: 1024px) {
        .caption{
            .ejemplo{
                height: 100%;
            }
            .position{
                h4, h5{
                    font-size: 10px
                }
                p{
                    font-size: 12px;
                }
            }
        }
        .descripcion{
            h2{
                font-size: 18px;
            }
            h3, h4{
                font-size: 14px;
            }
        }
        .horarios{
            h3{
                font-size: 13px;
            }
        }
        .comprar{
            h2{
                font-size: 16px;
            }
            .padding{
                padding-left: 10px;
            }
            a{
                height: 40px;
                //width: 100px;
                font-size: 15px
            }
        }
    }
    @media (min-width: 1025px) {
        .comprar{
            h2{
                font-size: 20px;
            }
        }
    }
    @media (min-width: 1366px) {
        .comprar{
            h2{
                font-size: 22px;
            }
        }
    }
}
.line-title {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}
.btn-width {
    width: 90% !important;
    @media (min-width: 992px) {
        //width: 150px;
        min-width: 150px;
    }
}
.image-fix {
    margin: 0px 7px 2px 0px;
}
.max-width-col2{
    max-width: 400px
}
.mirar{
        border: 2px solid var(--colorSecondary);
        border-radius: 25px;
        background-color: var(--bgPrincipal);
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        width: 90%;
        position: relative;
        img{
            padding-right: 10px;
        }
        span{
           position: absolute;
           text-transform: uppercase;
           color: var(--colorPrimary);
           font-weight: 600;
           font-size: 14px;
           background-color: var(--bgPrincipal);
           top: -10px;
           right: 15px;
           min-width: 40%;
        }
    }

    .avatarImage{
        object-fit: cover;
        border-radius: 50%;
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        margin-right: 5px;
    }

    .btn{
        z-index: 99;
    }


    .slide, .row__slide, .row__width {
        height: 100%;
    }
</style>