<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-4 px-0" v-if="modality == 1 || modality == 4">
        <h3>Cantidad de compras</h3>
        <input type="number" v-model="information.purchases_count_edit">
    </div>
    <div class="col-12 d-flex justify-content-start align-items-center error d-none" id="purchases-empty">
        <p>Por favor, complete la cantidad de compras del curso</p>
    </div>
</template>

<script setup>
import { inject } from "vue";
import MazPicker from "maz-ui/components/MazPicker";
import dayjs from "dayjs";

const information = inject("information");
const modality = inject("modality");


</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
.valido{
    background-image: url('../../../img/panel-icons/input-valido.svg');
    background-repeat: no-repeat;
    background-position: 98% center;
}
.error{
    padding: 10px 0;
    p{
        color: #FF6A6A;
        font-size: 16px;
        font-weight: 400;
    }
}

</style>